// views list for tasks here
import ListNotification from "@/views/notifications/index";
import AddNotifications from "@/views/notifications/add";
import EditNotifications from "@/views/notifications/edit";

const routes = [
  {
    path: '/notifications/',
    name: 'Notifications List',
    component: ListNotification,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_task'
    },
    props: true
  },
  {
    path: '/notifications/send',
    name: 'Send Notification',
    component: AddNotifications,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_task'
    }
  },
  {
    path: '/notifications/edit/:id',
    name: 'Edit Notification',
    component: EditNotifications,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_task'
    },
    props: true
  },
];

export default routes;
