// router/app-user/index.js
import { createRouter, createWebHistory } from "vue-router";

// views list for Prescriptions here
import ListPrescription from "@/views/app-user/prescription/index";
import AddPrescription from "@/views/app-user/prescription/add";
import EditPrescription from "@/views/app-user/prescription/edit";
import PrescriptionView from "@/views/app-user/prescription/show";

const routes = [
  {
    path: '/prescriptions/',
    name: 'Prescription',
    component: ListPrescription,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    },
    props: true
  },
  {
    path: '/prescriptions/add/:id',
    name: 'Add Prescription',
    component: AddPrescription,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_app_user'
    }
  },
  {
    path: '/prescriptions/edit/:id',
    name: 'Edit Prescription',
    component: EditPrescription,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_app_user'
    },
    props: true
  },
  {
    path: '/prescriptions/show/:id',
    name: 'Show Prescription',
    component: PrescriptionView,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    },
    props: true
  },
];

export default routes;
