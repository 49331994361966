<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <router-link
          to="/areas/add"
          class="c-button cbg-primary mb-2 float-end"
          >Edit VC</router-link
        >
      </div>
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <div class="row py-3">
              <div
                class="col-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="rounded-circle img-fluid"
                  width="130"
                  src="../../../assets/images/user/3.png"
                  alt=""
                />
              </div>
              <div class="col-7">
                <div class="row">
                  <div class="col-3">
                    <p class="p-label">Name</p>
                    <p class="text-secondary">M Husnain Raza</p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">Age</p>
                    <p class="text-secondary">28 Year</p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">Blood Group</p>
                    <p class="text-secondary">O-</p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">Marital</p>
                    <p class="text-secondary">Single</p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-3">
                    <p class="p-label">Email</p>
                    <p class="text-primary">info@gmail.com</p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">Number</p>
                    <p class="text-secondary">+92 303 7900571</p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">
                      Weight <span class="text-secondary">(Kg)</span>
                    </p>
                    <p class="text-secondary">68Kg</p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">
                      Height <span class="text-secondary">(Feet)</span>
                    </p>
                    <p class="text-secondary">5.10 Feet</p>
                  </div>
                </div>
                <div class="row"></div>
              </div>
              <div class="col-3">
                <div class="rounded w-100 h-100 box-bg p-2">
                  <p class="p-label">Disease</p>
                  <p class="text-secondary">Diabetes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link py-3 active"
              data-bs-toggle="tab"
              aria-current="page"
              href="#tab1"
              >Medical History</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab2"
              >Virtual Health System</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab3"
              >Prescriptions</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab4"
              >Family Profile</a
            >
          </li>
        </ul>
        <div class="card border-0">
          <div class="body p-3">
            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab1">
                <h4>Tab 1 Content</h4>
                <p>This is the content of Tab 1.</p>
              </div>
              <div class="tab-pane fade" id="tab2">
                <h4>Tab 2 Content</h4>
                <p>This is the content of Tab 2.</p>
              </div>
              <div class="tab-pane fade" id="tab3">
                <h4>Tab 3 Content</h4>
                <p>This is the content of Tab 3.</p>
              </div>
              <div class="tab-pane fade" id="tab4">
                <div class="col-md-12">
                  <data-table
                    :columns="columns"
                    :module="module"
                    :items="items"
                    :isEdit="false"
                    :isDelete="false"
                    @deleted="fetch"
                    @nextPage="nextPage"
                    @search="fetch"
                    @clear="fetch"
                  ></data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
    Bread_crumbs,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Name",
          value: "title",
        },
        {
          text: "Email",
          value: "tehsil",
          secondValue: "title",
        },
        {
          text: "Age",
          value: "district",
          secondValue: "title",
        },
        {
          text: "Blood Group",
          value: "lat",
        },
        {
          text: "Number",
          value: "long",
        },
        {
          text: "Relation",
          value: "long",
        },
      ],
      module: "areas",
      loading: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("areas/getAll", { search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.card {
  background-color: #FFFFFF !important;
  border-top-left-radius: 0px;
}
.nav-tabs {
  border-bottom: none !important;
  border-color: none;
}
.nav-item {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1); /* Drop shadow effect */
  background-color: #f8f9fa;
  margin-right: 10px !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.nav-tabs .nav-link.active {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  background-color: #fff !important;
}
.p-label {
  font-weight: 500 !important;
  color: #333333;
  margin: 0;
}
.p {
  color: #333333;
  margin: 0;
}
.box-bg {
  background: #f0faff;
}

</style>  