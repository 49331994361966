<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <router-link
          to="/health-literacy-content/add"
          class="c-button cbg-primary mb-2 float-end"
          >Add Health Literacy Content</router-link
        >
        <input
          type="text"
          class="form-control mb-2 w-25"
          v-model="search"
          placeholder="Search"
          @input="debouncedFetch"
        />
      </div>

      <div
        class="col-md-4 mt-3"
        v-for="(item, index) in responseData"
        :key="index"
      >
        <div class="card h-100" v-if="item.type == 'image'" @mouseenter="showBTN(item.id)"
        @mouseleave="hideBTN(item.id)">
          <img
            :src="item.file.path"
            class="card-img-top"
            alt="..."
            height="200"
          />
          <div class="card-body" style="position:relative;">
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text">{{ truncatedDesc(item.desc) }}</p>
            <div :id="`visible-${item.id}`" class="card-btn d-none">
              <router-link
                :to="`/health-literacy-content/edit/${item.encrypted_id}`"
                class="c-button cbg-primary me-2"
                >Edit</router-link
              >
              <router-link
                :to="`/health-literacy-content/show/${item.encrypted_id}`"
                class="c-button bg-warning"
                >View</router-link
              >
            </div>
          </div>
        </div>
        <div class="card h-100" v-if="item.type == 'video'" @mouseenter="showBTN(item.id)"
        @mouseleave="hideBTN(item.id)">
          <video
            width="100%"
            height="200"
            :poster="item.file.thumbnail"
            :src="item.file.path" 
          >
          </video>
          <div class="card-body" style="position:relative;">
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text">{{ truncatedDesc(item.desc) }}</p>
            <div :id="`visible-${item.id}`" class="card-btn d-none">
              <router-link
                :to="`/health-literacy-content/edit/${item.encrypted_id}`"
                class="c-button cbg-primary me-2"
                >Edit</router-link
              >
              <router-link
                :to="`/health-literacy-content/show/${item.encrypted_id}`"
                class="c-button bg-warning"
                >View</router-link
              >
            </div>
          </div>
        </div>
        <div
          class="card h-100"
          v-if="item.type == 'pdf'"
          @mouseenter="showBTN(item.id)"
          @mouseleave="hideBTN(item.id)"
        >
          <iframe
          class="card-img-top"
            :src="loadWithHTTPS(item.file.path)"
            width="100%"
            height="200"
          ></iframe>
          <div class="card-body" style="position:relative;">
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text">{{ truncatedDesc(item.desc) }}</p>
            <div :id="`visible-${item.id}`" class="card-btn d-none">
              <router-link
                :to="`/health-literacy-content/edit/${item.encrypted_id}`"
                class="c-button cbg-primary me-2"
                >Edit</router-link
              >
              <router-link
                :to="`/health-literacy-content/show/${item.encrypted_id}`"
                class="c-button bg-warning"
                >View</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <nav v-if="items.last_page > 0">
        <ul class="pagination mt-3 float-end">
          <li class="page-item" :class="{ disabled: items.current_page === 0 }">
            <a
              class="page-link"
              :href="items.prev_page_url"
              @click.prevent="nextPage(items.prev_page_url)"
              >Previous</a
            >
          </li>
          <!-- <li class="page-item" :class="{ active: page === items.current_page }" v-for="page in items.links" :key="page">
            <a class="page-link" href="#" @click.prevent="fetch({page: page.label})">{{ page.label }}</a>
          </li> -->
          <li
            class="page-item"
            :class="{ disabled: items.current_page === items.last_page }"
          >
            <a
              class="page-link"
              :href="items.next_page_url"
              @click.prevent="nextPage(items.next_page_url)"
              >Next</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
    Bread_crumbs,
  },
  data() {
    return {
      items: [],
      responseData: [],
      debouncedFetch: null,
      search: "",
      loading: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      let search = this.search;
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("healthLiteracyContent/getAll", { search })
        .then((response) => {
          this.responseData = response.data.data;
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.responseData = response.data.data.data;
        this.loading = false;
      });
    },
    truncatedDesc(desc) {
      const maxLength = 100;
      if (desc.length > maxLength) {
        return desc.substring(0, maxLength) + "...";
      } else {
        return desc;
      }
    },
    loadWithHTTPS(url) {
      if (process.env.NODE_ENV && process.env.NODE_ENV == "production") {
        return url.replace("http://", "https://");
      }
      return url;
    },
    showBTN(id) {
      document.getElementById(`visible-${id}`).classList.remove('d-none');
    },
    hideBTN(id) {
      document.getElementById(`visible-${id}`).classList.add('d-none');
    },
  },

  mounted() {
    this.debouncedFetch = this.debounce(this.fetch, 500);
  },
};
</script>

<style scoped>
.card-img-top{
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.card-btn {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 22%;
}
.card:hover{
  background: rgba(101, 99, 99, 0.445);
}
</style>
  