<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <router-link
          to="/tasks"
          class="c-button cbg-primary mb-2 float-end"
          >List Task</router-link
        >
      </div>
      <div class="col-md-12">
        <data-table
          :columns="columns"
          :module="module"
          :items="items"
          :isAction="false"
          :isEdit="true"
          :isDelete="true"
          :viewDetail="true"
          :unBlock="true"
          :isBlock="true"
          @viewItem="fetch"
          @deleted="fetch"
          @nextPage="nextPage"
          @search="fetch"
          @clear="fetch"
        ></data-table>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
    Bread_crumbs,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Name",
          value: "full_name",
        },
        {
          text: "Date",
          value: "task_status",
          secondValue:"date"
        },
        {
          text: "Detail",
          value: "task_status",
          secondValue:"desc"
        },
        {
          text: "Status",
          value: "task_status",
          secondValue:"status"
          // secondValue:"status",
        },
      ],
      module: "tasks",
      taskStatusId:null,
      loading: false,
    };
  },
  created() {
    this.taskStatusId = this.$route.params.id;
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("tasks/getAll", { id:this.taskStatusId, search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
  