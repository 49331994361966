<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <div class="row mb-2">
          <div class="col-md-2">
            <input
              v-model="filter.start_date"
              type="date"
              placeholder="Start Date"
              class="form-control form-control-sm dateclass placeholderclass"
              onClick="$(this).removeClass('placeholderclass')"
            />
          </div>
          <div class="col-md-2">
            <input
              v-model="filter.end_date"
              type="date"
              placeholder="End Date"
              class="form-control form-control-sm dateclass placeholderclass"
              onClick="$(this).removeClass('placeholderclass')"
            />
          </div>
          <div class="col-md-2">
            <select
              name=""
              id=""
              v-model="filter.district"
              class="c-form-select"
            >
              <option value="" disabled selected>Select District</option>
              <option
                :value="district.encrypted_id"
                v-for="(district, index) of districts"
                :key="index"
              >
                {{ district.title }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <button
              type="button"
              @click="exportFilterereData()"
              class="c-button cbg-secondary me-2"
            >
              Filter
            </button>
            <button
              type="button"
              @click="exportFilterereData(true, false)"
              class="c-button bg-success"
            >
              Export
            </button>
          </div>
          <div class="col-md-3">
            <button class="c-button cbg-primary">
              <router-link to="/chw/add" class="text-white"
                >Add CHW</router-link
              >
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <data-table
          :columns="columns"
          :module="module"
          :items="items"
          :isEdit="true"
          :isDelete="true"
          :viewDetail="false"
          :unBlock="true"
          :isBlock="true"
          @viewItem="fetch"
          @deleted="fetch"
          @nextPage="nextPage"
          @search="fetch"
          @clear="fetch"
        ></data-table>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
    Bread_crumbs,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Name",
          value: "full_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Qualification",
          value: "qualification",
          secondValue: "title"
        },
        {
          text: "Date Join",
          value: "joining_date",
        },
        {
          text: "Number",
          value: "phone_no",
        },
        {
          text: "Status",
          value: "user",
          secondValue:"status",
        },
      ],
      filter: {
        start_date: null,
        end_date: null,
        district: "",
      },
      districts: [],
      module: "chw",
      loading: false,
    };
  },
  created() {
    this.fetch();
    this.fetchDistricts();
  },
  methods: {
    fetch(search = "") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("chw/getAll", { search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    exportFilterereData(download = false, paginate = true) {
      let filteredFilter = Object.keys(this.filter)
        .filter((key) => this.filter[key]) // Only keep keys with truthy values
        .reduce((acc, key) => {
          acc[key] = this.filter[key];
          return acc;
        }, {});
      filteredFilter.isFiltered = true;
      filteredFilter.download = download;
      filteredFilter.paginate = paginate;
      this.loading = true;
      this.$store
        .dispatch("chw/getAll", filteredFilter)
        .then((response) => {
          if (download == true) {
            window.location.href = response.data;
          }else{
            this.items = response.data;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchDistricts() {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("districts/getAll", { paginate: false })
        .then((response) => {
          this.districts = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
  