<template>
    <div class="dropdown icon-dropdown">
                      <button
                        class="btn dropdown-toggle"
                        id="userdropdown"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="icon-more-alt"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="userdropdown"
                      >
                        <a class="dropdown-item" href="#">Weekly</a
                        ><a class="dropdown-item" href="#">Monthly</a
                        ><a class="dropdown-item" href="#">Yearly</a>
                      </div>
                    </div>
</template>