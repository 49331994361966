<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs></Bread_crumbs>
        <router-link to="/facility-centers" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Edit Facility Center</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              id="editFacilityCenter"
              ref="editFacilityCenter"
              class="needs-validation"
              novalidate
            >
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <!-- <label>First Name <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.title">
                    {{ errors.title }}
                  </span>

                  <input
                    type="text"
                    placeholder="Title *"
                    class="form-control form-control-sm"
                    v-model="facilityCenter.title"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a title.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->

                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.district_id">
                    {{ errors.district_id }}
                  </span>
                  <select
                    class="c-form-select"
                    v-model="facilityCenter.district_id"
                    v-on:change="fetchTehsils(this.value)"
                    required
                  >
                    <option value="">Select District ...</option>
                    <option
                      v-for="district in districts"
                      :value="district.id"
                      v-bind:key="district.id"
                    >
                      {{ district.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a district.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.tehsil_id">
                    {{ errors.tehsil_id }}
                  </span>
                  <select
                    class="c-form-select"
                    v-model="facilityCenter.tehsil_id"
                    v-on:change="fetchVCs({ tehsil_id_id: this.value })"
                    required
                  >
                    <option value="">Select Tehsil ...</option>
                    <option
                      v-for="tehsil in tehsils"
                      :value="tehsil.id"
                      v-bind:key="tehsil.id"
                    >
                      {{ tehsil.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select first district and then tehsil.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.area_id">
                    {{ errors.area_id }}
                  </span>
                  <select
                    class="c-form-select"
                    v-model="facilityCenter.area_id"
                    required
                  >
                    <option value="" selected>Select VC ...</option>
                    <option
                      v-for="area in areas"
                      :value="area.id"
                      v-bind:key="area.id"
                    >
                      {{ area.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select first tehsil and then area.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.phone_no">
                    {{ errors.phone_no }}
                  </span>
                  <input
                    maxlength="11"
                    pattern="[0-9]{11}"
                    type="text"
                    placeholder="Phone *"
                    class="form-control form-control-sm"
                    v-model="facilityCenter.phone_no"
                    @keypress="isNumber($event)"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a number atleast 11 digits.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.lat">
                    {{ errors.lat }}
                  </span>
                  <input
                    type="number"
                    placeholder="Latitude *"
                    class="form-control form-control-sm"
                    v-model="facilityCenter.lat"
                    ern="[^\s@]+@[^\s@]+\.[^\spatt@]+"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a valid latitude.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.long">
                    {{ errors.long }}
                  </span>
                  <input
                    type="number"
                    placeholder="Longitude *"
                    class="form-control form-control-sm"
                    v-model="facilityCenter.long"
                    @keypress="isNumber($event)"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a longitude.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <!-- <label>Password <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.location">
                    {{ errors.location }}
                  </span>
                  <input
                    type="text"
                    placeholder="Location *"
                    id="location"
                    name="location"
                    class="form-control form-control-sm"
                    v-model="facilityCenter.location"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a location.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.manager">
                    {{ errors.manager }}
                  </span>
                  <input
                    type="text"
                    id="manager"
                    name="manager"
                    placeholder="Facility Manager *"
                    class="form-control form-control-sm"
                    v-model="facilityCenter.manager"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a manager.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <!-- <label>Password <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.open_time">
                    {{ errors.open_time }}
                  </span>
                  <label for="">Open Time</label>
                  <input
                    type="time"
                    placeholder="Location"
                    id="open_time"
                    name="open_time"
                    class="form-control form-control-sm"
                    v-model="facilityCenter.open_time"
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a open time.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.close_time">
                    {{ errors.close_time }}
                  </span>
                  <label for="">Close Time</label>
                  <input
                    type="time"
                    id="close_time"
                    name="close_time"
                    placeholder="Facility Manager"
                    class="form-control form-control-sm"
                    v-model="facilityCenter.close_time"
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a close time.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <!-- <label>Password <span class="text-danger">*</span></label> -->
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-12 mb-3">
                  <label for="file-upload" class="custom-file-upload form-control">Upload File *</label>
                  <span class="text-danger d-block" v-if="errors.file">
                    {{ errors.file }}
                  </span>
                  <input
                    type="file"
                    ref="file"
                    id="file-upload"
                    accept="image/*,audio/*"
                    placeholder="Attach Image *"
                    class="form-control form-control-sm"
                    @change="selectFile()"
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a image.
                  </div>
                  <img v-if="filePreview" :src="filePreview" alt="" class="mt-3" width="50%" height="200">
                </div>
              </div>

              <div class="form-group row">
                <div
                  class="col-md-12 mb-3"
                  v-for="(service, index) in services"
                  :key="index"
                >
                  <a v-if="service != 0"
                    class="text-danger cursor float-end mb-1"
                    @click="removeServiceField(index)"
                  >
                    X
                  </a>
                  <input
                    type="text"
                    placeholder="Service *"
                    class="form-control form-control-sm"
                    v-model="facilityCenter.services[index]"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide an service.
                  </div>
                </div>
                <a class="text-primary cursor w-25" @click="addServiceField()">
                  +Add Service
                </a>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <!-- <button type="button" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  mixins: [helpers],
  data() {
    return {
      facilityCenter: {
        title: null,
        district_id: "",
        tehsil_id: "",
        area_id: "",
        phone_no: null,
        lat: null,
        long: null,
        location: null,
        manager:null,
        open_time:null,
        close_time:null,
        file: null,
        services: [],
        created_by: localStorage.getItem("uid"),
      },
      services: [],
      districts: [],
      tehsils: [],
      areas: [],
      filePreview: null,
      errors: [],
      userId: null,
      loading: false,
    };
  },
  mounted() {
    this.facilityCenterId = this.$route.params.id;
    this.fetch(this.facilityCenterId);
    this.fetchDistricts();
  },
  methods: {
    fetch(id) {
      this.loading = false;
      this.$store
        .dispatch("facilityCenters/getSingle", { id })
        .then((response) => {
          this.facilityCenter = response.data;
          this.services = new Array(this.facilityCenter.services.length)
          this.filePreview = this.facilityCenter.file.path;
          console.log(this.services, 'services');
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      var form = document.getElementById("editFacilityCenter");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        this.errors = [];
        const data = toRaw(this.facilityCenter);
        const id = this.facilityCenterId;
        this.$store
          .dispatch("facilityCenters/update", { data, id })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly updated.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show("Something went wrong!", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.facilityCenter.file = reader.result;
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    addServiceField() {
      this.services.push(this.services.length);
    },
    removeServiceField(index) {
      if (index > -1 && index < this.services.length) {
        this.services.splice(index, 1);
        this.facilityCenter.services.splice(index, 1);
      }
    },
    fetchDistricts() {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("districts/getAll", {})
        .then((response) => {
          this.districts = response.data.data;
          this.fetchTehsils();
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },

    fetchTehsils() {
      const district_id = this.facilityCenter.district_id;
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("tehsils/getAll", { district_id })
        .then((response) => {
          this.tehsils = response.data;
          this.fetchVCs();
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchVCs() {
      const tehsil_id = this.facilityCenter.tehsil_id;
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("areas/getAll", { tehsil_id })
        .then((response) => {
          this.areas = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
