<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="row">
        <div class="col-md-12">
          <form
            @submit.prevent="submit"
            id="slider"
            ref="addSlider"
            class="needs-validation"
            novalidate
          >
            <div class="form-group row">
              <div class="col-md-12">
                <img
                  v-if="filePreview"
                  :src="filePreview"
                  alt=""
                  class="my-2"
                  height="100"
                />

                <label for="slider-file-upload" class="custom-file-upload form-control"
                  >Upload Slider File *</label
                >
                <input
                  type="file"
                  ref="sliderFile"
                  id="slider-file-upload"
                  accept="image/*"
                  placeholder="Select File *"
                  class="form-control form-control-sm"
                  @change="selectFile()"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-3">
                <button type="submit" class="c-button cbg-primary">
                  Submit
                </button>
                <button
                  type="button"
                  @click="this.$refs.addSlider.reset()"
                  class="ms-2 c-button cbg-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <data-table
          :columns="columns"
          :module="module"
          :items="items"
          :isEdit="false"
          :isDelete="true"
          :viewDetail="false"
          :unBlock="false"
          :isBlock="false"
          @viewItem="fetch"
          @deleted="fetch"
          @nextPage="nextPage"
          @search="fetch"
          @clear="fetch"
        ></data-table>
      </div>
    </div>
  </div>
</template>
  <script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  props: {
    sliderFile: Object,
  },
  mixins: [isNumber],
  data() {
    return {
      items: [],
      columns: [
        // {
        //   text: "ID",
        //   value: "id",
        // },
        {
          text: "File Type",
          value: "file_type",
        },
        {
          text: "Image",
          value: "path",
        },
      ],
      slider: {
        file: null,
      },
      module: "promotional",
      filePreview: "/promotional/slider/slider.png",
      errors: [],
      loading: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("promotional/getAllSlider", { search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    submit() {
      var form = document.getElementById("slider");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.slider);
        this.$store
          .dispatch("promotional/storeSlider", { data })
          .then((res) => {
            this.loading = false;
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.fetch();
            this.$refs.addSlider.reset();
            this.errors = [];
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    selectFile() {
      let file = this.$refs.sliderFile.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.slider.file = reader.result;
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
};
</script>
  