<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/areas" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Page</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              ref="addPage"
              id="add-page"
              class="needs-validation"
              novalidate
            >
              <div class="form-group mb-3">
                <!-- <label>Select Type <span class="text-danger">*</span></label> -->
                <select
                  class="form-control form-control-sm"
                  v-model="page.key"
                  required
                >
                  <option value="">Select Type... *</option>
                  <option value="privacy_policy">Privacy Policy</option>
                  <option value="terms">Terms & Conditions</option>
                </select>
                <div class="invalid-feedback text-danger">
                  Please select a type.
                </div>
              </div>
              <div class="form-group mb-3">
                <!-- <label>Content <span class="text-danger">*</span></label> -->
                <ckeditor
                  :editor="editor"
                  height="50px"
                  v-model="page.value"
                  placeholder="Type Content *"
                  :config="editorConfig"
                  required
                ></ckeditor>
                <div class="invalid-feedback text-danger">
                  Please provide a Description.
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addPage.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import helpers from "@/helpers/helpers";
export default {
  components: {
    Bread_crumbs,
    ckeditor: CKEditor.component 
  },
  mixins:[helpers],
  data() {
    return {
      page: {
        key: "",
        value: "",
      },
      editor: ClassicEditor,
        editorData: '',
        editorConfig: {
          placeholder: "Type detail.... *",
          config:{ height: '300px' }
        },
        menutoogle:false,
      loading: false,
    };
  },
  mounted() {},
  methods: {
    submit() {
      var form = document.getElementById("add-page");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.page);
        this.$store
          .dispatch("pages/store", { data })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              if (this.$refs.addPage) {
                this.$nextTick(() => {
                  this.$refs.addPage.reset();
                  this.$refs.addPage.classList.remove("was-validated");
                });
              }
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show(Object.values(this.errors), {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },
};
</script>
<style>
 .ck-content { height:300px; }
</style>
  