<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <!-- <span v-if="user"> / {{ user.f_name }}</span> -->
      </div>
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <div class="row">
              <div
                class="col-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="rounded-circle img-fluid"
                  width="130"
                  :src="user.file.path"
                  alt=""
                  v-if="user.file"
                />
              </div>
              <div class="col-7">
                <div class="row">
                  <div class="col-4">
                    <p class="p-label">Name</p>
                    <p class="text-secondary" v-if="user">{{ user.f_name }}</p>
                  </div>
                  <div class="col-4">
                    <p class="p-label">CNIC</p>
                    <p class="text-secondary" v-if="user">
                      {{ user.cnic }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="p-label">Specialized</p>
                    <p class="text-secondary" v-if="user">
                      {{ specializations }}
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-4">
                    <p class="p-label">Email</p>
                    <p class="text-primary" v-if="user">{{ user.email }}</p>
                  </div>
                  <div class="col-4">
                    <p class="p-label">Number</p>
                    <p class="text-secondary" v-if="user">
                      +{{ user.phone_no }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="p-label">Qualification</p>
                    <p class="text-secondary" v-if="user && user.qualification">
                      {{ user.qualification.title }}
                    </p>
                  </div>
                </div>
                <div class="row"></div>
              </div>
              <div class="col-3">
                <div class="rounded w-100 h-100 box-bg p-2">
                  <div class="row">
                    <div class="col-3">
                      <p class="p-label">{{ rating }}</p>
                    </div>
                    <div class="col-8">
                      <Rating
                        starSize="15"
                        starColor="#FFD833"
                        inactiveColor="#BAB8B1"
                        :numberOfStars="5"
                        :disableClick="true"
                        :rating="rating"
                      />
                    </div>
                    <p class="text-secondary">Rating</p>
                  </div>
                  <div class="mt-3">
                    <p class="p-label">Patient</p>
                    <p class="text-secondary">{{ user.patients_count }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link py-3"
              data-bs-toggle="tab"
              aria-current="page"
              href="#tab1"
              :class="{ active: activeTabDoctor === 'tab1' }" @click="setActiveTabDoctor('tab1')"
              >Upcoming Appointments</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab2"
            :class="{ active: activeTabDoctor === 'tab2' }" @click="setActiveTabDoctor('tab2')"
              >Cancel Appointments</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab3"
            :class="{ active: activeTabDoctor === 'tab3' }" @click="setActiveTabDoctor('tab3')"
              >Completed Appointments</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab4"
            :class="{ active: activeTabDoctor === 'tab4' }" @click="setActiveTabDoctor('tab4')"
              >Duty Roaster</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab5"
            :class="{ active: activeTabDoctor === 'tab5' }" @click="setActiveTabDoctor('tab5')"
              >Patient List</a
            >
          </li>
        </ul>
        <div class="card tab-card border-0">
          <div class="body p-3">
            <div class="tab-content">
              <div class="tab-pane fade" :class="{ show: activeTabDoctor === 'tab1', active: activeTabDoctor === 'tab1' }" id="tab1">
                <Timeline
                v-if="user.ongoing_appointments.length > 0"
                  :timelines="user.ongoing_appointments"
                  :user="user"
                  timelineType="appointments"
                />
              </div>
              <div class="tab-pane fade" :class="{ show: activeTabDoctor === 'tab2', active: activeTabDoctor === 'tab2' }" id="tab2">
                <Timeline
                v-if="user.doctor_cancelled_appointments.length > 0"
                  :timelines="user.doctor_cancelled_appointments"
                  :user="user"
                  timelineType="appointments"
                />
              </div>
              <div class="tab-pane fade" :class="{ show: activeTabDoctor === 'tab3', active: activeTabDoctor === 'tab3' }" id="tab3">
                <Timeline
                v-if="user.doctor_completed_appointments.length > 0"
                  :timelines="user.doctor_completed_appointments"
                  :user="user"
                  timelineType="appointments"
                />
              </div>
              <div class="tab-pane fade" :class="{ show: activeTabDoctor === 'tab4', active: activeTabDoctor === 'tab4' }" id="tab4">
                <DutyRoaster :doctor_id="userId" />
              </div>
              <div class="tab-pane fade" :class="{ show: activeTabDoctor === 'tab5', active: activeTabDoctor === 'tab5' }" id="tab5">
                <PatientList :doctor_id="userId" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import Timeline from "@/components/app-user/Timeline.vue";
import DataTable from "@/components/dataTable.vue";
import Rating from "@/components/rating.vue";
import DutyRoaster from "@/components/duty_roaster.vue";
import PatientList from "@/components/patient-list.vue";

export default {
  components: {
    DataTable,
    Bread_crumbs,
    Timeline,
    Rating,
    DutyRoaster,
    PatientList
  },
  data() {
    return {
      rating:0,
      dutyRoasters:null,
      items: [],
      columns: [
        {
          text: "Name",
          value: "full_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Age",
          value: "age",
        },
        {
          text: "Blood Group",
          value: "blood_group",
        },
        {
          text: "Number",
          value: "phone_no",
        },
        {
          text: "Relation",
          value: "relation",
        },
      ],
      specializations: "",
      user: {
        doctor_cancelled_appointments:[],
        doctor_completed_appointments:[],
        ongoing_appointments:[],
      },
      module: "familyMembers",
      loading: false,
      activeTabDoctor: 'tab1',
    };
  },
  created() {
    this.userId = this.$route.params.id;
    this.fetch(this.userId);
    const savedTab = localStorage.getItem('activeTabDoctor');
    if (savedTab) {
      this.activeTabDoctor = savedTab;
    }
  },
  methods: {
    fetch(id) {
      this.loading = false;
      this.$store
        .dispatch("doctors/getSingle", { id })
        .then((response) => {
          this.user = response.data;
          this.rating = this.user.rating
          if (this.user.specializations) {
            this.getSpecializations(this.user.specializations);
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    getSpecializations(specializations) {
      specializations = specializations.map((s) => s.title);
      this.specializations = specializations.join(", ");
    },
    setActiveTabDoctor(tab) {
      this.activeTabDoctor = tab;
      localStorage.setItem('activeTabDoctor', tab);
    }
  },
};
</script>
  <style scoped>
.card {
  background-color: #ffffff !important;
}
.tab-card {
  border-top-left-radius: 0px;
}
.nav-tabs {
  border-bottom: none !important;
  border-color: none;
}
.nav-item {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1); /* Drop shadow effect */
  background-color: #f8f9fa;
  margin-right: 10px !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.nav-tabs .nav-link.active {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  background-color: #fff !important;
}
.p-label {
  font-weight: 500 !important;
  color: #333333;
  margin: 0;
}
.p {
  color: #333333;
  margin: 0;
}
.box-bg {
  background: #f0faff;
}
.Active {
  background-color: rgba(0, 0, 128, 1);
  color: white !important;
}
</style>  