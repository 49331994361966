<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/qualifications" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Edit Qualifications</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              ref="addRole"
              id="edit-qualifications"
              class="needs-validation"
              novalidate
            >
              <div class="form-group mb-3">
                <!-- <label>Title <span class="text-danger">*</span></label> -->
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Title *"
                  required
                  v-model="qualification.title"
                />
                <div class="invalid-feedback text-danger">
                  Please provide a title.
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";
export default {
  components: {
    Bread_crumbs,
  },
  mixins:[helpers],
  data() {
    return {
      qualification: {
        title: "",
      },

      loading: false,
    };
  },
  mounted() {
    this.qualificationId = this.$route.params.id;
    this.fetch(this.qualificationId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("qualifications/getSingle", { id })
        .then((response) => {
          this.qualification = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    submit() {
      var form = document.getElementById("edit-qualifications");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.qualification);
        const id = this.qualificationId;
        this.$store
          .dispatch("qualifications/update", { data, id })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly updated.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show("Something went wrong!", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },
};
</script>
  