<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <button
          type="button"
          @click="$router.go(-1)"
          class="c-button cbg-primary"
        >
          Back
        </button>

        <div class="col-md-8 offset-md-2">
          <div class="card mt-4">
            <div class="card-header">
              <h4 class="card-title" v-if="appointment">Appointment Detail</h4>
            </div>
            <div class="card-body" v-if="appointment">
              <div class="row mb-3" v-if="appointment.doctor">
                <div class="col-md-3 d-flex justify-content-center">
                  <img
                    :src="appointment.doctor.file.path"
                    class="w-100"
                    v-if="appointment.doctor"
                    alt=""
                  />
                </div>
                <div class="col-md-9 text-sm-center">
                  <h4>Dr {{ appointment.doctor.full_name }}</h4>
                  <p
                    v-for="specialization of appointment.doctor.specializations"
                    :key="specialization"
                  >
                    {{ specialization.title }}
                  </p>
                  <h5>{{ appointment.date }} - {{ appointment.s_time }}</h5>
                </div>
              </div>
              <hr />
              <div class="col-md-8 offset-md-1">
                <h5>Scheduled Appointment:</h5>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-4">Date :</div>
                  <div class="col-sm-6 col-md-8">{{ appointment.date }}</div>
                </div>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-4">Time :</div>
                  <div class="col-sm-6 col-md-8">{{ appointment.s_time }}</div>
                </div>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-4">Booking for :</div>
                  <div class="col-sm-6 col-md-8">
                    {{
                      appointment.patient.parent_id
                        ? appointment.patient.relation
                        : "Self"
                    }}
                  </div>
                </div>
              </div>
              <hr />
              <div class="col-md-8 offset-md-1" v-if="appointment.patient">
                <h5>Patient Information:</h5>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-4">Name :</div>
                  <div class="col-sm-6 col-md-8">{{ appointment.patient.full_name }}</div>
                </div>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-4">Gender :</div>
                  <div class="col-sm-6 col-md-8">{{ appointment.patient.gender }}</div>
                </div>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-4">Age :</div>
                  <div class="col-sm-6 col-md-8">
                    {{
                      appointment.patient.age
                    }} Years
                  </div>
                </div>
              </div>
              <hr />
              <div class="col-md-8 offset-md-1" v-if="appointment.patient">
                <h5>Disease Detail:</h5>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-12">{{ appointment.patient.short_bio }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
  },
  data() {
    return {
      appointment: null,
      patient_id: null,
      doctor_id: null,
      appointmentId: null,
      loading: false,
      errors: [],
    };
  },
  mounted() {
    this.appointmentId = this.$route.params.id;
    this.patient_id = this.$route.query.patient_id;
    this.doctor_id = this.$route.query.doctor_id;
    this.fetch(this.appointmentId, this.patient_id, this.doctor_id);
  },
  methods: {
    fetch(id, patient_id, doctor_id) {
      this.loading = true;
      this.$store
        .dispatch("appointments/getSingle", { id, patient_id, doctor_id })
        .then((response) => {
          this.appointment = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>
  