// views list for tasks here
import ListTask from "@/views/tasks/index";
import AddTask from "@/views/tasks/add";
import EditTask from "@/views/tasks/edit";
import ShowTask from "@/views/tasks/show";

const routes = [
  {
    path: '/tasks/',
    name: 'Tasks',
    component: ListTask,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_task'
    },
    props: true
  },
  {
    path: '/tasks/add',
    name: 'Add Task',
    component: AddTask,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_task'
    }
  },
  {
    path: '/tasks/edit/:id',
    name: 'Edit Task',
    component: EditTask,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_task'
    },
    props: true
  },
  {
    path: '/tasks/show/:id',
    name: 'Task Profile',
    component: ShowTask,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_task'
    },
    props: true
  },
];

export default routes;
