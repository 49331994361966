// router/app-user/index.js
import { createRouter, createWebHistory } from "vue-router";

// routes defined here
import ListAppUser from "@/views/app-user/index";
import AddAppUser from "@/views/app-user/add";
import EditAppUser from "@/views/app-user/edit";
import AppUserView from "@/views/app-user/show";

const routes = [
  {
    path: '/app-users',
    name: 'List App User',
    component: ListAppUser,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    }
  },
  {
    path: '/app-users/add',
    name: 'Add App User',
    component: AddAppUser,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_app_user'
    }
  },
  {
    path: '/app-users/edit/:id',
    name: 'Edit App User',
    component: EditAppUser,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_app_user'
    },
    props: true
  },
  {
    path: '/app-users/show/:id',
    name: 'Member App User',
    component: AppUserView,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    },
    props: true
  },
];

export default routes;
