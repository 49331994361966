<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <router-link
          to="/facility-centers/add"
          class="c-button cbg-primary mb-2 float-end"
          >Add Facility Center</router-link
        >
      </div>
      <div class="col-md-12">
        <data-table
          :columns="columns"
          :module="module"
          :items="items"
          :isEdit="true"
          :isDelete="true"
          :viewDetail="false"
          :unBlock="true"
          :isBlock="true"
          @viewItem="fetch"
          @deleted="fetch"
          @nextPage="nextPage"
          @search="fetch"
          @clear="fetch"
        ></data-table>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
    Bread_crumbs,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "District",
          value: "district",
          secondValue:'title'
        },
        {
          text: "Contact Number",
          value: "phone_no",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      module: "facilityCenters",
      loading: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("facilityCenters/getAll", { search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
  