<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs></Bread_crumbs>
        <router-link to="/app-users" class="c-button cbg-primary mb-3">Back</router-link>
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add App User</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" id="app-user" ref="addAppUser" class="needs-validation" novalidate>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <!-- <label>First Name <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.f_name">
                    {{ errors.f_name }}
                  </span>

                  <input type="text" placeholder="First Name *" class="form-control form-control-sm"
                    v-model="user.f_name" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a name.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <!-- <label>Last Name</label> -->
                  <input type="text" placeholder="Last Name" class="form-control form-control-sm"
                    v-model="user.l_name" />
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.email">
                    {{ errors.email }}
                  </span>
                  <input type="email" placeholder="Email *" class="form-control form-control-sm" v-model="user.email"
                    ern="[^\s@]+@[^\s@]+\.[^\spatt@]+" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a valid email.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.phone_no">
                    {{ errors.phone_no }}
                  </span>
                  <input maxlength="11" pattern="[0-9]{11}" type="text" placeholder="Phone *"
                    class="form-control form-control-sm" v-model="user.phone_no" @keypress="isNumber($event)"
                    required />
                  <div class="invalid-feedback text-danger">
                    Please provide a number atleast 11 digits.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <!-- <label>Password <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.password">
                    {{ errors.password }}
                  </span>
                  <input type="password" placeholder="Password *" id="password" name="password"
                    class="form-control form-control-sm" v-model="user.password" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a password.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.confirmPassword">
                    {{ errors.confirmPassword }}
                  </span>
                  <input type="password" id="confirmPassword" name="confirmPassword" placeholder="Confirm Password *"
                    class="form-control form-control-sm" data-match="#password"
                    data-match-error="Passwords do not match" v-model="user.confirmPassword" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a confirm password.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <!-- <label>Password <span class="text-danger">*</span></label> -->
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.dob">
                    {{ errors.dob }}
                  </span>
                  <input type="date" placeholder="Date of Birth *" :max="new Date().toISOString().split('T')[0]"
                    class="form-control form-control-sm dateclass placeholderclass"
                    onClick="$(this).removeClass('placeholderclass')" v-model="user.dob" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a date of birth.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.cnic">
                    {{ errors.cnic }}
                  </span>
                  <input maxlength="13" pattern="[0-9]{13}" type="text" placeholder="CNIC *"
                    class="form-control form-control-sm" v-model="user.cnic" @keypress="isNumber($event)" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a cnic atleast 13 digits.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.height">
                    {{ errors.height }}
                  </span>
                  <input pattern="[0-9]" type="number" placeholder="Height" class="form-control form-control-sm"
                    v-model="user.height" />
                  <div class="invalid-feedback text-danger">
                    Please provide a height.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.weight">
                    {{ errors.weight }}
                  </span>
                  <input pattern="[0-9]" type="number" placeholder="Wheight" class="form-control form-control-sm"
                    v-model="user.weight" />
                  <div class="invalid-feedback text-danger">
                    Please provide a weight.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->

                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.blood_group">
                    {{ errors.blood_group }}
                  </span>
                  <select class="c-form-select" v-model="user.blood_group">
                    <option value="">Select Blood Group...</option>
                    <option value="O+">O positive</option>
                    <option value="O-">O negative</option>
                    <option value="A+">A positive</option>
                    <option value="A-">A negative</option>
                    <option value="B+">B positive</option>
                    <option value="B-">B negative</option>
                    <option value="AB+">AB positive</option>
                    <option value="AB-">AB negative</option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a blood group.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.marital_status">
                    {{ errors.marital_status }}
                  </span>
                  <select class="c-form-select" v-model="user.marital_status" required>
                    <option value="">Select Marital Status... *</option>
                    <option value="Married">Married</option>
                    <option value="Unmarried">Unmarried</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Separated">Separated</option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a Marital Status.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.age">
                    {{ errors.age }}
                  </span>
                  <input type="number" placeholder="Age *" class="form-control form-control-sm" v-model="user.age"
                    required />
                  <div class="invalid-feedback text-danger">
                    Please provide an age.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.district_id">
                    {{ errors.district_id }}
                  </span>
                  <select class="c-form-select" v-model="user.district_id" v-on:change="fetchTehsils(this.value)"
                    required>
                    <option value="">Select District ... *</option>
                    <option v-for="district in districts" :value="district.id" v-bind:key="district.id">
                      {{ district.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a district.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.tehsil_id">
                    {{ errors.tehsil_id }}
                  </span>
                  <select class="c-form-select" v-model="user.tehsil_id"
                    v-on:change="fetchVCs({ tehsil_id_id: this.value })" required>
                    <option value="">Select Tehsil ... *</option>
                    <option v-for="tehsil in tehsils" :value="tehsil.id" v-bind:key="tehsil.id">
                      {{ tehsil.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select first district and then tehsil.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.area_id">
                    {{ errors.area_id }}
                  </span>
                  <select class="c-form-select" v-model="user.area_id" required>
                    <option value="" selected>Select VC ... *</option>
                    <option v-for="area in areas" :value="area.id" v-bind:key="area.id">
                      {{ area.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select first tehsil and then area.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12">
                  <textarea class="text-area" v-model="user.short_bio" placeholder="Short bio... " rows="7"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mt-2">
                  <p><b>Gender *</b></p>
                  <span class="text-danger d-block" v-if="errors.gender">
                    {{ errors.gender }}
                  </span>
                  <div class="d-flex">
                    <div>
                      <input type="radio" class="c-checkbox mb-3" id="male" v-model="user.gender" value="Male"
                        checked />
                      <label for="male" class="ms-2">Male</label>
                    </div>
                    <div class="mx-2">
                      <input type="radio" class="c-checkbox mb-3" id="female" v-model="user.gender" value="Female" />
                      <label for="female" class="ms-2">Female</label>
                    </div>
                    <div class="mx-2">
                      <input type="radio" class="c-checkbox mb-3" id="transgender" v-model="user.gender"
                        value="Transgender" />
                      <label for="transgender" class="ms-2">Transgender</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mt-2">
                  <p><b>Send Credentials via email</b></p>
                  <span class="text-danger d-block" v-if="errors.send_credentials">
                    {{ errors.send_credentials }}
                  </span>
                  <div class="d-flex">
                    <div class="mx-2">
                      <input type="checkbox" class="c-checkbox mb-3" id="send-credentials"
                        v-model="user.send_credentials" value="send-credentials" />
                      <label for="send-credentials" class="ms-2">Send Credentials via email</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addAppUser.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  mixins: [helpers],
  data() {
    return {
      user: {
        f_name: null,
        l_name: null,
        phone_no: null,
        email: null,
        cnic: null,
        dob: null,
        age: null,
        blood_group: "",
        height: null,
        weight: null,
        short_bio: null,
        marital_status: "",
        district_id: "",
        tehsil_id: "",
        area_id: "",
        gender: "Male",
        send_credentials: false,
        password: null,
        confirmPassword: null,
        created_by: localStorage.getItem("uid"),
      },
      districts: [],
      tehsils: [],
      areas: [],
      errors: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchDistricts();
  },
  methods: {
    submit() {
      var form = document.getElementById("app-user");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.user);
        this.$store
          .dispatch("appUsers/store", { data })
          .then((res) => {
            this.loading = false;
            if (res.success == true) {
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              if (this.$refs.addAppUser) {
                this.$nextTick(() => {
                  this.$refs.addAppUser.reset();
                  this.$refs.addAppUser.classList.remove("was-validated");
                });
              }
              this.errors = [];
            } else {
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show("Something went wrong!", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    fetchDistricts() {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("districts/getAll", {})
        .then((response) => {
          this.districts = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchTehsils() {
      // console.log(this.district_id);
      const district_id = this.user.district_id
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("tehsils/getAll", { district_id })
        .then((response) => {
          this.tehsils = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchVCs() {
      const tehsil_id = this.user.tehsil_id
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("areas/getAll", { tehsil_id })
        .then((response) => {
          this.areas = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
