<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <strong>All Campaigns</strong>
        </div>
        <div class="card-body pt-3">
          <table class="table">
            <thead class="thead-dark">
              <th>Campaign Title</th>
              <th>Doctor</th>
              <th>City</th>
              <th>Popularity</th>
              <th>Max Participation</th>
              <th>Status</th>
            </thead>
            <tbody>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Name",
          value: "f_name",
        },
        {
          text: "Email",
          value: "email",
        },
      ],
      items: [],
      module: "admins",
      loading: false,
      changePassword: true,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("admins/getAll", { id })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}
</style>
  