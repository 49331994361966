// router/app-user/index.js
import { createRouter, createWebHistory } from "vue-router";

// views list for Medical Historys here
import ListMedicalHistory from "@/views/app-user/history/index";
import AddMedicalHistory from "@/views/app-user/history/add";
import EditMedicalHistory from "@/views/app-user/history/edit";
import MedicalHistoryView from "@/views/app-user/history/show";

const routes = [
  {
    path: '/medical-histories/',
    name: 'Medical History',
    component: ListMedicalHistory,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    },
    props: true
  },
  {
    path: '/medical-histories/add/:id',
    name: 'Add Medical History',
    component: AddMedicalHistory,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_app_user'
    }
  },
  {
    path: '/medical-histories/edit/:id',
    name: 'Edit Medical History',
    component: EditMedicalHistory,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_app_user'
    },
    props: true
  },
  {
    path: '/medical-histories/show/:id',
    name: 'Show Medical History',
    component: MedicalHistoryView,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    },
    props: true
  },
];

export default routes;
