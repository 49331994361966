// views list for supports here
import ListSupport from "@/views/supports/index";
import AddSupport from "@/views/supports/add";
import EditSupport from "@/views/supports/edit";
// import ShowSupport from "@/views/supports/show";

const routes = [
  {
    path: '/supports/',
    name: 'Supports',
    component: ListSupport,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
  {
    path: '/supports/add',
    name: 'Add Support',
    component: AddSupport,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    }
  },
  {
    path: '/supports/edit/:id',
    name: 'Edit Support',
    component: EditSupport,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
//   {
//     path: '/supports/show/:id',
//     name: 'Support Show',
//     component: ShowSupport,
//     meta: {
//       requiresAuth: true,
//       requiredPermission: 'view_location'
//     },
//     props: true
//   },
];

export default routes;
