// router/app-user/index.js
import { createRouter, createWebHistory } from "vue-router";

// views list for family members here
import ListFamilyMember from "@/views/app-user/family-members/index";
import AddFamilyMember from "@/views/app-user/family-members/add";
import EditFamilyMember from "@/views/app-user/family-members/edit";
import FamilyMemberView from "@/views/app-user/show";

const routes = [
  {
    path: '/family-members/',
    name: 'Family Members',
    component: ListFamilyMember,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    },
    props: true
  },
  {
    path: '/family-members/add/:id',
    name: 'Add Family Member',
    component: AddFamilyMember,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_app_user'
    }
  },
  {
    path: '/family-members/edit/:id',
    name: 'Edit Family Member',
    component: EditFamilyMember,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_app_user'
    },
    props: true
  },
  {
    path: '/family-members/show/:id',
    name: 'Show Family Member',
    component: FamilyMemberView,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    },
    props: true
  },
];

export default routes;
