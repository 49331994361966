<template>
  <div class="custom-date-picker">
    <div class="calendar">
      <div class="month">
        <button
        class="prev-button"
          type="button"
          @click="prevMonth"
          :disabled="isPrevMonthDisabled"
        >
          &lt;
        </button>
        <span>{{ currentMonth }}</span>
        <button class="next-button" type="button" @click="nextMonth">&gt;</button>
      </div>
      <div class="days">
        <div
          v-for="(day, index) in daysOfWeek"
          :key="index"
          class="weekdays"
          :class="{ selected: isSelectedDay(day) }"
        >
          <span @click="toggleDateWithDay(day, !isSelectedDay(day))">{{
            day
          }}</span>
        </div>
        <div v-for="blank in firstDayOfMonth" :key="blank" class="blank"></div>
        <div v-for="day in daysInMonth" :key="day" class="day">
          <span
            :class="{
              selected: isSelected(getISODate(day)),
              pastDate: isDateDisabled(getISODate(day)),
            }"
            @click="toggleDate(getISODate(day))"
            >{{ day }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div v-if="isDisplayDates" class="selected-dates">
    <h6 class="w-100">Selected Dates:</h6>
    <ul>
      <li v-for="(date, index) in selectedDates" :key="index">
        {{ formatDate(date) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: { slotsDates: Array, isDisplayDates: Boolean },
  data() {
    const currentDate = new Date().toISOString().split("T")[0];

    return {
      currentMonth: "",
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      selectedDates: [currentDate],
      disableDates: [],
      selectedWeekDays: [],
    };
  },
  computed: {
    daysInMonth() {
      const year = this.currentDate.getUTCFullYear();
      const month = this.currentDate.getUTCMonth();
      return new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
    },
    firstDayOfMonth() {
      const year = this.currentDate.getUTCFullYear();
      const month = this.currentDate.getUTCMonth();
      return new Date(Date.UTC(year, month, 1)).getUTCDay();
    },
    currentDate() {
      return new Date(
        Date.UTC(
          new Date(this.currentMonth).getFullYear(),
          new Date(this.currentMonth).getMonth(),
          1
        )
      );
    },
    isPrevMonthDisabled() {
      const today = new Date();
      const current = new Date(this.currentDate);
      return current <= today;
    },
  },
  created() {
    this.updateCurrentMonth();
    if (this.slotsDates.length > 0) {
      this.selectedDates = [];
      this.disableDates = this.slotsDates;
    }
  },
  methods: {
    updateCurrentMonth() {
      const currentDate = new Date();
      this.currentMonth = currentDate.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
    },
    prevMonth() {
      if (this.isPrevMonthDisabled) return;
      const newDate = new Date(this.currentDate);
      newDate.setUTCMonth(newDate.getUTCMonth() - 1);
      this.currentMonth = newDate.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
    },
    nextMonth() {
      const newDate = new Date(this.currentDate);
      newDate.setUTCMonth(newDate.getUTCMonth() + 1);
      this.currentMonth = newDate.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    getISODate(day) {
      const year = this.currentDate.getUTCFullYear();
      const month = this.currentDate.getUTCMonth();
      return new Date(Date.UTC(year, month, day)).toISOString().split("T")[0];
    },
    toggleDate(isoDate) {
      const index = this.selectedDates.indexOf(isoDate);
      if (index === -1 && this.disableDates.indexOf(isoDate) === -1) {
        if (new Date(isoDate) >= new Date().setHours(0, 0, 0, 0)) {
          this.selectedDates.push(isoDate);
        }
      } else {
        this.selectedDates.splice(index, 1);
      }
      this.sendDataToParent();
    },
    toggleDateWithDay(dayOfWeek, isSelectDate = true) {
      const year = this.currentDate.getUTCFullYear();
      const month = this.currentDate.getUTCMonth();
      const selectedDates = [];
      const monthKey = `${year}-${month}`;

      if (!this.selectedWeekDays[monthKey]) {
        this.selectedWeekDays[monthKey] = [];
      }

      const dayIndex = this.daysOfWeek.indexOf(dayOfWeek);

      // Find all occurrences of the selected day of the week in the current month
      for (let day = 1; day <= this.daysInMonth; day++) {
        const date = new Date(Date.UTC(year, month, day));
        if (date.getUTCDay() === dayIndex) {
          const isoDate = date.toISOString().split("T")[0];
          // Check if date is today or in the future before adding to selectedDates
          if (new Date(isoDate) >= new Date().setHours(0, 0, 0, 0)) {
            selectedDates.push(isoDate);
          }
        }
      }

      // add dates into selected dates
      selectedDates.forEach((date) => {
        const index = this.selectedDates.indexOf(date);
        if (
          isSelectDate &&
          index === -1 &&
          this.disableDates.indexOf(date) < 0
        ) {
          this.selectedDates.push(date);
        } else if (!isSelectDate && index !== -1) {
          this.selectedDates.splice(index, 1);
        }
      });

      const weekDayIndex = this.selectedWeekDays[monthKey].indexOf(dayOfWeek);
      if (weekDayIndex === -1) {
        this.selectedWeekDays[monthKey].push(dayOfWeek);
      } else {
        this.selectedWeekDays[monthKey].splice(weekDayIndex, 1);
      }

      this.sendDataToParent();
    },

    isSelected(isoDate) {
      return this.selectedDates.includes(isoDate);
    },
    isSelectedDay(dayOfWeek) {
      const year = this.currentDate.getUTCFullYear();
      const month = this.currentDate.getUTCMonth();
      const monthKey = `${year}-${month}`;
      return (
        this.selectedWeekDays[monthKey] &&
        this.selectedWeekDays[monthKey].includes(dayOfWeek)
      );
    },
    isDateDisabled(isoDate) {
      const today = new Date();
      const selectedDate = new Date(isoDate);
      // Compare dates without comparing time (setHours(0,0,0,0))
      let isdateSelected =
        selectedDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
      if (isdateSelected) {
        return isdateSelected;
      } else if (this.disableDates.indexOf(isoDate) >= 0) {
        return true;
      }
      return false;
    },
    sendDataToParent() {
      this.$emit("selectedDates", this.selectedDates);
    },
  },
};
</script>



<style scoped>
.custom-date-picker {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.next-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px; /* Adjust the size as needed */
  color: white; /* Adjust the color */
}

.prev-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px; /* Adjust the size as needed */
  color: white; /* Adjust the color */
}

.calendar {
  border-radius: 3%;
  border: 1px solid #d1d1d1;
  width: 100%;
}

.month {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  background: rgba(0, 0, 128, 1);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: white;
  font-size: 17px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding: 15px;
}

.day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
}
.weekdays {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.day span {
  cursor: pointer;
}

.day:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}
.weekdays:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}

.blank {
  visibility: hidden;
}

.selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: rgba(0, 0, 128, 1);
  color: white;
  border-radius: 50%;
  padding: 5px;
}
.selected:hover {
  background-color: rgba(0, 0, 128, 1);
  color: white;
}
.selected-dates {
  width: 35%;
}

.selected-dates h4 {
  margin-bottom: 10px;
}

.selected-dates ul {
  padding-left: 20px;
}

.pastDate {
  color: red;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
