<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs></Bread_crumbs>
        <router-link to="/user-messages" class="c-button cbg-primary mb-3">Back</router-link>
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add User Message</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" id="user_message" ref="addUserMessage" class="needs-validation" novalidate>
              <!-- <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <span class="text-danger d-block" v-if="errors.title">
                    {{ errors.title }}
                  </span>

                  <input
                    type="text"
                    placeholder="Title *"
                    class="form-control form-control-sm"
                    v-model="user_message.title"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a title.
                  </div>
                </div>
              </div> -->
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.district_id">
                    {{ errors.district_id }}
                  </span>
                  <select class="c-form-select" v-model="user_message.district_id" v-on:change="fetchTehsils()">
                    <option value="">Select District ... </option>
                    <option v-for="district in districts" :value="district.id" v-bind:key="district.id">
                      {{ district.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a district.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <select class="c-form-select" v-model="user_message.tehsil_id">
                    <option value="">Select Tehsil... </option>
                    <option v-for="tehsil in tehsils" :value="tehsil.id" v-bind:key="tehsil.id">
                      {{ tehsil.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a tehsil.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.from_age">
                    {{ errors.from_age }}
                  </span>
                  <input type="number" placeholder="From Age " class="form-control form-control-sm"
                    v-model="user_message.from_age" :required="user_message.to_age ? true : false" />
                  <div class="invalid-feedback text-danger">
                    Please select from age.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.to_age">
                    {{ errors.to_age }}
                  </span>
                  <input type="number" placeholder="To Age " class="form-control form-control-sm"
                    v-model="user_message.to_age" :required="user_message.from_age ? true : false" />
                  <div class="invalid-feedback text-danger">
                    Please select to age.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.interest_ids">
                    {{ errors.interest_ids }}
                  </span>
                  <multiselect v-model="user_message.interest_ids" :options="personOptions" :multiple="true"
                    :taggable="true" group-values="libs" group-label="language" :group-select="true"
                    :close-on-select="false" track-by="title" label="title" placeholder="Select Interest *" required
                    @tag="addTag"></multiselect>
                  <div class="invalid-feedback text-danger">
                    Please select a Interest.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.to_age">
                    {{ errors.to_age }}
                  </span>
                  <select class="c-form-select" v-model="user_message.type" required>
                    <option value="">Select Type... *</option>
                    <option value="Survey">Survey</option>
                    <option value="Booking">Booking</option>
                    <option value="Knowledge">Knowledge</option>
                    <option value="General">General</option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select type.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 mb-3" v-if="user_message.type == 'Survey'">
                  <select class="c-form-select" v-model="user_message.survey_id" required>
                    <option value="">Select Survey Form... *</option>
                    <option v-for="form in forms" :value="form.id" v-bind:key="form.id">
                      {{ form.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a form.
                  </div>
                </div>
                <div class="col-md-12 mb-3" v-if="user_message.type == 'Knowledge'">
                  <select class="c-form-select" v-model="user_message.knowledge_id" required>
                    <option value="">Select Knowledge... *</option>
                    <option v-for="knowledge in knowledges" :value="knowledge.id" v-bind:key="knowledge.id">
                      {{ knowledge.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a knowledge.
                  </div>
                </div>
                <div class="col-md-12 mb-3" v-if="user_message.type == 'General'">
                  <input type="text" v-model="user_message.text" placeholder="Type message *"
                    class="form-control form-control-sm" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a text.
                  </div>
                </div>
              </div>

              <!-- <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <textarea name="" rows="7" v-model="user_message.desc" id="" placeholder="Type detail..." class="text-area"></textarea>
                  <div class="invalid-feedback text-danger">
                    Please enter a detail.
                  </div>
                </div>
              </div> -->
              <!-- <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <label for="file-upload" class="custom-file-upload form-control">Upload File *</label>
                  <span class="text-danger d-block" v-if="errors.file">
                    {{ errors.file }}
                  </span>
                  <input
                    type="file"
                    ref="file"
                    id="file-upload"
                    accept="image/*,audio/*"
                    placeholder="Select File *"
                    class="form-control form-control-sm"
                    @change="selectFile()"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please select a file.
                  </div>
                  <img
                    v-if="filePreview"
                    :src="filePreview"
                    alt=""
                    class="mt-3"
                    width="50%"
                    height="200"
                  />
                </div>
              </div> -->
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addUserMessage.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  mixins: [helpers],
  data() {
    return {
      user_message: {
        title: null,
        type: "",
        knowledge_id: "",
        survey_id: "",
        interest_ids: null,
        text: null,
        from_age: null,
        to_age: null,
        district_id: "",
        tehsil_id: "",
        created_by: localStorage.getItem("uid"),
      },
      filePreview: null,
      districts: [],
      tehsils: [],
      knowledges: [],
      forms: [],
      errors: [],
      loading: false,
      personOptions: [
        {
          language: "Select All",
          libs: [],
        },
      ],
    };
  },
  mounted() {
    this.fetchInterest();
    this.fetchDistricts();
    this.fetchKnowledges();
    this.fetchSurveyForms();
  },
  methods: {
    submit() {
      var form = document.getElementById("user_message");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.user_message);
        this.$store
          .dispatch("userMessages/store", { data })
          .then((res) => {
            this.loading = false;
            if (res.success == true) {
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              if (this.$refs.addUserMessage) {
                this.$nextTick(() => {
                  this.$refs.addUserMessage.reset();
                  this.$refs.addUserMessage.classList.remove("was-validated");
                });
              }
              this.errors = [];
            } else {
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    fetchDistricts() {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("districts/getAll", {})
        .then((response) => {
          this.districts = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.user_message.file = reader.result;
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    fetchTehsils() {
      const district_id = this.user_message.district_id;
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("tehsils/getAll", { district_id })
        .then((response) => {
          this.tehsils = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchInterest() {
      this.loading = true;
      this.$store
        .dispatch("interests/getAll", { paginate: false })
        .then((response) => {
          let res = response.data;
          res = res.map((e) => {
            return { id: e.id, title: e.title }
          })
          this.personOptions[0].libs = res;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchKnowledges() {
      const status = 'Active';
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("healthLiteracyContent/getAll", { status })
        .then((response) => {
          this.knowledges = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchSurveyForms() {
      const status = 'Active';
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("dynamicForms/getAll", { status })
        .then((response) => {
          this.forms = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
