<template>
    <div class="box" :class="['bg-' + bgColor]">
        ffdsfasf
    </div>
</template>

<script>
export default {
    name:"CardBox",
    props:{
        bgColor:{
            type: String,
        }
    }
}
</script>

<style>
.box{
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 5px;
}
</style>