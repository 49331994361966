<template>
  <div class="d-flex align-items-center pt-3">
    <div
      class="box align-items-center justify-content-center"
      :class="['bg-' + color]"
    >
      <span class="svgIcon" v-html="svgIcon"></span>
    </div>
    <p class="title ms-2">{{ title }}</p>
  </div>
  <div class="card mt-4">
    <div class="card-body d-flex align-items-center">
      <svg
        viewBox="0 0 166 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 42.9278C4.02411 45.2852 11.1889 50 15.6553 50C21.2383 50 27.5191 35.3505 32.4043 35.3505C37.2894 35.3505 43.5702 44.4433 49.1532 44.4433C54.7362 44.4433 59.6213 23.732 65.9021 23.732C72.183 23.732 74.2766 37.8763 82.6511 37.8763C91.0255 37.8763 91.0255 1 98.0043 1C104.983 1 109.17 17.1649 115.451 17.1649C121.732 17.1649 125.221 7.06186 131.502 7.06186C137.783 7.06186 139.179 36.866 148.251 36.866C157.323 36.866 159.417 31.8144 165 32.3196"
          :stroke="stokeColor"
          stroke-width="2"
        />
      </svg>

      <div class="inBox text-center ms-2" :style="{ backgroundColor: bgColor }">
        <p class="m-0 text-lg">{{ lgText }}</p>
        <p class="m-0 text-sm" :style="{ color: stokeColor }">{{ smText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DropDown3 from "../base/dropdown/DropDown3.vue";
import { apexDashboard } from "../../../data/comon";
import CardBox from "@/components/dashboard/box.vue";
export default {
  props: {
    svgIcon: String,
    smText: String,
    lgText: String,
    title: String,
    color: String,
    stokeColor: String,
    bgColor: String,
  },
  components: {
    DropDown3,
    CardBox,
  },
  data() {
    return {
      apexDashboard: apexDashboard,
      chartOptions: {
        ...apexDashboard.options6,
        xaxis: {
          ...apexDashboard.options6.xaxis,
          labels: {
            show: false, // Hide the labels on the x-axis
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    updateTheme(e) {
      this.chartOptions = {
        theme: {
          palette: "palette2",
        },
      };
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 14px;
  font-weight: 500;
}
.inBox {
  border-radius: 10px;
  width: 95px;
  height: 60px;
  padding: 12px;
}

.box {
  width: 38px;
  height: 38px;
}

.text-lg {
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
}
.text-sm {
  font-size: 14px;
}

.svgIcon {
  width: 20px;
  height: 20px;
}
</style>
