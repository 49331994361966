<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container">
      <div class="row">
        <div class="col-md-12 mt-5">
          <Bread_crumbs />
          <!-- <img src="http://www.cortazar-split.com/dog_origin.jpeg" alt="" width="100" height="100">
          <img src="https://ibcsps.org/ibcsps/storage/students/wZGTmK63Rpn4UcrBoK5fobcYGX578zvIQb4weMsa.jpg" alt="" width="100" height="100"> -->
          <UsersCard
            v-if="dashboard"
            :districtData="dashboard.districts"
            :totalUser="dashboard.total_users"
          />
          <div class="row">
            <div class="col-xxl-6 col-md-6 box-col-12">
              <TaskCard v-if="dashboard" :taskData="dashboard.tasks" />
            </div>
            <div class="col-xxl-6 col-md-6 box-col-12">
              <TotalUsersCard
                v-if="dashboard"
                :admins="dashboard.admins"
                :doctors="dashboard.doctors"
                :chw="dashboard.chw"
                :patients="dashboard.patients"
              />
            </div>
          </div>
          <AppointmentCard v-if="dashboard" :appointmentData="dashboard.appointments" />
          <!-- <OverallPerformanceCard /> -->
          <Campaigns />
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import OverallBalanceCard from "./default/OverallBalanceCard.vue";
import RecentSalesCard from "./default/RecentSalesCard.vue";
import UsersCard from "./default/UsersCard.vue";
import TotalUsersCard from "./default/TotalUserCard.vue";
import TaskCard from "./default/TaskCard.vue";
import AppointmentCard from "./default/AppointmentCard.vue";

import OverallPerformanceCard from "./default/OverallPerformanceCard.vue";
import Campaigns from "./default/campaigns.vue";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    TaskCard,
    UsersCard,
    AppointmentCard,
    TotalUsersCard,
    Campaigns,
    Bread_crumbs,
    OverallBalanceCard,
    RecentSalesCard,
    OverallPerformanceCard,
  },
  data() {
    return {
      dashboard: null,
      imageUrl: "https://learnfirst.pk/tma/backend/storage/Logo/171765630666615af26e0d0.png",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("dashboard/getAll", {})
        .then((response) => {
          this.dashboard = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
