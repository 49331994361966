<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/faqs" class="c-button cbg-primary mb-3">Back</router-link>
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Faq</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addFaq" id="add-faq" class="needs-validation" novalidate>
              <div class="form-group mb-3">
                <!-- <label>Question <span class="text-danger">*</span></label> -->
                <span class="text-danger d-block" v-if="errors.question">
                    {{ errors.question }}
                  </span>
                <input type="text" class="form-control form-control-sm" placeholder="Question *" required
                  v-model="faq.question" />
                <div class="invalid-feedback text-danger">
                  Please provide a question.
                </div>
              </div>
              <div class="form-group mb-3">
                <!-- <label>Answer <span class="text-danger">*</span></label> -->
                <ckeditor :editor="editor" height="50px" v-model="faq.answer" placeholder="Answer *"
                  :config="editorConfig" required></ckeditor>
                <div class="invalid-feedback text-danger">
                  Please provide a answer.
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addFaq.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import helpers from "@/helpers/helpers";

export default {
  components: {
    Bread_crumbs,
    ckeditor: CKEditor.component
  },
  mixins:[helpers],
  data() {
    return {
      faq: {
        question: "",
        answer: "",
      },
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        placeholder: "Answer *",
        config: { height: '300px' }
      },
      menutoogle: false,
      loading: false,
      errors:[]
    };
  },
  mounted() { },
  methods: {
    submit() {
      var form = document.getElementById("add-faq");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.faq);
        this.$store
          .dispatch("faqs/store", { data })
          .then((res) => {
            this.loading = false;
            if (res.success == true) {
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              if (this.$refs.addFaq) {
                this.$nextTick(() => {
                  this.$refs.addFaq.reset();
                  this.$refs.addFaq.classList.remove("was-validated");
                });
              }
              this.faq={};
              this.errors = [];
            } else {
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show("Something went wrong!", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },
};
</script>
<style>
.ck-content {
  height: 300px;
}
</style>