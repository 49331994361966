// views list for healthLiteracyContents here
import ListhealthLiteracyContent from "@/views/health-literacy-content/index";
import AddhealthLiteracyContent from "@/views/health-literacy-content/add";
import EdithealthLiteracyContent from "@/views/health-literacy-content/edit";
import ShowhealthLiteracyContent from "@/views/health-literacy-content/show";

const routes = [
  {
    path: '/health-literacy-content/',
    name: 'Health Literacy Content',
    component: ListhealthLiteracyContent,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_hlc'
    },
    props: true
  },
  {
    path: '/health-literacy-content/add',
    name: 'Add Health Literacy Content',
    component: AddhealthLiteracyContent,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_hlc'
    }
  },
  {
    path: '/health-literacy-content/edit/:id',
    name: 'Edit Health Literacy Content',
    component: EdithealthLiteracyContent,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_hlc'
    },
    props: true
  },
  {
    path: '/health-literacy-content/show/:id',
    name: 'Show Health Literacy Content',
    component: ShowhealthLiteracyContent,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_hlc'
    },
    props: true
  },
];

export default routes;
