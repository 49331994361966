<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs></Bread_crumbs>
        <router-link
          to="/health-literacy-content"
          class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="col-md-8 offset-md-2">
          <div class="card mt-4">
            <div class="card-header">
              <h4 class="card-title">Add Health Literacy Content</h4>
            </div>
            <div class="card-body">
              <form
                @submit.prevent="submit"
                id="hlcm"
                ref="editHealthLiteracyContent"
                class="needs-validation"
                novalidate
              >
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <select
                    class="c-form-select"
                    v-model="hlcm.type"
                    @change="selectTypeRelatedFile()"
                    required
                  >
                    <option value="" disabled>Select Content Type...</option>
                    <option value="pdf">PDF</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a content type.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <!-- <label>First Name <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.title">
                    {{ errors.title }}
                  </span>

                  <input
                    type="text"
                    placeholder="Title *"
                    class="form-control form-control-sm"
                    v-model="hlcm.title"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a title.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.category_id">
                      {{ errors.category_id }}
                    </span>
                    <select
                      class="c-form-select"
                      v-model="hlcm.category_id"
                      required
                    >
                      <option value="" selected disabled>Select Category ... *</option>
                      <option
                        v-for="category in categories"
                        :value="category.id"
                        v-bind:key="category.id"
                      >
                        {{ category.title }}
                      </option>
                    </select>
                    <div class="invalid-feedback text-danger">
                      Please select a category.
                    </div>
                  </div>
                </div>
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <span class="text-danger d-block" v-if="errors.desc">
                    {{ errors.desc }}
                  </span>
                  <textarea
                    name=""
                    id=""
                    rows="7"
                    class="text-area"
                    placeholder="Type description.... *"
                    v-model="hlcm.desc"
                    required
                  ></textarea>
                  <div class="invalid-feedback text-danger">
                    Please provide a description.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 mb-2" v-if="hlcm.tags.length > 0">
                  <div class="tags">
                    <div
                      v-for="(tag, index) in hlcm.tags"
                      :key="index"
                      class="tag"
                    >
                      {{ tag }}
                      <span class="remove-tag" @click="removeTag(index)"
                        >x</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <textarea
                    @keydown="handleKeydown"
                    v-model="tag"
                    placeholder="Enter tags... *"
                    class="form-control"
                  ></textarea>
                  <div class="invalid-feedback text-danger">
                    Please enter a detail.
                  </div>
                </div>
              </div>
              <div class="form-group row" v-if="hlcm.type == 'video'">
                <div class="col-md-12 mb-3">
                  <label for="thumbnail-upload" class="custom-file-upload form-control">Upload Thumbnail </label>
                  <span class="text-danger d-block" v-if="errors.thumbnail">
                    {{ errors.thumbnail }}
                  </span>
                  <input
                    type="file"
                    id="thumbnail-upload"
                    ref="thumbnail"
                    accept="image/*"
                    placeholder="upload thumbnail"
                    class="form-control form-control-sm"
                    @change="selectThumbnail()"
                  />
                  <div class="invalid-feedback text-danger">
                    Please upload a thumbnail.
                  </div>
                  <img
                    v-if="thumbnailPreview"
                    :src="thumbnailPreview"
                    alt=""
                    class="mt-3"
                    width="50%"
                    height="200"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <label for="file-upload" class="custom-file-upload form-control"
                    >Upload {{ hlcm.type }} </label
                  >
                  <span class="text-danger d-block" v-if="errors.file">
                    {{ errors.file }}
                  </span>
                  <input
                    id="file-upload"
                    type="file"
                    ref="file"
                    :accept="fileAccept"
                    placeholder="Select File"
                    class="form-control form-control-sm"
                    @change="selectFile()"
                  />
                  <div class="invalid-feedback text-danger">
                    Please select a {{ hlcm.type }}.
                  </div>
                  <img
                    v-if="filePreview && hlcm.type == 'image'"
                    :src="filePreview"
                    alt=""
                    class="mt-3"
                    width="50%"
                    height="200"
                  />
                  <iframe
                    v-if="filePreview && hlcm.type == 'pdf'"
                    :src="filePreview"
                    width="50%"
                    height="200"
                    class="mt-3"
                  ></iframe>

                  <video
                    width="50%"
                    class="mt-3"
                    height="200"
                    controls
                    v-if="filePreview && hlcm.type == 'video'"
                    :src="filePreview"
                  >
                    <!-- <source :src="filePreview" type="video/mp4" />
                    Your browser does not support the video tag. -->
                  </video>
                  <div v-if="uploadProgress > 0">
                    <progress :value="uploadProgress" max="100">
                      {{ uploadProgress }}%
                    </progress>
                    <p>{{ uploadProgress }}%</p>
                  </div>
                </div>
              </div>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <button type="submit" class="c-button cbg-primary">
                      Submit
                    </button>
                    <!-- <button type="button" class="ms-2 c-button cbg-secondary">
                      Cancel
                    </button> -->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  mixins: [helpers],
  data() {
    return {
      hlcm: {
        title: null,
        type: "image",
         category_id:"",
        tags: [],
        desc: null,
        file: null,
        thumbnail: null,
        created_by: localStorage.getItem("uid"),
      },
      tag: [],
      categories: [],
      thumbnailPreview: null,
      filePreview: null,
      uploadProgress: 0,
      fileAccept: "image/*",
      errors: [],
      userId: null,
      loading: false,
    };
  },
  mounted() {
    this.hlcmId = this.$route.params.id;
    this.fetch(this.hlcmId);
    this.fetchCategories();
  },
  methods: {
    fetch(id) {
      this.loading = false;
      this.$store
        .dispatch("healthLiteracyContent/getSingle", { id })
        .then((response) => {
          this.hlcm = response.data;
          this.hlcm.tags = this.hlcm.splited_tags;
          this.thumbnailPreview = this.hlcm.file.thumbnail;
          this.filePreview = this.hlcm.file.path;
          this.fileAccept = this.hlcm.file.type;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      var form = document.getElementById("hlcm");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        this.errors = [];
        const data = toRaw(this.hlcm);
        const id = this.hlcmId;
        this.$store
          .dispatch("healthLiteracyContent/update", { data, id })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly updated.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show("Something went wrong!", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    onUploadProgress(progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    selectThumbnail() {
      let file = this.$refs.thumbnail.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.hlcm.thumbnail = reader.result;
          this.thumbnailPreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "video/mp4" ||
        file.type == "application/pdf"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
          if (this.hlcm.type != "image") {
            this.$toast.show("File format not supported. JPG/JPEG/PNG", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            return;
          }
        } else if (file.type == "application/pdf") {
          this.attachmentType = "Pdf";
          if (this.hlcm.type != "pdf") {
            this.$toast.show("File format not supported. PDF", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            return;
          }
        } else {
          if (this.hlcm.type != "video") {
            this.$toast.show("File format not supported. Video", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            return;
          }
          this.attachmentType = "Video";
        }
        let reader = new FileReader();
        this.filePreview = URL.createObjectURL(file);
        reader.onloadend = () => {
          this.hlcm.file = reader.result;
          // this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP4/PDF", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    handleKeydown(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.generateTags();
      }
    },

    generateTags() {
      const newTags = this.tag
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag);
      if (newTags.length > 0) {
        this.hlcm.tags = [...this.hlcm.tags, ...newTags];
        this.tag = "";
      }
    },
    removeTag(index) {
      this.hlcm.tags.splice(index, 1);
    },
    selectTypeRelatedFile() {
      if (this.hlcm.type == "image") {
        this.fileAccept = "image/*";
      } else if (this.hlcm.type == "pdf") {
        this.fileAccept = "application/pdf";
      } else if (this.hlcm.type == "video") {
        this.fileAccept = "video/mp4";
      }
    },
    fetchCategories() {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("categories/getAll", { paginate:false })
        .then((response) => {
          this.categories = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
