<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <!-- <span v-if="user"> / {{ user.f_name }}</span> -->
      </div>
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <div class="row">
              <div
                class="col-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="rounded-circle img-fluid"
                  width="130"
                  src="../../assets/images/user/3.png"
                  alt=""
                />
              </div>
              <div class="col-7">
                <div class="row">
                  <div class="col-4">
                    <p class="p-label">Name</p>
                    <p class="text-secondary" v-if="user">{{ user.f_name }}</p>
                  </div>
                  <div class="col-4">
                    <p class="p-label">Age</p>
                    <p class="text-secondary" v-if="user">
                      {{ user.age }} Year
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="p-label">Specialized</p>
                    <p class="text-secondary" v-if="user">
                      {{ specializations }}
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-4">
                    <p class="p-label">Email</p>
                    <p class="text-primary" v-if="user">{{ user.email }}</p>
                  </div>
                  <div class="col-4">
                    <p class="p-label">Number</p>
                    <p class="text-secondary" v-if="user">
                      +{{ user.phone_no }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="p-label">Qualification</p>
                    <p class="text-secondary" v-if="user">
                      {{ user.qualification.title }}
                    </p>
                  </div>
                </div>
                <div class="row"></div>
              </div>
              <div class="col-3">
                <div class="rounded w-100 h-100 box-bg p-2">
                  <div class="row">
                    <div class="col-3">
                      <p class="p-label">{{ rating }}.0</p>
                    </div>
                    <div class="col-8">
                      <Rating
                        starSize="15"
                        starColor="#FFD833"
                        inactiveColor="#BAB8B1"
                        :numberOfStars="5"
                        :disableClick="true"
                        :rating="rating"
                      />
                    </div>
                    <p class="text-secondary">Rating</p>
                  </div>
                  <div class="mt-3">
                    <p class="p-label">Patient</p>
                    <p class="text-secondary">100</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link py-3 active"
              data-bs-toggle="tab"
              aria-current="page"
              href="#tab1"
              >Upcoming Appointments</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab2"
              >Cancel Appointments</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab3"
              >Completed Appointments</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab4"
              >Duty Roaster</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab5"
              >Patient List</a
            >
          </li>
        </ul>
        <div class="card tab-card border-0">
          <div class="body p-3">
            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab1">
                <Timeline
                  :timelines="medicalHistory"
                  timelineType="medical-history"
                />
              </div>
              <div class="tab-pane fade" id="tab2">
                <Timeline
                  :timelines="appointments"
                  timelineType="appointments"
                />
              </div>
              <div class="tab-pane fade" id="tab3">
                <Timeline
                  :timelines="prescriptions"
                  timelineType="prescriptions"
                />
              </div>
              <div class="tab-pane fade" id="tab4">
                <DutyRoaster :doctor_id="userId" />
              </div>
              <div class="tab-pane fade" id="tab5">
                <PatientList :doctor_id="userId" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import Timeline from "@/components/app-user/Timeline.vue";
import DataTable from "@/components/dataTable.vue";
import Rating from "@/components/rating.vue";
import DutyRoaster from "@/components/duty_roaster.vue";
import PatientList from "@/components/patient-list.vue";

export default {
  components: {
    DataTable,
    Bread_crumbs,
    Timeline,
    Rating,
    DutyRoaster,
    PatientList
  },
  data() {
    return {
      dutyRoasters:null,
      rating: 3,
      medicalHistory: [
        {
          id: 1,
          title: "Fever",
          date: "20 Oct 2023",
          detail:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  consectetur adipiscing elit, sed do eiusmod tempor",
        },
        {
          id: 2,
          title: "Cold & Flu",
          date: "20 Oct 2023",
          detail:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  consectetur adipiscing elit, sed do eiusmod tempor",
        },
        {
          id: 3,
          title: "Diabetes",
          date: "20 Oct 2023",
          detail:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  consectetur adipiscing elit, sed do eiusmod tempor",
        },
        {
          id: 4,
          title: "Blood Presser",
          date: "20 Oct 2023",
          detail:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  consectetur adipiscing elit, sed do eiusmod tempor",
        },
        {
          id: 5,
          title: "Cancer",
          date: "20 Oct 2023",
          detail:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  consectetur adipiscing elit, sed do eiusmod tempor",
        },
        {
          id: 6,
          title: "Tified",
          date: "20 Oct 2023",
          detail:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  consectetur adipiscing elit, sed do eiusmod tempor",
        },
      ],
      appointments: [
        {
          id: 1,
          title: "Appointment",
          date: "20 Oct 2023",
          time: "11:00",
          day: "Monday",
          doctor: {
            id: 1,
            name: "Dr Taha Ahmad",
          },
        },
        {
          id: 2,
          title: "Appointment",
          date: "20 Oct 2023",
          time: "11:00",
          day: "Monday",
          doctor: {
            id: 1,
            name: "Dr Taha Ahmad",
          },
        },
        {
          id: 3,
          title: "Appointment",
          date: "20 Oct 2023",
          time: "11:00",
          day: "Monday",
          doctor: {
            id: 1,
            name: "Dr Taha Ahmad",
          },
        },
        {
          id: 4,
          title: "Appointment",
          date: "20 Oct 2023",
          day: "Monday",
          time: "11:00",
          doctor: {
            id: 1,
            name: "Dr Taha Ahmad",
          },
        },
        {
          id: 5,
          title: "Appointment",
          date: "20 Oct 2023",
          time: "11:00",
          day: "Monday",
          doctor: {
            id: 1,
            name: "Dr Taha Ahmad",
          },
        },
        {
          id: 6,
          title: "Appointment",
          date: "20 Oct 2023",
          time: "11:00",
          day: "Monday",
          doctor: {
            id: 1,
            name: "Dr Taha Ahmad",
          },
        },
      ],
      prescriptions: [
        {
          id: 1,
          title: "Prescription",
          date: "20 Oct 2023",
          time: "11:00",
          day: "Monday",
          isNew: true,
          doctor: {
            id: 1,
            name: "Dr Taha Ahmad",
          },
        },
        {
          id: 2,
          title: "Prescription",
          date: "20 Oct 2023",
          time: "11:00",
          day: "Monday",
          isNew: false,
          doctor: {
            id: 1,
            name: "Dr Taha Ahmad",
          },
        },
        {
          id: 3,
          title: "Prescription",
          date: "20 Oct 2023",
          time: "11:00",
          day: "Monday",
          isNew: false,
          doctor: {
            id: 1,
            name: "Dr Taha Ahmad",
          },
        },
      ],
      items: [],
      columns: [
        {
          text: "Name",
          value: "full_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Age",
          value: "age",
        },
        {
          text: "Blood Group",
          value: "blood_group",
        },
        {
          text: "Number",
          value: "phone_no",
        },
        {
          text: "Relation",
          value: "relation",
        },
      ],
      specializations: "",
      user: null,
      module: "familyMembers",
      loading: false,
    };
  },
  created() {
    this.userId = this.$route.params.id;
    // this.fetchFamilyMembers(this.userId);
    this.fetch(this.userId);
  },
  methods: {
    fetch(id) {
      this.loading = false;
      this.$store
        .dispatch("doctors/getSingle", { id })
        .then((response) => {
          this.user = response.data;
          if (this.user.specializations) {
            this.getSpecializations(this.user.specializations);
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },

    getSpecializations(specializations) {
      specializations = specializations.map((s) => s.title);
      this.specializations = specializations.join(", ");
    },

    fetchFamilyMembers(parent_id) {
      this.loading = false;
      this.$store
        .dispatch("familyMembers/getAll", { parent_id })
        .then((response) => {
          this.items = response.data;
          console.log("items", this.items);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
  <style scoped>
.card {
  background-color: #ffffff !important;
}
.tab-card {
  border-top-left-radius: 0px;
}
.nav-tabs {
  border-bottom: none !important;
  border-color: none;
}
.nav-item {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1); /* Drop shadow effect */
  background-color: #f8f9fa;
  margin-right: 10px !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.nav-tabs .nav-link.active {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  background-color: #fff !important;
}
.p-label {
  font-weight: 500 !important;
  color: #333333;
  margin: 0;
}
.p {
  color: #333333;
  margin: 0;
}
.box-bg {
  background: #f0faff;
}
.Active {
  background-color: rgba(0, 0, 128, 1);
  color: white !important;
}
</style>  