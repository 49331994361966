// views list for Interests here
import ListInterest from "@/views/interests/index";
import AddInterest from "@/views/interests/add";
import EditInterest from "@/views/interests/edit";

const routes = [
  {
    path: '/interests/',
    name: 'Interest',
    component: ListInterest,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_chw'
    },
    props: true
  },
  {
    path: '/interests/add',
    name: 'Add Interest',
    component: AddInterest,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_chw'
    }
  },
  {
    path: '/interests/edit/:id',
    name: 'Edit Interest',
    component: EditInterest,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_chw'
    },
    props: true
  },
];

export default routes;
