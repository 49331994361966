<template>
  <div class="breadcrumbs">
    <router-link
      v-for="(crumb, index) in breadcrumbs"
      :key="index"
      :to="crumb.to"
      :class="{ active: breadcrumbs.length - 1 === index }"
      class="breadcrumb-link"
    >
      <!-- {{ crumb.label.length > 15 ? crumb.label.substring(0, 15) + "..." : crumb.label }} -->
      {{ crumb.label }}
      <span v-if="index < breadcrumbs.length - 1" class="breadcrumb-separator">
        /
      </span>
    </router-link>
  </div>
</template>


<script>
export default {
  computed: {
    breadcrumbs() {
      // Get the current route
      const route = this.$route;
      // Split the route path into segments
      const segments = route.path
        .split("/")
        .filter((segment) => segment.trim() !== "");
      // Initialize breadcrumbs array
      const breadcrumbs = [];
      // Add home breadcrumb
      breadcrumbs.push({ label: "Home", to: "/" });
      // Initialize a variable to store the accumulated path
      let accumulatedPath = "/";
      // Loop through each segment to generate breadcrumbs
      segments.forEach((segment, index) => {
        if (index < 2) {
          // Convert dashes to spaces and capitalize the first letter of each word
          const formattedSegment = segment
            .replace(/-/g, " ")
            .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());
          // Build the path for the current breadcrumb
          accumulatedPath += segment + "/";
          // Add breadcrumb to the array
          breadcrumbs.push({
            label:
              index === 0
                ? formattedSegment
                : formattedSegment.charAt(0).toUpperCase() +
                  formattedSegment.slice(1),
            to: accumulatedPath,
          });
        }
      });
      return breadcrumbs;
    },
  },
};
</script>

<style scoped>
.breadcrumbs {
  font-size: 14px;
  margin-bottom: 20px;
}

.breadcrumb-link {
  text-decoration: none;
  color: #333333; /* Link color */
}

.breadcrumb-link.active {
  color: #000080; /* Active link color */
}

.breadcrumb-separator {
  margin: 0 5px;
  color: #333333; /* Separator color */
}
</style>

