<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/interests" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Interest</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              ref="addInterest"
              id="add-interest"
              class="needs-validation"
              novalidate
            >
              <div class="mb-3">
                <!-- <label>Title <span class="text-danger">*</span></label> -->
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Title *"
                  v-model="title"
                  required
                />
                <span class="text-danger d-block" v-if="errors.title">
                  {{ errors.title[0] }}
                </span>
                <div class="invalid-feedback text-danger">
                  Please enter interest.
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addInterest.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";

export default {
  components: {
    Bread_crumbs,
  },
  mixins:[helpers],
  data() {
    return {
      title: "",
      loading: false,
      errors: [],
    };
  },
  mounted() {},
  methods: {
    handlePermissions(permissions) {
      this.permissions = permissions;
    },
    submit() {
      var form = document.getElementById("add-interest");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw({ title: this.title });
        this.$store
          .dispatch("interests/store", { data })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.$refs.addInterest.reset();
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.errors = e.error;
            this.loading = false;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },
};
</script>
  