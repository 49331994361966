<template>
  <div>
    <section class="gradient-custom-5">
      <div class="container py-5">
        <div class="main-timeline-5">
          <div
            class="timeline-5 right-5 mb-5"
            v-for="(timeline, index) in timelines"
            :key="index"
            ref="timelineItem"
          >
            <TimelineItem :item="timeline" :timelineType="timelineType" :user="user" />
          </div>

          <!-- Other timeline items -->
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
import TimelineItem from "./TimelineItem.vue";
export default {
  name: "Timeline",
  components: {
    TimelineItem,
  },
  props: {
    bgColor: {
      type: String,
    },
    color: String,
    timelines: Array,
    timelineType: String,
    user: Object
  },
  data() {
    return {
      circleColor: "green",
    };
  },
  mounted() {
    this.$nextTick(()=>{
      this.timelines.forEach((timeline, index) => {
        this.changeColor(this.$refs.timelineItem[index], this.getColor(index));
      });
    })
  },
  methods: {
    changeColor(element, newColor) {
      if (element) {
        element.style.setProperty("--circle-color", newColor);
      }
    },
    getColor(index) {
      const colors = ["red", "green", "blue", "yellow", "purple", "orange"];
      return colors[index % colors.length];
    },
  },
};
</script>
  
  <style scoped>
/* The actual timeline (the vertical ruler) */
.main-timeline-5 {
  position: relative;
  max-width: 95%;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-5::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: #c3c3c3;
  top: 10px;
  bottom: 0;
  left: auto;
  margin-left: -6px;
}

/* Container around content */
.timeline-5 {
  position: relative;
  background-color: inherit;
  width: 100%;
}

/* The circles on the timeline */
.timeline-5::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  right: 1px;
  background-color: var(--circle-color, red);
  top: 5px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the right */
.right-5 {
  padding: 0px 0px 0px 15px;
  left: auto;
}

/* Fix the circle for containers on the right side */
.right-5::after {
  left: -10px;
}

@media (max-width: 991px) {
  .main-timeline-5 {
    max-width: 100%;
  }
}
.gradient-custom-5 {
  /* fallback for old browsers */
  background: #fff;
}
</style>
  