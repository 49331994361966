<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/categories" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Category</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              ref="addCategory"
              id="add-category"
              class="needs-validation"
              novalidate
            >
              <div class="mb-3">
                <!-- <label>Title <span class="text-danger">*</span></label> -->
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Title *"
                  v-model="title"
                  required
                />
                <span class="text-danger d-block" v-if="errors.title">
                  {{ errors.title[0] }}
                </span>
                <div class="invalid-feedback text-danger">
                  Please provide a title.
                </div>
              </div>
               <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <label for="file-upload" class="custom-file-upload form-control">Upload File *</label>
                  <span class="text-danger d-block" v-if="errors.file">
                    {{ errors.file }}
                  </span>
                  <input
                    type="file"
                    ref="file"
                    id="file-upload"
                    accept="image/*,application/pdf"
                    placeholder="Select File *"
                    class="form-control form-control-sm"
                    @change="selectFile()"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please select a file.
                  </div>
                  <img v-if="filePreview" :src="filePreview" alt="" class="mt-3" width="50%" height="200">
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addCategory.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import helpers from "@/helpers/helpers";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
  },
  mixins:[helpers],
  data() {
    return {
      title: "",
      file: null,
      filePreview:null,
      loading: false,
      errors: [],
    };
  },
  mounted() {},
  methods: {
    handlePermissions(permissions) {
      this.permissions = permissions;
    },
    submit() {
      var form = document.getElementById("add-category");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw({ title: this.title, file: this.file });
        this.$store
          .dispatch("categories/store", { data })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              if (this.$refs.addCategory) {
                this.$nextTick(() => {
                  this.$refs.addCategory.reset();
                  this.$refs.addCategory.classList.remove("was-validated");
                });
                this.filePreview = null;
              }
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.errors = e.error;
            this.loading = false;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
        // console.log(reader.result);
          this.file = reader.result;
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
};
</script>
  