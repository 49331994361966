<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="c-top-elipse"></div>
    <div class="page-wrapper">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <!-- <img class="img-fluid for-light" src="../../assets/images/logo/logo.png" alt="looginpage" />
                                    <img class="img-fluid for-dark" src="../../assets/images/logo/logo_dark.png" alt="looginpage" /> -->
                  </a>
                </div>
                <div class="login-main">
                  <form class="theme-form" @submit.prevent="submit">
                    <h4 class="text-center">Forgot Password</h4>
                    <div class="d-flex justify-content-center">
                      <p class="w-100 text-center px-5">Enter your email for the verification process,</p>
                    </div>
                    <span class="text-primary">{{ msg }}</span>
                    <div class="form-group">
                      <!-- <label class="col-form-label">Your Email</label> -->
                      <div class="form-input position-relative">
                        <input
                          type="email"
                          v-model="email"
                          class="c-form-control"
                          required
                          placeholder="email@test.com"
                          autofocus
                        />
                        <span v-if="errors.email" class="text-danger">{{
                          errors.email[0]
                        }}</span>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <button
                        type="submit"
                        class="c-btn btn-block w-100"
                      >
                        Verify
                      </button>
                    </div>
                    <!-- <p class="mt-4 mb-0">
                      Already have an account?
                      <router-link to="/auth/login"> Login </router-link>
                    </p> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="c-bottom-elipse"></div>
</template>

<script>
import router from "@/router";
import { toRaw } from "vue";
import '../assets/css/auth.css'

export default {
  data() {
    return {
      active: true,
      email: null,
      loading: false,
      errors: [],
      msg: null,
    };
  },
  created() {
    if (!!localStorage.getItem("token")) {
      router.push({ path: "/" });
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.errors = [];
      const data = { email: toRaw(this.email) };
      this.$store
        .dispatch("auth/verifyEmail", { data })
        .then((response) => {
          this.loading = false;
          this.msg = response.data;
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
  },
};
</script>
