<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <bread_crumbs />
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Profile</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="editUser">
              <div class="form-group row">
                <div class="col-md-6">
                  <!-- <label>First Name <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.f_name">
                    First Name is required
                  </span>
                  <input
                    type="text"
                    class="form-control form-control-sm mb-3"
                    placeholder="First Name *"
                    required
                    v-model="user.f_name"
                  />
                </div>
                <div class="col-md-6">
                  <!-- <label>Last Name</label> -->
                  <input
                    type="text"
                    placeholder="Last Name"
                    class="form-control form-control-sm mb-3"
                    v-model="user.l_name"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <!-- <label>Phone</label> -->
                  <input
                    type="text"
                    placeholder="Phone"
                    class="form-control form-control-sm mb-3"
                    v-model="user.phone"
                    @keypress="isNumber($event)"
                  />
                </div>
                <div class="col-md-6">
                  <!-- <label>Email <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.email">
                    Email is required
                  </span>
                  <input
                    type="email"
                    class="form-control form-control-sm mb-3"
                    placeholder="Email *"
                    required
                    v-model="user.email"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <label for="file-upload" class="custom-file-upload form-control">File Upload</label>
                  <input
                    type="file"
                    id="file-upload"
                    ref="file"
                    accept="image/*"
                    placeholder="file upload"
                    class="form-control form-control-sm mb-3"
                    @change="selectFile()"
                  />
                  <div class="invalid-feedback text-danger">
                    Please select a file.
                  </div>
                  <img v-if="filePreview" :src="filePreview" alt="" class="mt-3" width="25%" height="200">
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";
import isNumber from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  mixins: [isNumber],
  data() {
    return {
      user: {
        f_Name: null,
        l_name: null,
        phone: null,
        email: null,
        file: null,
      },
      roles: [],
      errors: [],
      userId: null,
      filePreview:null,
      loading: false,
    };
  },
  mounted() {
    this.userId = localStorage.getItem("uid");
    this.fetch(this.userId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("admins/getSingle", { id })
        .then((response) => {
          this.user = response.data;
          this.filePreview = this.user.file.path;
          localStorage.setItem("profile_image", this.user.file.path);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      const id = this.userId;
      this.$store
        .dispatch("admins/update", { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
          localStorage.setItem("profile_image", this.filePreview);
        })
        .catch((e) => {
          this.errors = e.error;
          this.loading = false;
        });
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
        // console.log(reader.result);
          this.user.file = reader.result;
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
};
</script>
