export default {
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatErrors(errors) {
      if (!this.isObject(errors)) {
        return errors;
      }
      const formatted = {};
      for (const [field, messages] of Object.entries(errors)) {
        // Join messages into a single string
        formatted[field] = messages.join(', ');
      }
      return formatted;
    },
    isObject(value) {
      return value && typeof value === 'object' && !Array.isArray(value);
    },
  },
};
