<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
      </div>
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <div class="row py-3">
              <div class="col-2 d-flex justify-content-center align-items-center">
                <img class="rounded-circle img-fluid w-100" :src="user.file.path" alt="" v-if="user.file" />
              </div>
              <div class="col-7">
                <div class="row">
                  <div class="col-3">
                    <p class="p-label">Name</p>
                    <p class="text-secondary" v-if="user">
                      {{ user.full_name }}
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">Age</p>
                    <p class="text-secondary" v-if="user">
                      {{ user.age }} Year
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">Blood Group</p>
                    <p class="text-secondary" v-if="user">
                      {{ user.blood_group }}
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">CNIC</p>
                    <p class="text-secondary" v-if="user">
                      {{ user.cnic }}
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-3">
                    <p class="p-label">Email</p>
                    <p class="text-primary" v-if="user">{{ user.email }}</p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">Number</p>
                    <p class="text-secondary" v-if="user">
                      +{{ user.phone_no }}
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">
                      Weight <span class="text-secondary">(Kg)</span>
                    </p>
                    <p class="text-secondary" v-if="user">
                      {{ user.weight }}Kg
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="p-label">
                      Height <span class="text-secondary">(Feet)</span>
                    </p>
                    <p class="text-secondary" v-if="user">
                      {{ user.height }} Feet
                    </p>
                  </div>
                </div>
                <div class="row"></div>
              </div>
              <div class="col-3">
                <div class="rounded w-100 h-100 box-bg p-2">
                  <p class="p-label">Diseases</p>
                  <ol>
                    <li v-for="(history, index) in user.medical_histories" :key="index" class="text-secondary">
                      {{ history.disease_name }}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" aria-current="page" href="#tab1"
              :class="{ active: activeTab === 'tab1' }" @click="setActiveTab('tab1')">Medical History</a>
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab2" :class="{ active: activeTab === 'tab2' }"
              @click="setActiveTab('tab2')">Virtual Health System</a>
          </li>
          <li class="nav-item">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab3" :class="{ active: activeTab === 'tab3' }"
            @click="setActiveTab('tab3')">Prescriptions</a>
          </li>
          <li class="nav-item" v-if="user.as == 'User'">
            <a class="nav-link py-3" data-bs-toggle="tab" href="#tab4" :class="{ active: activeTab === 'tab4' }"
            @click="setActiveTab('tab4')">Family Profile</a>
          </li>
        </ul>
        <div class="card tab-card border-0">
          <div class="body p-3">
            <div class="tab-content">
              <div class="tab-pane fade" :class="{ show: activeTab === 'tab1', active: activeTab === 'tab1' }" id="tab1">
                <router-link :to="`/medical-histories/add/${userId}`" class="c-button cbg-primary my-3 float-end">Add
                  Medical History</router-link>
                <Timeline v-if="user.medical_histories.length > 0" :timelines="user.medical_histories" :user="user"
                  timelineType="medical-history" />
              </div>
              <div class="tab-pane fade" :class="{ show: activeTab === 'tab2', active: activeTab === 'tab2' }" id="tab2">
                <Timeline v-if="user.appointments.length > 0" :timelines="user.appointments" :user="user"
                  timelineType="appointments" />
              </div>
              <div class="tab-pane fade" :class="{ show: activeTab === 'tab3', active: activeTab === 'tab3' }" id="tab3">
                <!-- <router-link
                  :to="`/prescriptions/add/${userId}`"
                  class="c-button cbg-primary my-3 float-end"
                  >Add Prescription</router-link
                > -->
                <Timeline v-if="user.patient_prescriptions.length > 0" :timelines="user.patient_prescriptions"
                  :user="user" timelineType="prescriptions" />
              </div>
              <div v-if="user.as == 'User'" class="tab-pane fade" :class="{ show: activeTab === 'tab4', active: activeTab === 'tab4' }" id="tab4">
                <router-link :to="`/family-members/add/${userId}`" class="c-button cbg-primary my-3 float-end">Add
                  Member</router-link>
                <div class="col-md-12">
                  <data-table :columns="columns" :module="module" :items="items" :isEdit="false" :isDelete="false"
                    :isSearch="false" :viewDetail="true" @viewItem="fetch" @deleted="fetch" @nextPage="nextPage"
                    @search="fetch" @clear="fetch"></data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import Timeline from "@/components/app-user/Timeline.vue";
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
    Bread_crumbs,
    Timeline,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Name",
          value: "full_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Age",
          value: "age",
        },
        {
          text: "Blood Group",
          value: "blood_group",
        },
        {
          text: "Number",
          value: "phone_no",
        },
        {
          text: "Relation",
          value: "relation",
        },
      ],
      user: {
        medical_histories: [],
        appointments: [],
        patient_prescriptions: [],
      },
      module: "familyMembers",
      loading: false,
      activeTab: 'tab1',
    };
  },
  created() {
    this.userId = this.$route.params.id;
    this.fetch(this.userId);
    this.fetchFamilyMembers(this.userId);

    const savedTab = localStorage.getItem('activeTab');
    if (savedTab && this.user.as == 'User') {
      this.activeTab = savedTab;
    }
  },
  methods: {
    fetch(id) {
      this.loading = false;
      this.$store
        .dispatch("appUsers/getSingle", { id })
        .then((response) => {
          this.user = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchFamilyMembers(parent_id) {
      this.loading = false;
      this.$store
        .dispatch("familyMembers/getAll", { parent_id })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      localStorage.setItem('activeTab', tab);
    }
  },
};
</script>
<style scoped>
.card {
  background-color: #ffffff !important;
}

.tab-card {
  border-top-left-radius: 0px;
}

.nav-tabs {
  border-bottom: none !important;
  border-color: none;
}

.nav-item {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  /* Drop shadow effect */
  background-color: #f8f9fa;
  margin-right: 10px !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.nav-tabs .nav-link.active {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  background-color: #fff !important;
}

.p-label {
  font-weight: 500 !important;
  color: #333333;
  margin: 0;
}

.p {
  color: #333333;
  margin: 0;
}

.box-bg {
  background: #f0faff;
}

.disease-name {
  line-height: 5px;
}
</style>