<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/roles" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Edit District</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              ref="addRole"
              id="add-tehsil"
              class="needs-validation"
              novalidate
            >
              <div class="mb-3">
                <label>Title <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="title"
                  required
                />
                <span class="text-danger d-block" v-if="errors.title">
                  {{ errors.title[0] }}
                </span>
                <div class="invalid-feedback text-danger">
                  Please select a district.
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
  },
  data() {
    return {
      title: "",
      districtId: null,
      loading: false,
      errors:[],
    };
  },
  mounted() {
    this.districtId = this.$route.params.id;
    this.fetch(this.districtId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("districts/getSingle", { id })
        .then((response) => {
          this.title = response.data.title;
          console.log(this.title);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    submit() {
      var form = document.getElementById("add-tehsil");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw({ title: this.title });
        const id = this.districtId;
        this.$store
          .dispatch("districts/update", { data, id })
          .then((response) => {
            this.loading = false;
            if (response.success === true) {
              this.$toast.show("Successfuly updated", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              // this.$refs.addRole.reset();
            } else {
              this.$toast.show("Failed to udpate", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 5000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },
};
</script>
  