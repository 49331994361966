// views list for UserMessagess here
import ListUserMessages from "@/views/user-messages/index";
import AddUserMessages from "@/views/user-messages/add";
import EditUserMessages from "@/views/user-messages/edit";
import ShowUserMessages from "@/views/user-messages/show";

const routes = [
  {
    path: '/user-messages/',
    name: 'User Messages',
    component: ListUserMessages,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_chw'
    },
    props: true
  },
  {
    path: '/user-messages/add',
    name: 'Add User Messages',
    component: AddUserMessages,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_chw'
    }
  },
  {
    path: '/user-messages/show/:id',
    name: 'Show User Messages',
    component: ShowUserMessages,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_chw'
    },
    props: true
  },
  {
    path: '/user-messages/edit/:id',
    name: 'Edit User Messages',
    component: EditUserMessages,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_chw'
    },
    props: true
  },
];

export default routes;
