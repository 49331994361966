<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="row">
        <div class="col-md-12">
          <form
            @submit.prevent="submit"
            id="testimonial"
            ref="addTestimonial"
            class="needs-validation"
            novalidate
          >
            <div class="form-group row">
              <div class="col-md-12 mb-3">
                <span class="text-danger d-block" v-if="errors.name">
                  {{ errors.name }}
                </span>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Name *"
                  required
                  v-model="testimonial.name"
                />
                <div class="invalid-feedback text-danger">
                  Please provide a Name.
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <span class="text-danger d-block" v-if="errors.text">
                  {{ errors.text }}
                </span>
                <textarea
                  name=""
                  id=""
                  rows="7"
                  class="text-area"
                  placeholder="Type text.... *"
                  v-model="testimonial.text"
                  required
                ></textarea>
                <div class="invalid-feedback text-danger">
                  Please provide a text.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-3">
                <button type="submit" class="c-button cbg-primary">
                  Submit
                </button>
                <button
                  type="button"
                  @click="this.$refs.addTestimonial.reset()"
                  class="ms-2 c-button cbg-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <data-table
          :columns="columns"
          :module="module"
          :items="items"
          :isEdit="false"
          :isDelete="true"
          :viewDetail="false"
          :unBlock="false"
          :isBlock="false"
          @viewItem="fetch"
          @deleted="fetch"
          @nextPage="nextPage"
          @search="fetch"
          @clear="fetch"
        ></data-table>
      </div>
    </div>
  </div>
</template>
  <script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";
export default {
  components: {
    Bread_crumbs,
    DataTable
  },
  props: {
  },
  mixins: [isNumber],
  data() {
    return {
      testimonial: {
        name: null,
        text: null,
      },
      imagePreview: "/promotional/how-it-works/image.png",
      videoPreview: null,
      items: [],
      columns: [
        // {
        //   text: "ID",
        //   value: "id",
        // },
        {
          text: "Person Name",
          value: "name",
        },
        {
          text: "Text",
          value: "text",
        },
      ],
      module: "promotional",
      errors: [],
      loading: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("promotional/getAllTestimonial", { search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    submit() {
      var form = document.getElementById("testimonial");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.testimonial);
        this.$store
          .dispatch("promotional/storeTestimonial", { data })
          .then((res) => {
            this.loading = false;
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.$refs.addTestimonial.reset();
            this.errors = [];
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },
};
</script>
  