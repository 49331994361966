<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/tehsils" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Tehsil</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              ref="addRole"
              id="add-tehsil"
              class="needs-validation"
              novalidate
            >
              <div class="form-group mb-3">
                <label>Select District <span class="text-danger">*</span></label>
              <select
                class="form-control form-control-sm"
                v-model="district_id"
                required
              >
                <option value="">Select...</option>
                <option
                  v-for="district in districts"
                  :value="district.id"
                  v-bind:key="district.id"
                >
                  {{ district.title }}
                </option>
              </select>
              <div class="invalid-feedback text-danger">
                Please select a district.
              </div>
              </div>
              <div class="form-group mb-3">
                <label>Title <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm"
                required
                v-model="title"
              />
              <div class="invalid-feedback text-danger">
                Please provide a title.
              </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
  },
  data() {
    return {
      district_id: "",
      title: "",
      loading: false,
      districts: [],
    };
  },
  mounted() {
    this.fetchDistricts();
  },
  methods: {
    submit() {
      var form = document.getElementById("add-tehsil");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw({ title: this.title, district_id: this.district_id });
        this.$store
          .dispatch("tehsils/store", { data })
          .then((response) => {
            this.loading = false;
            if (response.success === true) {
              this.$toast.show("Successfuly added", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.title = "";
            } else {
              this.$toast.show("Failed to save", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 5000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show(e.error, {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },

    fetchDistricts(paginate = "false") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("districts/getAll", { paginate })
        .then((response) => {
          this.districts = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
  