// views list for qualifications here
import ListQualification from "@/views/qualifications/index";
import AddQualification from "@/views/qualifications/add";
import EditQualification from "@/views/qualifications/edit";

const routes = [
  {
    path: '/qualifications/',
    name: 'Qualifications',
    component: ListQualification,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
  {
    path: '/qualifications/add',
    name: 'Add Qualification',
    component: AddQualification,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    }
  },
  {
    path: '/qualifications/edit/:id',
    name: 'Edit Qualification',
    component: EditQualification,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
];

export default routes;
