<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs></Bread_crumbs>
        <router-link to="/admins" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Edit Sub Admin</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              id="sub-admin"
              class="needs-validation"
              novalidate
              ref="editUser"
            >
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.f_name">
                    First Name is required
                  </span>
                  <input
                    type="text"
                    placeholder="First Name *"
                    class="form-control form-control-sm"
                    required
                    v-model="admin.f_name"
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a name.
                  </div>
                </div>
                <div class="col-md-6">
                  <input
                    type="text"
                    placeholder="Last Name"
                    class="form-control form-control-sm"
                    v-model="admin.l_name"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.email">
                    Email is required
                  </span>
                  <input
                    type="email"
                    placeholder="Email *"
                    class="form-control form-control-sm"
                    pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                    required
                    v-model="admin.email"
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a valid email.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <input
                    maxlength="11"
                    pattern="[0-9]{11}"
                    type="text"
                    placeholder="Phone *"
                    class="form-control form-control-sm"
                    v-model="admin.phone"
                    @keypress="isNumber($event)"
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a number atleast 11 digits.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.dob">
                    {{ errors.dob }}
                  </span>
                  <input
                    type="date"
                    placeholder="Date of Birth"
                    :max="new Date().toISOString().split('T')[0]"
                    class="form-control form-control-sm dateclass placeholderclass"
                    v-model="admin.dob"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.cnic">
                    {{ errors.cnic }}
                  </span>
                  <input
                    maxlength="13"
                    pattern="[0-9]{13}"
                    type="text"
                    placeholder="CNIC *"
                    class="form-control form-control-sm"
                    v-model="admin.cnic"
                    @keypress="isNumber($event)"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a cnic atleast 13 digits.
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-6">
                  <select
                    class="c-form-select"
                    v-model="admin.role"
                    required
                  >
                    <option value="">Select Role... *</option>
                    <option
                      v-for="role in roles"
                      :value="role.name"
                      :key="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mt-2">
                  <p><b>Gender *</b></p>
                  <span class="text-danger d-block" v-if="errors.gender">
                    {{ errors.gender }}
                  </span>
                  <div class="d-flex">
                    <div>
                      <input
                        type="radio"
                        class="c-checkbox mb-3"
                        id="male"
                        v-model="admin.gender"
                        value="male"
                      />
                      <label for="male" class="ms-2">Male</label>
                    </div>
                    <div class="mx-2">
                      <input
                        type="radio"
                        class="c-checkbox mb-3"
                        id="female"
                        v-model="admin.gender"
                        value="female"
                      />
                      <label for="female" class="ms-2">Female</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <!-- <button type="button" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components:{
    Bread_crumbs,

  },
  mixins: [helpers],
  data() {
    return {
      admin: {
        f_Name: null,
        l_name: null,
        phone: null,
        email: null,
        cnic: null,
        dob: null,
        gender:null,
        role: "",
        prevRole: null,
        send_credentials:false,
        updatedBy: 1,
        created_by: 1,
      },
      roles: [],
      errors: [],
      userId: null,
      loading: false,
    };
  },
  mounted() {
    this.userId = this.$route.params.id;
    this.fetch(this.userId);
    this.fetchRoles();
  },
  methods: {
    fetch(id) {
      this.loading = false;
      this.$store
        .dispatch("admins/getSingle", { id })
        .then((response) => {
          console.log(response.data);
          this.admin = response.data;
          this.admin.role = this.admin.roles[0].name;
          this.admin.prevRole = this.admin.roles[0].name;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      var form = document.getElementById("sub-admin");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        this.errors = [];
        const data = toRaw(this.admin);
        const id = this.userId;
        this.$store
          .dispatch("admins/update", { data, id })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly updated.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show("Something went wrong!", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    fetchRoles() {
      this.loading = true;
      this.$store
        .dispatch("roles/getAll", {paginate: 'false'})
        .then((response) => {
          this.loading = false;
          this.roles = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
