<template>
  <loading
    v-if="loading"
    :active="loading"
    :is-full-page="true"
    loader="bars"
  ></loading>
  <li class="onhover-dropdown">
    <div class="notification-box">
      <div style="background: #fff; padding: 5px; border-radius: 5px">
        <svg>
          <use
            href="@/assets/svg/icon-sprite.svg#notification"
            @click="notification_open()"
          ></use>
        </svg>
      </div>
      <span class="badge rounded-pill text-white" style="background-color: #ec2227">{{
        notificationCount
      }}</span>
    </div>
    <div
      class="onhover-show-div notification-dropdown"
      :class="{ active: notification }"
    >
      <h6 class="mb-0 dropdown-title text-end" @click="readNotifications">
        Mark all as read
      </h6>
      <ul>
        <li class="b-l-primary border-4" v-for="notification in notifications" :key="notification">
          <p v-if="notification.body.title">
            {{ notification.body.title }}
            <span class="font-danger" v-if="notification.isRead == 0">New</span>
          </p>
          <p v-else>
            {{ JSON.parse(notification.body).title }}
            <span class="font-danger" v-if="notification.is_read == 0"
              >New</span
            >
          </p>
          <p v-if="notification.body.body">{{ notification.body.body }}</p>
          <p v-else>{{ JSON.parse(notification.body).body }}</p>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "Notifications",
  data() {
    return {
      notification: false,
      uid: localStorage.getItem("uid"),
      notifications: [],
      notificationCount: 0,
      loading: false,
      channelName: null,
      pusherChannel: null,
    };
  },
  inject: ["pusherService"],
  mounted() {
    this.channelName = "admin." + localStorage.getItem("pusher_id");
    this.pusher();
    this.fetchNotifications();
  },
  onUnmounted() {
    this.pusherService.unsubscribe(this.channelName);
  },
  methods: {
    notification_open() {
      this.notification = !this.notification;
    },
    pusher() {
      this.pusherChannel = this.pusherService.subscribe(this.channelName);
      this.pusherChannel.bind(this.channelName, (data) => {
        this.notifications.unshift(data);
        this.notificationCount += 1;
      });
    },
    fetchNotifications() {
      this.loading = true;
      const id = localStorage.getItem("pusher_id");
      this.$store
        .dispatch("notifications/getAll", { id })
        .then((response) => {
          this.notifications = response.data.notifications;
          this.notificationCount = response.data.count;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    readNotifications() {
      this.loading = true;
      const data = this.notifications;
      this.$store
        .dispatch("notifications/update", { data })
        .then(() => {
          this.fetchNotifications();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>