<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs></Bread_crumbs>
        <router-link to="/campaigns" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Campaign</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              id="campaign"
              ref="addCampaign"
              class="needs-validation"
              novalidate
            >
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <!-- <label>First Name <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.title">
                    {{ errors.title }}
                  </span>

                  <input
                    type="text"
                    placeholder="Campagin Title *"
                    class="form-control form-control-sm"
                    v-model="campaign.title"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a title.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.district_id">
                    {{ errors.district_id }}
                  </span>
                  <select
                    class="c-form-select"
                    v-model="campaign.district_id"
                    v-on:change="fetchTehsils()"
                    required
                  >
                    <option value="">Select District ... *</option>
                    <option
                      v-for="district in districts"
                      :value="district.id"
                      v-bind:key="district.id"
                    >
                      {{ district.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a district.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <select
                    class="c-form-select"
                    v-model="campaign.tehsil_id"
                    required
                  >
                    <option value="">Select Tehsile... *</option>
                    <option
                      v-for="tehsil in tehsils"
                      :value="tehsil.id"
                      v-bind:key="tehsil.id"
                    >
                      {{ tehsil.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a tehsil.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.start_date">
                    {{ errors.start_date }}
                  </span>
                  <input
                    type="date"
                    :min="minDate"
                    placeholder="Start Date *"
                    class="form-control form-control-sm dateclass placeholderclass"
                    onClick="$(this).removeClass('placeholderclass')"
                    v-model="campaign.start_date"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please select start date.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.end_date">
                    {{ errors.end_date }}
                  </span>
                  <input
                    type="date"
                    :min="minDate"
                    placeholder="End Date *"
                    class="form-control form-control-sm dateclass placeholderclass"
                    onClick="$(this).removeClass('placeholderclass')"
                    v-model="campaign.end_date"
                    @change="dateValidation"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please select end date.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <label for="file-upload" class="custom-file-upload form-control">Upload File *</label>
                  <span class="text-danger d-block" v-if="errors.file">
                    {{ errors.file }}
                  </span>
                  <input
                    type="file"
                    ref="file"
                    id="file-upload"
                    accept="image/*,application/pdf"
                    placeholder="Select File *"
                    class="form-control form-control-sm"
                    @change="selectFile()"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please select a file.
                  </div>
                  <img v-if="filePreview" :src="filePreview" alt="" class="mt-3" width="50%" height="200">
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addCampaign.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  mixins: [helpers],
  data() {
    return {
      campaign: {
        title: null,
        start_date: null,
        end_date: null,
        district_id: "",
        tehsil_id: "",
        file: null,
        created_by: localStorage.getItem("uid"),
      },
      filePreview: null,
      districts: [],
      minDate:null,
      tehsils:[],
      errors: [],
      loading: false,
    };
  },
  mounted() {
    this.minDate = new Date().toISOString().split('T')[0];
    this.fetchDistricts();
  },
  methods: {
    submit() {
      var form = document.getElementById("campaign");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.campaign);
        console.log(data);
        this.$store
          .dispatch("campaigns/store", { data })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.$refs.addCampaign.reset();
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    fetchDistricts() {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("districts/getAll", {})
        .then((response) => {
          this.districts = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
        // console.log(reader.result);
          this.campaign.file = reader.result;
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    fetchTehsils() {
      const district_id = this.campaign.district_id
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("tehsils/getAll", { district_id })
        .then((response) => {
          this.tehsils = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    dateValidation(){
      if(this.campaign.start_date ==  null){
        this.$toast.show("Select a date after the start date!", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 10000,
        });
      }
    },
  },
};
</script>
