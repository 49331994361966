<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs/>
        <router-link to="/roles" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Role</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <!-- <label>Name <span class="text-danger">*</span></label> -->
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                placeholder="Name *"
                required
                v-model="role.name"
              />
              <div class="row">
                <div class="col">
                  <label>Modules Permissions</label>
                  <PermissionList @permissions-changed="handlePermissions" :permissionsList="[]" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button
                    type="submit"
                    class="c-button cbg-primary"
                  >
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addRole.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import PermissionList from "@/components/permission/PermissionList.vue";
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";

export default {
  components:{
    Bread_crumbs,
    PermissionList
  },
  mixins:[helpers],
  data() {
    return {
      role: {
        name: "",
        permissions: [],
      },
      loading: false,
      modules: [],
      permissions:[],
    };
  },
  mounted() {
  },
  methods: {
    handlePermissions(permissions){
      this.permissions = permissions
    },
    submit() {
      this.loading = true;
      const data = toRaw({role: this.role, permissions:this.permissions});
      this.$store
        .dispatch("roles/store", { data })
        .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.$refs.addRole.reset();
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>
