<template>
  <div class="header-wrapper row m-0">
    <div
      class="nav-right col-xxl-5 col-xl-6 col-md-5 col-4 p-0 ms-auto"
    >
      <p class="header-heading">Virtual Health System Logo</p>
      <Logo />
    </div>
    <div
      class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto"
    >
      <ul class="nav-menus">
        <li @click="$router.push({ path: `/notifications/send` })" class="cursor pe-0 py-0 header-notify-text">Send Notifications</li>
        <Notification />
        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import Profile from "./profile";
import Logo from "./logo.vue";
import Notification from "./notifications.vue";
export default {
  components: {
    Profile,
    Logo,
    Notification,
  },
  data() {
    return {};
  },
};
</script>
