<template>
  <div class="col-md-12 mt-5">
    <div class="card">
      <div class="card-body pt-3">
        <div class="row m-0 overall-card">
          <div class="col-md-4">
            <AnalyticCard
              :svgIcon="icon1"
              title="Medicine Specialist"
              lgText="1050"
              smText="+50%"
              color="primary"
              bgColor="#EDF0FF"
              stokeColor="#2D54F4"
            />
          </div>
          <div class="col-md-4">
            <AnalyticCard
              :svgIcon="icon2"
              title="Heart Specialist"
              lgText="1050"
              smText="+50%"
              color="success"
              bgColor="#EBFFF8"
              stokeColor="#0DC286"
            />
          </div>
          <div class="col-md-4">
            <AnalyticCard
              :svgIcon="icon3"
              title="Gynacologist"
              lgText="1050"
              smText="+50%"
              color="danger"
              bgColor="#FFEEEE"
              stokeColor="#FF4C4E"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticCard from "./AnalyticCard.vue";
export default {
  components: {
    AnalyticCard,
  },
  data() {
    return {
      icon1: `<svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3044 1.49338C15.4738 -0.173993 13.4016 -0.171718 11.8527 0.202422C10.3096 0.575084 8.69046 0.575084 7.14736 0.202422C5.59844 -0.171718 3.52625 -0.173993 1.69555 1.49338C-1.77309 4.65265 1.0181 10.2961 1.85818 11.2682C2.69826 12.2403 2.72531 13.5364 3.32151 16.1827C3.9177 18.8289 4.67641 21.3401 6.19395 20.9621C7.71149 20.5841 8.118 18.748 8.49736 16.2907C8.52349 16.1217 8.55066 15.9632 8.57873 15.8142C8.7716 14.7925 10.2283 14.7925 10.4212 15.8142C10.4493 15.9631 10.4765 16.1217 10.5027 16.2907C10.882 18.748 11.2885 20.5841 12.8061 20.9621C14.3236 21.3401 15.0823 18.8289 15.6785 16.1827C16.2747 13.5364 16.3018 12.2403 17.1419 11.2682C17.9819 10.2961 20.7731 4.65265 17.3044 1.49338ZM6.7098 2.72076C6.6678 3.03122 6.38169 3.24934 6.07035 3.20885C5.95885 3.19509 3.48505 2.92296 2.79002 5.61404C2.72371 5.87059 2.49192 6.04106 2.23776 6.04106C2.19063 6.04106 2.14281 6.03526 2.09511 6.02298C1.78993 5.94474 1.60619 5.63474 1.68471 5.33065C2.44627 2.38188 4.98444 1.91619 6.2202 2.08143C6.53256 2.12327 6.75168 2.40951 6.7098 2.72076Z" fill="white"/>
            </svg>
        `,
      icon2: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.221 12.427L16.5184 13.4859L15.6484 9.13507L14.9829 11.0055C15.0852 11.298 15.2612 11.8495 15.3976 12.5143L15.5259 12.157L16.0809 14.9312L17.516 13.1396H22V12.427H17.221Z" fill="white"/>
<path d="M8.31182 4.7282C8.57475 4.59958 8.84943 4.49555 9.13267 4.41646L8.86582 0.945309C8.85157 0.758979 8.65063 0.627159 8.47428 0.684875L8.11231 0.805651C7.89498 0.877974 7.74927 1.08034 7.74927 1.30906C7.74927 1.33828 7.75176 1.36749 7.75639 1.3967L8.31182 4.7282Z" fill="white"/>
<path d="M8.96318 7.79539C9.70779 7.79539 10.4385 7.53923 11.0203 7.07394C11.5793 6.62682 12.248 6.32827 12.9538 6.2107L13.3275 6.14835C12.5808 5.39982 11.5501 4.94522 10.425 4.94522C9.08859 4.94522 7.83345 5.59862 7.06747 6.6938C6.9841 6.81315 6.90607 6.93535 6.83197 7.05898C7.41697 7.5346 8.14269 7.79539 8.90476 7.79539H8.96318Z" fill="white"/>
<path d="M0.854348 5.19671L2.32112 5.92991C2.82702 6.18286 3.19861 6.62678 3.36891 7.1537C3.61046 7.10953 3.85736 7.08281 4.11102 7.08281H4.15769C4.43666 7.08281 4.71668 7.11059 4.98959 7.1651L5.87528 7.34217C6.04094 6.98269 6.2401 6.63675 6.46918 6.30685L5.73206 5.56972C5.43172 5.26938 5.24824 4.87072 5.21546 4.44675L4.9479 0.967766C4.93294 0.77431 4.73272 0.631446 4.54852 0.679186L3.80071 0.865872C3.60832 0.914681 3.47401 1.08676 3.47401 1.28556C3.47401 1.32119 3.47829 1.3561 3.48684 1.38995L4.06685 3.70963C4.17159 4.13003 3.97457 4.55862 3.58695 4.75244C3.2745 4.90884 2.90647 4.87571 2.62644 4.66551L1.69764 3.96899C1.49314 3.81651 1.17464 3.87031 1.03284 4.083L0.69367 4.5914C0.648067 4.66052 0.62384 4.74032 0.62384 4.82333C0.62384 4.98223 0.712196 5.12545 0.854348 5.19671Z" fill="white"/>
<path d="M3.23746 12.4268C4.36897 12.4268 5.25502 11.5254 5.25502 10.3743V10.1812C5.25502 9.43696 5.37473 8.70767 5.60773 8.01543L4.84958 7.86366C4.62228 7.81841 4.38928 7.79526 4.15735 7.79526H4.11068C2.23526 7.79526 0.700803 9.31582 0.626343 11.2133L0.851863 11.4388C1.48923 12.0759 2.33644 12.4268 3.23746 12.4268Z" fill="white"/>
<path d="M11.1544 4.29173L11.9906 1.78322C12.0134 1.71553 12.0248 1.64427 12.0248 1.57302C12.0248 1.34999 11.914 1.143 11.7284 1.01937L11.2706 0.71405C11.1199 0.613581 10.8944 0.710843 10.8648 0.890048L10.3065 4.24007C10.3464 4.239 10.3853 4.23258 10.4252 4.23258C10.6731 4.23258 10.9157 4.25539 11.1544 4.29173Z" fill="white"/>
<path d="M14.4328 3.49403C14.4884 3.42741 14.5187 3.34333 14.5187 3.25675C14.5187 3.11531 14.4403 2.98848 14.3138 2.92506L14.1934 2.86485C13.9493 2.74265 13.6319 2.83172 13.4872 3.06365L12.4722 4.68754C12.7006 4.79478 12.9183 4.92161 13.1256 5.06234L14.4328 3.49403Z" fill="white"/>
<path d="M8.81808 13.8519C9.40837 13.8519 9.88689 13.3734 9.88689 12.7831C9.88689 12.1928 9.40837 11.7143 8.81808 11.7143C8.22779 11.7143 7.74927 12.1928 7.74927 12.7831C7.74927 13.3734 8.22779 13.8519 8.81808 13.8519Z" fill="white"/>
<path d="M12.3905 11.1741L11.9537 13.1393H10.5632C10.3975 13.9512 9.67818 14.5644 8.81778 14.5644C7.83554 14.5644 7.03643 13.7653 7.03643 12.783C7.03643 11.8008 7.83554 11.0017 8.81778 11.0017C9.67782 11.0017 10.3975 11.6148 10.5632 12.4267H11.3822L12.3705 7.97905L13.8391 14.2199L14.9827 11.0052C14.9339 10.8652 14.9011 10.7815 14.8997 10.7779L14.8797 10.7077L14.4604 8.33176C14.3599 7.76315 14.1433 7.24193 13.8419 6.78519L13.0706 6.9138C12.4845 7.01142 11.9295 7.25938 11.4652 7.63062C10.7573 8.19602 9.86878 8.50776 8.96279 8.50776H8.904C8.02686 8.50776 7.19034 8.22239 6.49953 7.70187C6.15216 8.47748 5.96761 9.31721 5.96761 10.1812V10.3743C5.96761 11.8991 4.74275 13.1393 3.23751 13.1393C2.29161 13.1393 1.39487 12.8201 0.669861 12.2326C0.852272 13.8828 1.56731 15.4408 2.72982 16.6927C4.27889 18.3612 6.21487 19.6911 8.32862 20.5394L10.1132 21.2544C10.4235 21.3784 10.8001 21.356 11.0616 21.1429L11.2383 20.9986C13.3674 19.2561 14.9104 16.8306 15.5869 14.1646C15.583 13.5939 15.5021 13.0221 15.3978 12.5137L13.7724 17.0469L12.3905 11.1741Z" fill="white"/>
</svg>
`,
      icon3: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.6191 5.45401C15.1263 3.94342 15.4721 2.31823 16.0023 0.820309C16.0641 0.645779 16.0443 0.451151 15.9454 0.292003C15.8331 0.110542 15.6354 0 15.4221 0H5.58693C5.37364 0 5.17594 0.110542 5.06359 0.292003C4.95124 0.473465 4.94103 0.699963 5.03656 0.890448C5.71913 2.25498 5.8597 3.85515 5.43734 5.30742C5.03057 6.59081 4.43512 7.76067 3.85895 8.89144C3.68155 9.23947 3.50775 9.58242 3.34036 9.92414L6.11273 11.3104C8.82374 12.666 12.1764 12.666 14.8874 11.3104L17.6666 9.92094C17.5014 9.58464 17.3298 9.24739 17.155 8.9053C16.5988 7.8181 16.0232 6.69266 15.6191 5.45401ZM10.5 11.1157C10.1602 11.1157 9.88474 10.8403 9.88474 10.5005C9.88474 10.1606 10.1602 9.88521 10.5 9.88521C10.8398 9.88521 11.1153 10.1606 11.1153 10.5005C11.1153 10.8403 10.8398 11.1157 10.5 11.1157Z" fill="white"/>
<path d="M0.615018 4.96303H1.6318L1.45153 5.1433C1.21121 5.38363 1.21121 5.773 1.45153 6.01333C1.69185 6.25365 2.08123 6.25365 2.32155 6.01333L3.55208 4.7828C3.7924 4.54248 3.7924 4.1531 3.55208 3.91278L2.32155 2.68226C2.08123 2.44193 1.69185 2.44193 1.45153 2.68226C1.21121 2.92258 1.21121 3.31196 1.45153 3.55228L1.6318 3.73251H0.615018C0.274942 3.73251 -0.000244141 4.00769 -0.000244141 4.34777C-0.000244141 4.68785 0.274942 4.96303 0.615018 4.96303Z" fill="white"/>
<path d="M20.3851 3.73255H19.3683L19.5485 3.55228C19.7889 3.31196 19.7889 2.92258 19.5485 2.68226C19.3082 2.44193 18.9188 2.44193 18.6785 2.68226L17.448 3.91278C17.2077 4.1531 17.2077 4.54248 17.448 4.7828L18.6785 6.01333C18.9188 6.25365 19.3082 6.25365 19.5485 6.01333C19.7889 5.773 19.7889 5.38363 19.5485 5.1433L19.3683 4.96307H20.3851C20.7251 4.96307 21.0003 4.68789 21.0003 4.34781C21.0003 4.00774 20.7251 3.73255 20.3851 3.73255Z" fill="white"/>
<path d="M18.1785 11.0411L15.438 12.4112C13.9125 13.1737 12.2055 13.5769 10.5004 13.5769C8.79518 13.5769 7.08819 13.1737 5.56263 12.4112L2.83001 11.0448C2.5766 11.643 2.35617 12.248 2.20515 12.8829C2.04592 13.5517 1.94362 14.2283 1.88443 14.9073C4.80204 14.2703 7.37072 15.1143 9.70482 17.4487C9.94756 17.6451 11.0537 17.6458 11.3001 17.4444C12.945 15.7987 15.3786 14.0692 19.1254 14.913C19.0663 14.232 18.9643 13.5534 18.8046 12.8828C18.6525 12.2434 18.4318 11.6379 18.1785 11.0411Z" fill="white"/>
<path d="M12.1658 18.3188C12.0557 18.4289 11.9009 18.5143 11.7308 18.5882V20.3858C11.7308 20.7256 12.0063 21.0011 12.3461 21.0011H17.8835C18.1434 21.0011 18.3816 20.8358 18.4639 20.5892C18.9358 19.1766 19.1497 17.6658 19.1574 16.1924C15.8478 15.3377 13.724 16.7606 12.1658 18.3188Z" fill="white"/>
<path d="M1.8526 16.1773C1.85867 17.6558 2.07213 19.1718 2.54567 20.5893C2.62804 20.8359 2.86618 21.0012 3.12615 21.0012H8.65449C8.99428 21.0012 9.26975 20.7257 9.26975 20.3859V18.5883C9.09965 18.5144 8.94493 18.429 8.83476 18.3189C7.24591 16.73 5.11989 15.3401 1.8526 16.1773Z" fill="white"/>
</svg>
`,
    };
  },
};
</script>

<style scoped>
.card {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  }
</style>
