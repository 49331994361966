// views list for specializations here
import ListSpecialization from "@/views/specializations/index";
import AddSpecialization from "@/views/specializations/add";
import EditSpecialization from "@/views/specializations/edit";

const routes = [
  {
    path: '/specializations/',
    name: 'Specializations',
    component: ListSpecialization,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
  {
    path: '/specializations/add',
    name: 'Add Specialization',
    component: AddSpecialization,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    }
  },
  {
    path: '/specializations/edit/:id',
    name: 'Edit Specialization',
    component: EditSpecialization,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
];

export default routes;
