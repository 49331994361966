// views list for Pages here
import ListPage from "@/views/pages/index";
import AddPage from "@/views/pages/add";
import EditPage from "@/views/pages/edit";

const routes = [
  {
    path: '/pages/',
    name: 'Web Content',
    component: ListPage,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_chw'
    },
    props: true
  },
  {
    path: '/pages/add',
    name: 'Add Web Content',
    component: AddPage,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_chw'
    }
  },
  {
    path: '/pages/edit/:id',
    name: 'Edit Web Content',
    component: EditPage,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_chw'
    },
    props: true
  },
];

export default routes;
