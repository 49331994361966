export const overallbalance = [
  {
    iconName: "user",
    title: "Total Participants",
    amount: "21",
    // balance: "+$456",
    // balanceClass: "font-success",
  },
  {
    iconName: "user",
    title: "Completed",
    amount: "02",
    // balance: "+$456",
    // balanceClass: "font-danger",
  },
  {
    iconName: "user",
    title: "Remaining",
    amount: "19",
  },
];

export const resentSales = [
  {
    image: require("@/assets/images/dashboard/user/1.jpg"),
    name: "Jane Cooper",
    time: "10 minutes ago",
    amount: "$200.00",
  },
  {
    image: require("@/assets/images/dashboard/user/2.jpg"),
    name: "	Brooklyn Simmons",
    time: "19 minutes ago",
    amount: "$970.00",
  },
  {
    image: require("@/assets/images/dashboard/user/3.jpg"),
    name: "Leslie Alexander",
    time: "2 hours ago",
    amount: "$300.00",
  },
  {
    image: require("@/assets/images/dashboard/user/4.jpg"),
    name: "Travis Wright",
    time: "8 hours ago",
    amount: "$450.00",
  },
  {
    image: require("@/assets/images/dashboard/user/5.jpg"),
    name: "Mark Green",
    time: "1 day ago",
    amount: "$768.00",
  },
];

export const paperNotes = [
  {
    label: "SAAS",
    labelClass: "badge badge-light-primary",
  },
  {
    label: "E-Commerce",
    labelClass: "badge badge-light-success",
  },
  {
    label: "Crypto",
    labelClass: "badge badge-light-warning",
  },
  {
    label: "Project",
    labelClass: "badge badge-light-info",
  },
  {
    label: "NFT",
    labelClass: "badge badge-light-secondary",
  },
  {
    label: "+9",
    labelClass: "badge badge-light-light",
  },
];

export const customers = [
  {
    image: require("@/assets/images/dashboard/user/1.jpg"),
  },
  {
    image: require("@/assets/images/dashboard/user/6.jpg"),
  },
  {
    image: require("@/assets/images/dashboard/user/7.jpg"),
  },
  {
    image: require("@/assets/images/dashboard/user/3.jpg"),
  },
  {
    image: require("@/assets/images/dashboard/user/8.jpg"),
  },
];

export const puechaseSalesDetails1 = [
  {
    widgetClass: "widget-round secondary",
    svgIcon1: "cart",
    svgIcon2: "halfcircle",
    number: "10,000",
    title: "Purchase",
    growthClass: "font-secondary f-w-500",
    iconClass: "icon-arrow-up icon-rotate me-1",
    growthNumber: "+50%",
  },
  {
    widgetClass: "widget-round primary",
    svgIcon1: "tag",
    svgIcon2: "halfcircle",
    number: "4,200",
    title: "Sales",
    growthClass: "font-primary f-w-500",
    iconClass: "icon-arrow-up icon-rotate me-1",
    growthNumber: "+70%",
  },
];
export const puechaseSalesDetails2 = [
  {
    widgetClass: "widget-round warning",
    svgIcon1: "return-box",
    svgIcon2: "halfcircle",
    number: "7000",
    title: "Sales return",
    growthClass: "font-warning f-w-500",
    iconClass: "icon-arrow-down icon-rotate me-1",
    growthNumber: "-20%",
  },
  {
    widgetClass: "widget-round success",
    svgIcon1: "rate",
    svgIcon2: "halfcircle",
    number: "5700",
    title: "Purchase rate",
    growthClass: "font-success f-w-500",
    iconClass: "icon-arrow-up icon-rotate me-1",
    growthNumber: "+70%",
  },
];
