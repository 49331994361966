<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link
          :to="`/app-users/show/${prescription.uid}`"
          class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="col-md-8 offset-md-2">
          <div class="card mt-4">
            <div class="card-header">
              <h4 class="card-title">Add Prescription</h4>
            </div>
            <div class="card-body">
              <form
                @submit.prevent="submit"
                ref="addRole"
                id="add-prescriptions"
                class="needs-validation"
                novalidate
              >
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Disease *"
                    v-model="prescription.disease_name"
                    required
                  />
                  <span class="text-danger d-block" v-if="errors.disease_name">
                    {{ errors.disease_name[0] }}
                  </span>
                  <div class="invalid-feedback text-danger">
                    Please provide a disease.
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6 mb-3">
                    <span class="text-danger d-block" v-if="errors.from_date">
                      {{ errors.from_date }}
                    </span>
                    <input
                      type="date"
                      placeholder="From *"
                      class="form-control form-control-sm dateclass placeholderclass"
                      onClick="$(this).removeClass('placeholderclass')"
                      v-model="prescription.from_date"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide a from date.
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <span class="text-danger d-block" v-if="errors.to_date">
                      {{ errors.to_date }}
                    </span>
                    <input
                      type="date"
                      placeholder="To *"
                      class="form-control form-control-sm dateclass placeholderclass"
                      onClick="$(this).removeClass('placeholderclass')"
                      v-model="prescription.to_date"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide a to date.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.detail">
                      {{ errors.detail }}
                    </span>
                    <textarea
                      name=""
                      id=""
                      rows="7"
                      class="text-area"
                      placeholder="Type detail.... *"
                      v-model="prescription.detail"
                      required
                    ></textarea>
                    <div class="invalid-feedback text-danger">
                      Please provide detail.
                    </div>
                  </div>
                </div>
                <div
                  class="form-group row"
                  v-for="(entry, index) in entries"
                  :key="index"
                >
                <h3 class="m-2">Medicine {{ index +1 }}</h3>
                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.c_date">
                      {{ errors.c_date }}
                    </span>
                    <input
                      type="date"
                      placeholder="Current Date *"
                      class="form-control form-control-sm dateclass placeholderclass"
                      @click="
                        $event.target.classList.remove('placeholderclass')
                      "
                      v-model="entry.c_date"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide a current date.
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.m_name">
                      {{ errors.m_name }}
                    </span>
                    <input
                      type="text"
                      placeholder="Medicine name *"
                      class="form-control form-control-sm"
                      v-model="entry.m_name"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide a medicine name.
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.days">
                      {{ errors.days }}
                    </span>
                    <input
                      type="number"
                      placeholder="Days *"
                      class="form-control form-control-sm"
                      v-model="entry.days"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide the number of days.
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="">Medicine Time *</label>
                    <div>
                      <button
                        type="button"
                        v-for="time in medicine_times"
                        :key="time"
                        :class="[
                          'radio-button',
                          { selected: entry.m_time === time },
                        ]"
                        @click="selectMedicineTime(entry, time)"
                      >
                        {{ time }}
                      </button>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="">To be taken *</label>
                    <div>
                      <button
                        type="button"
                        v-for="taken in to_be_takens"
                        :key="taken"
                        :class="[
                          'radio-button',
                          { selected: entry.to_be_taken === taken },
                        ]"
                        @click="selectToBeTaken(entry, taken)"
                      >
                        {{ taken }}
                      </button>
                    </div>
                  </div>

                  <div class="col-md-6 mt-2">
                    <div class="d-flex">
                      <div class="mx-2">
                        <label class="me-2">Still using it</label>
                        <input
                          type="checkbox"
                          class="c-checkbox mb-3"
                          :id="'send-still_using-' + index"
                          v-model="entry.still_using"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.note">
                      {{ errors.note }}
                    </span>
                    <textarea
                      rows="7"
                      class="text-area"
                      placeholder="Type note.... *"
                      v-model="entry.note"
                      required
                    ></textarea>
                    <div class="invalid-feedback text-danger">
                      Please provide a note.
                    </div>
                  </div>
                </div>

                <button class="c-button cbg-primary float-end" type="button" @click="addEntry">Add New Medicine</button>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <button type="submit" class="c-button cbg-primary">
                      Submit
                    </button>
                    <button type="button" class="ms-2 c-button cbg-secondary">
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
  },
  data() {
    return {
      prescription: {
        uid: null,
        from_date: null,
        to_date: null,
        detail: null,
        m_time: null,
        c_date: null,
        days: null,
        to_be_taken: null,
        still_using: "No",
        note: null,
        patient_id: null,
        reports: [],
        created_by: localStorage.getItem("uid"),
      },
      entries: [
        {
          c_date: "",
          m_name: "",
          days: "",
          m_time: "",
          to_be_taken: "",
          still_using: false,
          note: "",
        },
      ],
      medicine_times: ["Morning", "Noon", "Evening", "Night"],
      to_be_takens: ["After Food", "Before Food"],
      filePreview: null,
      loading: false,
      errors: [],
    };
  },
  computed: {
    stillUsingComputed: {
      get() {
        return this.prescription.still_using === "Yes";
      },
      set(newValue) {
        this.prescription.still_using = newValue ? "Yes" : "No";
      },
    },
  },
  mounted() {
    this.prescription.uid = this.$route.params.id;
  },
  methods: {
    addEntry() {
      this.loading = true;
      this.entries.push({
        c_date: "",
        m_name: "",
        days: "",
        m_time: "",
        to_be_taken: "",
        still_using: false,
        note: "",
      });
      setTimeout(() => {
        this.loading = false;
      }, 250);
    },
    selectMedicineTime(entry, time) {
      entry.m_time = time;
    },
    selectToBeTaken(entry, taken) {
      entry.to_be_taken = taken;
    },

    submit() {
      var form = document.getElementById("add-prescriptions");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.prescription);
        this.$store
          .dispatch("medicalHistories/store", { data })
          .then((response) => {
            this.loading = false;
            if (response.success === true) {
              this.$toast.show("Successfuly added", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.title = "";
            } else {
              this.$toast.show("Failed to save", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 5000,
              });
            }
          })
          .catch((e) => {
            this.errors = e.error;
            this.loading = false;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },
};
</script>
  