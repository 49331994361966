<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs></Bread_crumbs>
        <div class="col-sm-12 col-xl-12">
          <div class="card">
            <div class="card-header">
              <h3>Promotional Content</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div
                  class="col-sm-3 col-md-2 tabs-responsive-side line-tab-left"
                >
                  <div
                    class="nav flex-column nav-primary border-tab nav-left"
                    id="v-pills-success-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-success-logo-tab"
                      data-bs-toggle="pill"
                      href="#v-pills-success-logo"
                      role="tab"
                      aria-controls="v-pills-success-logo"
                      aria-selected="true"
                      >Logo</a
                    ><a
                      class="nav-link"
                      id="v-pills-success-banner-tab"
                      data-bs-toggle="pill"
                      href="#v-pills-success-banner"
                      role="tab"
                      aria-controls="v-pills-success-banner"
                      aria-selected="false"
                      >Banner</a
                    ><a
                      class="nav-link"
                      id="v-pills-success-about-tab"
                      data-bs-toggle="pill"
                      href="#v-pills-success-about"
                      role="tab"
                      aria-controls="v-pills-success-about"
                      aria-selected="false"
                      >About</a
                    >
                    <a
                      class="nav-link"
                      id="v-pills-success-slider-tab"
                      data-bs-toggle="pill"
                      href="#v-pills-success-slider"
                      role="tab"
                      aria-controls="v-pills-success-slider"
                      aria-selected="false"
                      >Sliders</a
                    >
                    <a
                      class="nav-link"
                      id="v-pills-success-how-it-works-tab"
                      data-bs-toggle="pill"
                      href="#v-pills-success-how-it-works"
                      role="tab"
                      aria-controls="v-pills-success-how-it-works"
                      aria-selected="false"
                      >How It Works</a
                    >
                    <a
                      class="nav-link"
                      id="v-pills-success-testimonials-tab"
                      data-bs-toggle="pill"
                      href="#v-pills-success-testimonials"
                      role="tab"
                      aria-controls="v-pills-success-testimonials"
                      aria-selected="false"
                      >Testimonials</a
                    >
                  </div>
                </div>
                <div class="col-sm-9 col-md-10">
                  <div class="tab-content" id="v-pills-success-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="v-pills-success-logo"
                      role="tabpanel"
                      aria-labelledby="v-pills-success-logo-tab"
                    >
                      <Logo
                        v-if="promotional.logo"
                        :logoFile="promotional.logo"
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-success-banner"
                      role="tabpanel"
                      aria-labelledby="v-pills-success-banner-tab"
                    >
                      <Banner
                        v-if="promotional.banner"
                        :bannerData="promotional.banner"
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-success-about"
                      role="tabpanel"
                      aria-labelledby="v-pills-success-about-tab"
                    >
                      <About
                        v-if="promotional.about"
                        :aboutData="promotional.about"
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-success-slider"
                      role="tabpanel"
                      aria-labelledby="v-pills-success-slider-tab"
                    >
                      <Slider />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-success-how-it-works"
                      role="tabpanel"
                      aria-labelledby="v-pills-success-how-it-works-tab"
                    >
                      <HowItWorks
                        v-if="promotional.how_it_works"
                        :hiwData="promotional.how_it_works"
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-success-testimonials"
                      role="tabpanel"
                      aria-labelledby="v-pills-success-testimonials-tab"
                    >
                      <Testimonial
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";
import Logo from "@/components/promotional/logo.vue";
import Banner from "@/components/promotional/banner.vue";
import About from "@/components/promotional/about.vue";
import Slider from "@/components/promotional/slider.vue";
import HowItWorks from "@/components/promotional/how_it_works.vue";
import Testimonial from "@/components/promotional/testimonial.vue";

export default {
  components: {
    Bread_crumbs,
    Logo,
    Banner,
    About,
    Slider,
    HowItWorks,
    Testimonial
  },
  mixins: [isNumber],
  data() {
    return {
      promotional: {
        logo: null,
        banner: null,
        about: null,
        how_it_works: "",
        file: null,
      },
      filePreview: null,
      errors: [],
      loading: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("promotional/getAll", {})
        .then((response) => {
          this.promotional = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
