<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link
          :to="`/app-users/show/${history.uid}`"
          class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="col-md-8 offset-md-2">
          <div class="card mt-4">
            <div class="card-header">
              <h4 class="card-title">Add Medical History</h4>
            </div>
            <div class="card-body">
              <form
                @submit.prevent="submit"
                ref="addMedicalHistory"
                id="add-medical-histories"
                class="needs-validation"
                novalidate
              >
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Disease *"
                    v-model="history.disease_name"
                    required
                  />
                  <span class="text-danger d-block" v-if="errors.disease_name">
                    {{ errors.disease_name[0] }}
                  </span>
                  <div class="invalid-feedback text-danger">
                    Please provide a disease.
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6 mb-3">
                    <span class="text-danger d-block" v-if="errors.from_date">
                      {{ errors.from_date }}
                    </span>
                    <input
                      type="date"
                      placeholder="From *"
                      class="form-control form-control-sm dateclass placeholderclass"
                      onClick="$(this).removeClass('placeholderclass')"
                      v-model="history.from_date"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide a from date.
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <span class="text-danger d-block" v-if="errors.to_date">
                      {{ errors.to_date }}
                    </span>
                    <input
                      type="date"
                      placeholder="To *"
                      class="form-control form-control-sm dateclass placeholderclass"
                      onClick="$(this).removeClass('placeholderclass')"
                      v-model="history.to_date"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide a to date.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.detail">
                      {{ errors.detail }}
                    </span>
                    <textarea
                      name=""
                      id=""
                      rows="7"
                      class="text-area"
                      placeholder="Type detail.... *"
                      v-model="history.detail"
                      required
                    ></textarea>
                    <div class="invalid-feedback text-danger">
                      Please provide detail.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12 mb-3">
                    <label for="file-upload" class="custom-file-upload form-control">Upload Report</label>
                    <span class="text-danger d-block" v-if="errors.file">
                      {{ errors.file }}
                    </span>
                    <input
                      type="file"
                      ref="file"
                      id="file-upload"
                      accept="image/*,audio/*"
                      placeholder="upload file"
                      class="form-control form-control-sm"
                      @change="selectFile()"
                    />
                    <div class="invalid-feedback text-danger">
                      Please upload a report.
                    </div>
                    <img
                      v-if="filePreview"
                      :src="filePreview"
                      alt=""
                      class="mt-3"
                      width="50%"
                      height="200"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.c_date">
                      {{ errors.c_date }}
                    </span>
                    <input
                      type="date"
                      placeholder="Current Date *"
                      class="form-control form-control-sm dateclass placeholderclass"
                      onClick="$(this).removeClass('placeholderclass')"
                      v-model="history.c_date"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide a current date.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <!-- <label>Email <span class="text-danger">*</span></label> -->
                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.m_name">
                      {{ errors.m_name }}
                    </span>
                    <input
                      type="text"
                      placeholder="Medicine name *"
                      class="form-control form-control-sm"
                      v-model="history.m_name"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide an medicine name.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <!-- <label>Email <span class="text-danger">*</span></label> -->
                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.days">
                      {{ errors.days }}
                    </span>
                    <input
                      type="number"
                      placeholder="Days *"
                      class="form-control form-control-sm"
                      v-model="history.days"
                      required
                    />
                    <div class="invalid-feedback text-danger">
                      Please provide a days.
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="">Medicine Time *</label>
                  <div class="col-md-12 mb-3">
                    <button
                    type="button"
                      v-for="time in medicine_times"
                      :key="time"
                      :class="[
                        'radio-button',
                        { selected: history.m_times.includes(time) },
                      ]"
                      @click="selectMedicineTime(history, time)"
                    >
                      {{ time }}
                    </button>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="">To be taken *</label>
                  <div class="col-md-12 mb-3">
                    <button
                    type="button"
                      v-for="taken in to_be_takens"
                      :key="taken"
                      :class="[
                        'radio-button',
                        { selected: history.to_be_taken === taken },
                      ]"
                      @click="selectToBeTaken(taken)"
                    >
                      {{ taken }}
                    </button>
                  </div>
                </div>
                <div class="form-group row">
                  <!-- <label>Email <span class="text-danger">*</span></label> -->
                  <div class="col-md-6 mt-2">
                    <div class="d-flex">
                      <div class="mx-2">
                        <label class="me-2">Still using it</label>
                        <input
                          type="checkbox"
                          class="c-checkbox mb-3"
                          id="send-still_using"
                          v-model="stillUsingComputed"
                          value="Yes"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12 mb-3">
                    <span class="text-danger d-block" v-if="errors.note">
                      {{ errors.note }}
                    </span>
                    <textarea
                      name=""
                      id=""
                      rows="7"
                      class="text-area"
                      placeholder="Type note.... *"
                      v-model="history.note"
                      required
                    ></textarea>
                    <div class="invalid-feedback text-danger">
                      Please provide note.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <button type="submit" class="c-button cbg-primary">
                      Submit
                    </button>
                    <button type="button" @click="this.$refs.addMedicalHistory.reset()" class="ms-2 c-button cbg-secondary">
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
  },
  data() {
    return {
      history: {
        uid: null,
        from_date: null,
        to_date: null,
        detail:null,
        m_times:[],
        c_date:null,
        days:null,
        to_be_taken:null,
        still_using:"No",
        note:null,
        patient_id:null,
        reports:[],
        created_by: localStorage.getItem("uid"),
      },
      medicine_times: ["Morning", "Noon", "Evening", "Night"],
      to_be_takens: ["After Food", "Before Food"],
      filePreview:null,
      loading: false,
      errors: [],
    };
  },
  computed: {
    stillUsingComputed: {
      get() {
        return this.history.still_using === 'Yes';
      },
      set(newValue) {
        this.history.still_using = newValue ? 'Yes' : 'No';
      },
    },
  },
  mounted() {
    this.history.uid = this.$route.params.id;
  },
  methods: {
    selectMedicineTime(history, time) {
      if (history.m_times.includes(time)) {
        // If the time is already selected, remove it
        history.m_times = history.m_times.filter((t) => t !== time);
      } else {
        // Otherwise, add it to the array
        history.m_times.push(time);
      }
    },
    selectToBeTaken(time) {
      this.history.to_be_taken = time;
    },
    submit() {
      var form = document.getElementById("add-medical-histories");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.history);
        this.$store
          .dispatch("medicalHistories/store", { data })
          .then((response) => {
            this.loading = false;
            if (response.success === true) {
              this.$toast.show("Successfuly added", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              if (this.$refs.addMedicalHistory) {
                this.$nextTick(() => {
                  this.$refs.addMedicalHistory.reset();
                });
              }
              this.title = "";
              window.history.back();
            } else {
              this.$toast.show("Failed to save", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 5000,
              });
            }
          })
          .catch((e) => {
            this.errors = e.error;
            this.loading = false;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
        // console.log(reader.result);
          this.history.reports = reader.result;
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
};
</script>
  