<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <!-- <router-link to="/roles" class="c-button cbg-primary mb-3"
          >Back</router-link
        > -->
        <div class="col-md-8 offset-md-2">
          <div class="card mt-4">
            <div class="card-header">
              <h4 class="card-title">Send Notification</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="submit" ref="addNotification">
                <div class="form-group row">
                  <div class="col-md-6 mt-2">
                    <p><b>Send to</b></p>
                    
                    <div class="d-flex">
                      <div class="mx-2">
                        <input
                          type="radio"
                          class="c-checkbox mb-3"
                          id="admin"
                          v-model="notification.sendTo"
                          value="admin"
                          @change="selectType('admin')"
                        />
                        <label for="admin" class="ms-2"
                          >Admin</label
                        >
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="mx-2">
                        <input
                          type="radio"
                          class="c-checkbox mb-3"
                          id="doctor"
                          v-model="notification.sendTo"
                          value="doctor"
                          @change="selectType('user')"
                        />
                        <label for="doctor" class="ms-2"
                          >Doctor</label
                        >
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="mx-2">
                        <input
                          type="radio"
                          class="c-checkbox mb-3"
                          id="chw"
                          v-model="notification.sendTo"
                          value="chw"
                          @change="selectType('user')"
                        />
                        <label for="chw" class="ms-2"
                          >CHW</label
                        >
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="mx-2">
                        <input
                          type="radio"
                          class="c-checkbox mb-3"
                          id="patient"
                          v-model="notification.sendTo"
                          value="patient"
                          @change="selectType('user')"
                        />
                        <label for="patient" class="ms-2"
                          >Patient</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm mb-3"
                  placeholder="Title *"
                  required
                  v-model="notification.title"
                />
                <input
                  type="text"
                  class="form-control form-control-sm mb-3"
                  placeholder="Body *"
                  required
                  v-model="notification.body"
                />
                <div class="row">
                  <div class="col-md-12">
                    <button type="submit" class="c-button cbg-primary">
                      Submit
                    </button>
                    <button
                      type="button"
                      @click="this.$refs.addNotification.reset()"
                      class="ms-2 c-button cbg-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import PermissionList from "@/components/permission/PermissionList.vue";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
    PermissionList,
  },
  data() {
    return {
      notification: {
        sendTo: null,
        title: null,
        body: null,
        channel:null,
        type:"",
      },
      loading: false,
      modules: [],
    };
  },
  mounted() {
    this.notification.channel = 'web';
  },
  methods: {
    submit() {
      this.loading = true;
      const data = toRaw(this.notification);
      this.$store
        .dispatch("notifications/send", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            if (this.$refs.addNotification) {
                this.$nextTick(() => {
                  this.$refs.addNotification.reset();
                  this.$refs.addNotification.classList.remove("was-validated");
                });
              }
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    selectType(type){
      this.notification.type = type;
      if(type == "user"){
        this.notification.channel = "mobile"
      }
    }
  },
};
</script>
