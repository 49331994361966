<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/roles" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Edit Role</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <!-- <label>Name</label> -->
              <input
                type="text"
                placeholder="Name *"
                class="form-control form-control-sm mb-3"
                required
                v-model="role.name"
              />
              <div class="row">
                <div class="col">
                  <label>Modules Permissions</label>
                  <PermissionList
                    v-if="role.permissions.length > 0"
                    @permissions-changed="handlePermissions"
                    :permissionsList="role.permissions"
                  />
                  <PermissionList
                    v-else
                    @permissions-changed="handlePermissions"
                    :permissionsList="role.permissions"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import PermissionList from "@/components/permission/PermissionList.vue";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
    PermissionList,
  },
  data() {
    return {
      role: {
        name: "",
        permissions: [],
      },
      roleId: null,
      loading: false,
      modules: [],
    };
  },
  mounted() {
    this.roleId = this.$route.params.id;
    // this.fetchModules();
    this.fetch(this.roleId);
  },
  methods: {
    handlePermissions(permissions) {
      this.role.permissions = permissions;
      console.log(this.role.permissions);
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("roles/getSingle", { id })
        .then((response) => {
          this.role = response.data;
          const rolePermissions = [];
          this.role.permissions.forEach((item) => {
            rolePermissions.push(item.name);
            // this.role.permissions.splice(
            //   this.role.permissions.indexOf(item),
            //   1
            // );
          });
          this.role.permissions = rolePermissions;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.role);
      const id = this.roleId;
      this.$store
        .dispatch("roles/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            // this.$refs.addRole.reset();
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    fetchModules() {
      this.loading = true;
      this.$store
        .dispatch("module/getAll")
        .then((response) => {
          this.loading = false;
          this.modules = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
