<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs></Bread_crumbs>
        <router-link to="/admins" class="c-button cbg-primary mb-3">Back</router-link>
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Sub Admin</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" id="sub-admin" ref="addSubAdmin" class="needs-validation" novalidate>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <!-- <label>First Name <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.f_name">
                    {{ errors.f_name }}
                  </span>

                  <input type="text" placeholder="First Name *" class="form-control form-control-sm"
                    v-model="admin.f_name" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a name.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <!-- <label>Last Name</label> -->
                  <input type="text" placeholder="Last Name" class="form-control form-control-sm"
                    v-model="admin.l_name" />
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.email">
                    {{ errors.email }}
                  </span>
                  <input type="email" placeholder="Email *" class="form-control form-control-sm" v-model="admin.email"
                    ern="[^\s@]+@[^\s@]+\.[^\spatt@]+" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a valid email.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.phone">
                    {{ errors.phone }}
                  </span>
                  <input maxlength="11" pattern="[0-9]{11}" type="text" placeholder="Phone *"
                    class="form-control form-control-sm" v-model="admin.phone" @keypress="isNumber($event)" />
                  <div class="invalid-feedback text-danger">
                    Please provide a number atleast 11 digits.
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <!-- <label>Password <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.password">
                    {{ errors.password }}
                  </span>
                  <input type="password" placeholder="Password *" id="password" name="password"
                    class="form-control form-control-sm" v-model="admin.password" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a password.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.confirmPassword">
                    {{ errors.confirmPassword }}
                  </span>
                  <input type="password" id="confirmPassword" name="confirmPassword" placeholder="Confirm Password *"
                    class="form-control form-control-sm" data-match="#password"
                    data-match-error="Passwords do not match" v-model="admin.confirmPassword" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a confirm password.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <!-- <label>Password <span class="text-danger">*</span></label> -->
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.dob">
                    {{ errors.dob }}
                  </span>
                  <input type="date" placeholder="Date of Birth"
                  :max="new Date().toISOString().split('T')[0]"
                    class="form-control form-control-sm dateclass placeholderclass"
                    onClick="$(this).removeClass('placeholderclass')" v-model="admin.dob" />
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.cnic">
                    {{ errors.cnic }}
                  </span>
                  <input maxlength="13" pattern="[0-9]{13}" type="text" placeholder="CNIC *"
                    class="form-control form-control-sm" v-model="admin.cnic" @keypress="isNumber($event)" required />
                  <div class="invalid-feedback text-danger">
                    Please provide a cnic atleast 13 digits.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Role <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mb-3">
                  <select class="c-form-select" v-model="admin.role" required>
                    <option value="">Select Role... *</option>
                    <option v-for="role in roles" :value="role.name" v-bind:key="role.id">
                      {{ role.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a role.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mt-2">
                  <p><b>Gender *</b></p>
                  <span class="text-danger d-block" v-if="errors.gender">
                    {{ errors.gender }}
                  </span>
                  <div class="d-flex">
                    <div>
                      <input type="radio" class="c-checkbox mb-3" id="male" v-model="admin.gender" value="male"
                        checked />
                      <label for="male" class="ms-2">Male</label>
                    </div>
                    <div class="mx-2">
                      <input type="radio" class="c-checkbox mb-3" id="female" v-model="admin.gender" value="female" />
                      <label for="female" class="ms-2">Female</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <label>Email <span class="text-danger">*</span></label> -->
                <div class="col-md-6 mt-2">
                  <p><b>Send Credentials via email</b></p>
                  <span class="text-danger d-block" v-if="errors.send_credentials">
                    {{ errors.send_credentials }}
                  </span>
                  <div class="d-flex">
                    <div class="mx-2">
                      <input type="checkbox" class="c-checkbox mb-3" id="send-credentials"
                        v-model="admin.send_credentials" value="send-credentials" />
                      <label for="send-credentials" class="ms-2">Send Credentials via email</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addSubAdmin.reset();" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  mixins: [helpers],
  data() {
    return {
      admin: {
        f_name: null,
        l_name: null,
        phone: null,
        email: null,
        cnic: null,
        dob: null,
        gender: "male",
        password: null,
        confirmPassword: null,
        role: "",
        send_credentials: false,
        created_by: localStorage.getItem("uid"),
      },
      roles: [],
      errors: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchRoles();
  },
  methods: {
    submit() {
      var form = document.getElementById("sub-admin");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.admin);
        this.$store
          .dispatch("admins/store", { data })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.$refs.addSubAdmin.reset();
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          });
      }
    },
    fetchRoles(paginate = 'false') {
      this.loading = true;
      this.$store
        .dispatch("roles/getAll", { paginate })
        .then((response) => {
          this.roles = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
