<template>
    <div class="card visitor-card">
      <div class="card-header card-no-border pb-0">
        <div class="header-top">
          <h5 class="m-0">
            Total Users<span class="f-14 c-text f-w-500 ms-1">
              <svg class="svg-fill me-1">
                <use
                  href="@/assets/svg/icon-sprite.svg#user-visitor"
                ></use></svg
              >({{ admins + doctors + chw + patients }})</span
            >
          </h5>
        </div>
      </div>
      <div class="card-body pt-3">
        <div class="visitors-container px-3">
            <div class="d-flex justify-content-between my-4 border-bottom">
                <h6>Admins</h6>
                <h6 class="text-primary">{{ admins }}</h6>
            </div>
            <div class="d-flex justify-content-between my-4 border-bottom">
                <h6>Doctors</h6>
                <h6 class="text-primary">{{ doctors }}</h6>
            </div>
            <div class="d-flex justify-content-between my-4 border-bottom">
                <h6>CHW</h6>
                <h6 class="text-primary">{{ chw }}</h6>
            </div>
            <div class="d-flex justify-content-between my-4 border-bottom">
                <h6>Patients</h6>
                <h6 class="text-primary">{{ patients }}</h6>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  props: {
    admins: Number,
    doctors: Number,
    chw: Number,
    patients: Number,
  },

  data() {
    return {
     
      categories: null,
      transformedData: null,
    };
  },

  mounted() {
   
  },
};
</script>

<style>
.visitors-container svg .apexcharts-series path {
  clip-path: inset(1% 0% 0% 0% round 0rem) !important;
}
</style>