// views list for Categorys here
import ListCategory from "@/views/categories/index";
import AddCategory from "@/views/categories/add";
import EditCategory from "@/views/categories/edit";

const routes = [
  {
    path: '/categories/',
    name: 'Category',
    component: ListCategory,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_chw'
    },
    props: true
  },
  {
    path: '/categories/add',
    name: 'Add Category',
    component: AddCategory,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_chw'
    }
  },
  {
    path: '/categories/edit/:id',
    name: 'Edit Category',
    component: EditCategory,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_chw'
    },
    props: true
  },
];

export default routes;
