// views list for doctors here
import ListDoctor from "@/views/doctors/index";
import AddDoctor from "@/views/doctors/add";
import EditDoctor from "@/views/doctors/edit";
import ShowDoctor from "@/views/doctors/show";

const routes = [
  {
    path: '/doctors/',
    name: 'Doctors',
    component: ListDoctor,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_doctor'
    },
    props: true
  },
  {
    path: '/doctors/add',
    name: 'Add Doctor',
    component: AddDoctor,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_doctor'
    }
  },
  {
    path: '/doctors/edit/:id',
    name: 'Edit Doctor',
    component: EditDoctor,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_doctor'
    },
    props: true
  },
  {
    path: '/doctors/show/:id',
    name: 'Doctor Profile',
    component: ShowDoctor,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_doctor'
    },
    props: true
  },
];

export default routes;
