<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row my-2" v-for="module in modules" :key="module.title">
          <div class="col-3">
            <strong>{{ module.title }}</strong>
          </div>
          <div class="col-2" v-if="module.view">
            <label>
              <input
                type="checkbox"
                class="me-1"
                :value="module.view.value"
                v-on:change="sendToParent"
                v-model="permissions"
              />
              {{ module.view.label }}
            </label>
          </div>
          <div class="col-2" v-if="module.create">
            <label>
              <input
                type="checkbox"
                class="me-1"
                :value="module.create.value"
                v-on:change="sendToParent"
                v-model="permissions"
              />{{ module.create.label }}
            </label>
          </div>
          <div class="col-2" v-if="module.edit">
            <label>
              <input
                type="checkbox"
                class="me-1"
                :value="module.edit.value"
                v-on:change="sendToParent"
                v-model="permissions"
              />{{ module.edit.label }}
            </label>
          </div>
          <div class="col-2" v-if="module.delete">
            <label>
              <input
                type="checkbox"
                class="me-1"
                :value="module.delete.value"
                v-on:change="sendToParent"
                v-model="permissions"
              />{{ module.delete.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PermissionList",
  props: ['permissionsList'],
  data() {
    return {
      modules: [
        {
          title: "Sub Admin",
          view: { label: "view", value: "view_sub_admin" },
          create: { label: "create", value: "create_sub_admin" },
          edit: { label: "edit", value: "edit_sub_admin" },
          delete: { label: "delete", value: "delete_sub_admin" },
        },
        {
          title: "Role",
          view: { label: "view", value: "view_role" },
          create: { label: "create", value: "create_role" },
          edit: { label: "edit", value: "edit_role" },
          delete: { label: "delete", value: "delete_role" },
        },
        {
          title: "Doctor",
          view: { label: "view", value: "view_doctor" },
          create: { label: "create", value: "create_doctor" },
          edit: { label: "edit", value: "edit_doctor" },
          delete: { label: "delete", value: "delete_doctor" },
        },
        {
          title: "CHW",
          view: { label: "view", value: "view_chw" },
          create: { label: "create", value: "create_chw" },
          edit: { label: "edit", value: "edit_chw" },
          delete: { label: "delete", value: "delete_chw" },
        },
        {
          title: "App User",
          view: { label: "view", value: "view_app_user" },
          create: { label: "create", value: "create_app_user" },
          edit: { label: "edit", value: "edit_app_user" },
          delete: { label: "delete", value: "delete_app_user" },
        },
        {
          title: "Task",
          view: { label: "view", value: "view_task" },
          create: { label: "create", value: "create_task" },
          edit: { label: "edit", value: "edit_task" },
          delete: { label: "delete", value: "delete_task" },
        },
        {
          title: "HLC",
          view: { label: "view", value: "view_hlc" },
          create: { label: "create", value: "create_hlc" },
          edit: { label: "edit", value: "edit_hlc" },
          delete: { label: "delete", value: "delete_hlc" },
        },
        {
          title: "Campaign",
          view: { label: "view", value: "view_campaign" },
          create: { label: "create", value: "create_campaign" },
          edit: { label: "edit", value: "edit_campaign" },
          delete: { label: "delete", value: "delete_campaign" },
        },
        {
          title: "Form",
          view: { label: "view", value: "view_form" },
          create: { label: "create", value: "create_form" },
          edit: { label: "edit", value: "edit_form" },
          delete: { label: "delete", value: "delete_form" },
        },
        {
          title: "Facility Center",
          view: { label: "view", value: "view_facility_center" },
          create: { label: "create", value: "create_facility_center" },
          edit: { label: "edit", value: "edit_facility_center" },
          delete: { label: "delete", value: "delete_facility_center" },
        },
        {
          title: "Location",
          view: { label: "view", value: "view_location" },
          create: { label: "create", value: "create_location" },
          edit: { label: "edit", value: "edit_location" },
          delete: { label: "delete", value: "delete_location" },
        },
        {
          title: "Activity",
          view: {
            label: "view doctor",
            value: "view_activity_doctor",
          },
          create: { label: "view chw", value: "create_activity_chw" },
          edit: '',
          delete: '',
        },
        {
          title: "Qualification",
          view: { label: "view", value: "view_qualification" },
          create: { label: "create", value: "create_qualification" },
          edit: { label: "edit", value: "edit_qualification" },
          delete: { label: "delete", value: "delete_qualification" },
        },
        {
          title: "Specialization",
          view: { label: "view", value: "view_specialization" },
          create: { label: "create", value: "create_specialization" },
          edit: { label: "edit", value: "edit_specialization" },
          delete: { label: "delete", value: "delete_specialization" },
        },
        {
          title: "Faq",
          view: { label: "view", value: "view_faq" },
          create: { label: "create", value: "create_faq" },
          edit: { label: "edit", value: "edit_faq" },
          delete: { label: "delete", value: "delete_faq" },
        },
        {
          title: "Setting",
          view: { label: "view", value: "view_setting" },
          create: { label: "create", value: "create_setting" },
          edit: { label: "edit", value: "edit_setting" },
          delete: { label: "delete", value: "delete_setting" },
        },
        {
          title: "Feedback",
          view: { label: "view", value: "view_feedback" },
          create: { label: "create", value: "create_feedback" },
          edit: { label: "edit", value: "edit_feedback" },
          delete: { label: "delete", value: "delete_feedback" },
        },
        {
          title: "Support",
          view: { label: "view", value: "view_support" },
          create: { label: "create", value: "create_support" },
          edit: { label: "edit", value: "edit_support" },
          delete: { label: "delete", value: "delete_support" },
        },
        {
          title: "Notification",
          view: { label: "view", value: "view_notification" },
          create: { label: "create", value: "create_notification" },
          edit: { label: "edit", value: "edit_notification" },
          delete: { label: "delete", value: "delete_notification" },
        },
      ],
      permissions: [],
    };
  },
  methods: {
    sendToParent(e) {
      this.$emit("permissions-changed", this.permissions);
    },
  },
  mounted() {
    this.permissions = this.permissionsList
  },
};
</script>