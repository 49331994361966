<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <button type="button" @click="$router.go(-1)" class="c-button cbg-primary">
            Back
          </button>
        
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title" v-if="history">Medical History of {{history.disease_name}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-3" v-if="history">
                <a :href="report.path" target="_blank" v-for="report of history.reports" :key="report"><img :src="report.path" class="w-25" alt=""/></a>
              </div>
            </div>
            <b>Medication:</b>
            <div class="row my-3">
              <div class="col-sm-6 col-md-2">Medication Name : </div>
              <div class="col-sm-6 col-md-10" v-if="history">{{ history.m_name }}</div>
            </div>
            <div class="row my-3">
              <div class="col-sm-6 col-md-2">Medication Days : </div>
              <div class="col-sm-6 col-md-10" v-if="history">{{ history.days }}</div>
            </div>
            <div class="row my-3 ">
              <div class="col-sm-6 col-md-2">To be Taken : </div>
              <div class="col-sm-6 col-md-10" v-if="history">{{ history.to_be_taken }}</div>
            </div>
            <div class="row my-3">
              <div class="col-sm-6 col-md-2">Medication Time : </div>
              <div class="col-sm-6 col-md-10" v-if="history">{{ history.m_times.join(', ') }}</div>
            </div>
            <b>Important Note:</b>
            <div class="row my-3">
              <div class="col-md-12" v-if="history">{{ history.note }}</div>
            </div>
            <b>Comments:</b>
            <div class="row my-3">
              <div class="col-md-6">Dummy comment </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
  },
  data() {
    return {
      history: null,
      districtId: null,
      mh_id:null,
      loading: false,
      errors: [],
    };
  },
  mounted() {
    this.mh_id = this.$route.params.id;
    this.fetch(this.mh_id);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("medicalHistories/getSingle", { id })
        .then((response) => {
          this.history = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>
  