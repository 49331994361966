// views list for feedbacks here
import ListFeedback from "@/views/feedbacks/index";
import AddFeedback from "@/views/feedbacks/add";
import EditFeedback from "@/views/feedbacks/edit";
// import ShowFeedback from "@/views/feedbacks/show";

const routes = [
  {
    path: '/feedbacks/',
    name: 'Feedback List',
    component: ListFeedback,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
  {
    path: '/feedbacks/add',
    name: 'Add Feedback',
    component: AddFeedback,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    }
  },
  {
    path: '/feedbacks/edit/:id',
    name: 'Edit Feedback',
    component: EditFeedback,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
//   {
//     path: '/feedbacks/show/:id',
//     name: 'Feedback Show',
//     component: ShowFeedback,
//     meta: {
//       requiresAuth: true,
//       requiredPermission: 'view_location'
//     },
//     props: true
//   },
];

export default routes;
