// views list for faqs here
import ListFaq from "@/views/faqs/index";
import AddFaq from "@/views/faqs/add";
import EditFaq from "@/views/faqs/edit";

const routes = [
  {
    path: '/faqs/',
    name: 'Faq',
    component: ListFaq,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_chw'
    },
    props: true
  },
  {
    path: '/faqs/add',
    name: 'Add Faq',
    component: AddFaq,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_chw'
    }
  },
  {
    path: '/faqs/edit/:id',
    name: 'Edit Faq',
    component: EditFaq,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_chw'
    },
    props: true
  },
];

export default routes;
