// views list for campaigns here
import ListCampaign from "@/views/campaigns/index";
import AddCampaign from "@/views/campaigns/add";
import EditCampaign from "@/views/campaigns/edit";
import ShowCampaign from "@/views/campaigns/show";

const routes = [
  {
    path: '/campaigns/',
    name: 'Campaigns',
    component: ListCampaign,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_campaign'
    },
    props: true
  },
  {
    path: '/campaigns/add',
    name: 'Add Campaign',
    component: AddCampaign,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_campaign'
    }
  },
  {
    path: '/campaigns/edit/:id',
    name: 'Edit Campaign',
    component: EditCampaign,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_campaign'
    },
    props: true
  },
  {
    path: '/campaigns/show/:id',
    name: 'Campaign Profile',
    component: ShowCampaign,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
];

export default routes;
