<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs></Bread_crumbs>
        <router-link to="/tasks" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Task</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              id="task"
              ref="addTask"
              class="needs-validation"
              novalidate
            >
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <!-- <label>First Name <span class="text-danger">*</span></label> -->
                  <span class="text-danger d-block" v-if="errors.title">
                    {{ errors.title }}
                  </span>

                  <input
                    type="text"
                    placeholder="Task Title *"
                    class="form-control form-control-sm"
                    v-model="task.title"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a title.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.district_id">
                    {{ errors.district_id }}
                  </span>
                  <select
                    class="c-form-select"
                    v-model="task.district_id"
                    v-on:change="fetchTehsils()"
                    required
                  >
                    <option value="">Select District ... *</option>
                    <option
                      v-for="district in districts"
                      :value="district.id"
                      v-bind:key="district.id"
                    >
                      {{ district.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a district.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <select
                    class="c-form-select"
                    v-model="task.tehsil_id"
                    v-on:change="fetchCHW()"
                    required
                  >
                    <option value="">Select Tehsile... *</option>
                    <option
                      v-for="tehsil in tehsils"
                      :value="tehsil.id"
                      v-bind:key="tehsil.id"
                    >
                      {{ tehsil.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a tehsil.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.person_ids">
                    {{ errors.person_ids }}
                  </span>
                  <multiselect
                    v-model="task.person_ids"
                    :options="personOptions"
                    :multiple="true"
                    :taggable="true"
                    group-values="libs"
                    group-label="language"
                    :group-select="true"
                    :close-on-select="false"
                    track-by="name"
                    label="name"
                    placeholder="Select CHW Person *"
                    required
                    @tag="addTag"
                  ></multiselect>
                  <div class="invalid-feedback text-danger">
                    Please select a CHW person.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <span class="text-danger d-block" v-if="errors.type">
                    {{ errors.type }}
                  </span>
                  <select
                    class="c-form-select"
                    v-model="task.type"
                    required
                  >
                    <option value="">Select Type... *</option>
                    <option value="Survey">Survey</option>
                    <option value="Campaign">Campaign</option>
                    <option value="Booking">Booking</option>
                    <option value="Knowledge">Knowledge</option>
                    <option value="UserRegistration">User Registration</option>
                    <option value="General">General</option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select type.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 mb-3" v-if="task.type == 'Survey'">
                  <select
                    class="c-form-select"
                    v-model="task.survey_id"
                    required
                  >
                    <option value="">Select Survey Form... *</option>
                    <option
                      v-for="form in forms"
                      :value="form.id"
                      v-bind:key="form.id"
                    >
                      {{ form.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a form.
                  </div>
                </div>
                <div class="col-md-12 mb-3" v-if="task.type == 'Campaign'">
                  <select
                    class="c-form-select"
                    v-model="task.campaign_id"
                    required
                  >
                    <option value="">Select Campaign... *</option>
                    <option
                      v-for="campaign in campaigns"
                      :value="campaign.id"
                      v-bind:key="campaign.id"
                    >
                      {{ campaign.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Please select a campaign.
                  </div>
                </div>
                <div class="col-md-12 mb-3" v-if="task.type == 'General'">
                  <input
                    type="text"
                    v-model="task.note"
                    placeholder="Note *"
                    class="form-control form-control-sm"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please provide a note.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mb-3">
                  <!-- <label for="">Start Date</label> -->
                  <span class="text-danger d-block" v-if="errors.s_date">
                    {{ errors.s_date }}
                  </span>
                  <input
                    type="date"
                    :min="minDate"
                    placeholder="Start Date *"
                    class="form-control form-control-sm dateclass placeholderclass"
                    onClick="$(this).removeClass('placeholderclass')"
                    v-model="task.s_date"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please select start date.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <!-- <label for="">End Date</label> -->
                  <span class="text-danger d-block" v-if="errors.e_date">
                    {{ errors.e_date }}
                  </span>
                  <input
                    type="date"
                    :min="minDate"
                    placeholder="End Date *"
                    class="form-control form-control-sm dateclass placeholderclass"
                    onClick="$(this).removeClass('placeholderclass')"
                    v-model="task.e_date"
                    @change="dateValidation"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please select end date.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <textarea name="" rows="7" v-model="task.desc" id="" placeholder="Type detail..." class="text-area"></textarea>
                  <div class="invalid-feedback text-danger">
                    Please enter a detail.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 mb-3">
                  <label for="file-upload" class="custom-file-upload form-control">Upload File *</label>
                  <span class="text-danger d-block" v-if="errors.file">
                    {{ errors.file }}
                  </span>
                  <input
                    type="file"
                    ref="file"
                    id="file-upload"
                    accept="image/*,audio/*"
                    placeholder="Select File *"
                    class="form-control form-control-sm"
                    @change="selectFile()"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Please select a file.
                  </div>
                  <img
                    v-if="filePreview"
                    :src="filePreview"
                    alt=""
                    class="mt-3"
                    width="50%"
                    height="200"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addTask.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import Bread_crumbs from "@/components/bread_crumbs.vue";
import helpers from "@/helpers/helpers";
export default {
  components: {
    Bread_crumbs,
  },
  mixins: [helpers],
  data() {
    return {
      task: {
        title: null,
        type: "",
        campaign_id:"",
        survey_id:"",
        person_ids: null,
        note: null,
        s_date: null,
        e_date: null,
        district_id: "",
        tehsil_id: "",
        desc:null,
        file: null,
        created_by: localStorage.getItem("uid"),
      },
      filePreview: null,
      districts: [],
      tehsils: [],
      campaigns: [],
      minDate:null,
      forms: [],
      errors: [],
      loading: false,
      personOptions: [
        {
          language: "Select All",
          libs: [],
        },
      ],
    };
  },
  mounted() {
    this.minDate = new Date().toISOString().split('T')[0];
    this.fetchDistricts();
    this.fetchCampaigns();
    this.fetchSurveyForms();
  },
  methods: {
    submit() {
      var form = document.getElementById("task");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      }else if(this.task.person_ids == null){
        this.$toast.show("Please fill the CHW required field!", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 10000,
        });
      } else {
        this.loading = true;
        console.log(this.task);
        const data = toRaw(this.task);
        console.log(data);
        this.$store
          .dispatch("tasks/store", { data })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              if (this.$refs.addTask) {
                this.$nextTick(() => {
                  this.$refs.addTask.reset();
                  this.$refs.addTask.classList.remove("was-validated");
                });
              }
              this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    fetchDistricts() {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("districts/getAll", {})
        .then((response) => {
          this.districts = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.task.file = reader.result;
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    fetchTehsils() {
      const district_id = this.task.district_id;
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("tehsils/getAll", { district_id })
        .then((response) => {
          this.tehsils = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchCHW() {
      const tehsil_id = this.task.tehsil_id;
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("chw/getAll", { tehsil_id })
        .then((response) => {
          let res = response.data;
          res = res.map((e)=>{
            return {id: e.id, name: e.name_with_area}
          })
          this.personOptions[0].libs = res;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchCampaigns() {
      const status = 'Active';
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("campaigns/getAll", { status })
        .then((response) => {
          this.campaigns = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchSurveyForms() {
      const status = 'Active';
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("dynamicForms/getAll", { status })
        .then((response) => {
          this.forms = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    dateValidation(){
      if(this.task.s_date ==  null){
        this.$toast.show("Select a date after the start date!", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 10000,
        });
      }
    },
  },
};
</script>
