import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit }, {}) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/dashboard`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    updateUserStatus({ dispatch, commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`admins/status/${id}`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingle({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/admins/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    store({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/admins", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    update({ dispatch, commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/admins/${id}`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    delete({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`/admins/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
