<template>
  <div>
    <div v-if="timelineType == 'medical-history'">
      <h5
        @click="
          $router.push({ path: `/medical-histories/show/${item.encrypted_id}` })
        "
        class="cursor"
      >
        {{ item.disease_name }}
      </h5>
      <span class="small text-muted"
        ><i class="fas fa-clock me-1"></i>{{ formattedDate }}</span
      >
      <p class="mt-2 mb-0">
        {{ item.detail }}
      </p>
    </div>
    <div v-if="timelineType == 'appointments'">
      <h5
        @click="
          $router.push({
            path: `/appointments/show/${item.encrypted_id}`,
            query: getQuery(),
          })
        "
        class="cursor"
      >
        <span v-if="isNewAppointment">New</span> Appointment
      </h5>
      <span class="small text-muted"
        ><i class="fas fa-clock me-1"></i>{{ formattedDate }}</span
      >
      <p class="mt-2 mb-0">
        You {{ isNewAppointment ? "have" : "had" }} Appointment with

        <span
          v-if="isDoctor"
          class="dr-name cursor"
          @click="
            $router.push({ path: `/doctors/show/${item.doctor.encrypted_id}` })
          "
          >Dr {{ item.doctor.full_name }}</span
        >
        <span
          v-if="isPatient"
          class="dr-name cursor"
          @click="
            $router.push({
              path: `/app-users/show/${item.patient.encrypted_id}`,
            })
          "
          >{{ item.patient.full_name }}</span
        >
        {{ item.s_time }} on {{ dayOfWeek }} {{ formattedDate }}
      </p>
    </div>
    <div v-if="timelineType == 'prescriptions'">
      <h5
        @click="
          $router.push({ path: `/prescriptions/show/${item.encrypted_id}` })
        "
        class="cursor"
      >
        <span v-if="isNewPrescription">New</span> Prescription
      </h5>
      <span class="small text-muted"
        ><i class="fas fa-clock me-1"></i>{{ formattedDate }}</span
      >
      <p class="mt-2 mb-0">
        You {{ isNewPrescription ? "have" : "had" }} Appointment with
        <span
          class="dr-name cursor"
          @click="
            $router.push({ path: `/doctors/show/${item.doctor.encrypted_id}` })
          "
          >Dr {{ item.doctor.full_name }}</span
        >
        {{ item.s_time }} on {{ dayOfWeek }} {{ formattedDate }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    timelineType: String,
    user: Object,
  },
  computed: {
    isNewAppointment() {
      // Parse the date string "31 May 2024"
      const date = this.item.date ? this.item.date : this.item.created_at;
      const dateParts = date.split(" ");
      const day = String(dateParts[0]).padStart(2, "0");
      const month = String(
        new Date(Date.parse(`${dateParts[1]} 1, 2000`)).getMonth() + 1
      ).padStart(2, "0");
      const year = dateParts[2];

      // Create a formatted date string in YYYY-MM-DD format
      const appointmentDate = `${year}-${month}-${day}`;

      // Get the current date in YYYY-MM-DD format
      const currentDate = new Date().toISOString().split("T")[0];

      return appointmentDate >= currentDate;
    },

    isNewPrescription() {
      // Parse the date string "31 May 2024"
      const date = this.item.from_date
        ? this.item.from_date
        : this.item.created_at;
      const dateParts = date.split(" ");
      const day = String(dateParts[0]).padStart(2, "0");
      const month = String(
        new Date(Date.parse(`${dateParts[1]} 1, 2000`)).getMonth() + 1
      ).padStart(2, "0");
      const year = dateParts[2];

      // Create a formatted date string in YYYY-MM-DD format
      const appointmentDate = `${year}-${month}-${day}`;

      // Get the current date in YYYY-MM-DD format
      const currentDate = new Date().toISOString().split("T")[0];

      return appointmentDate >= currentDate;
    },

    dayOfWeek() {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const appointmentDate = new Date(this.item.date);
      return days[appointmentDate.getUTCDay()];
    },
    formattedDate() {
      const date = this.item.date
        ? this.item.date
        : this.item.c_date
        ? this.item.c_date
        : this.item.created_at;
      const appointmentDate = new Date(date);
      const options = { day: "2-digit", month: "long", year: "numeric" };
      return appointmentDate.toLocaleDateString("en-GB", options);
    },

    isDoctor() {
      return this.item.doctor ? true : false;
    },
    isPatient() {
      return this.item.patient ? true : false;
    },
  },
  methods: {
    getQuery() {
      if(this.user.as === "User"){
        return { patient_id: this.user.encrypted_id }
      }else if(this.user.as === "Doctor"){
        return { doctor_id: this.user.encrypted_id };
      }
      return;
    },
  },
};
</script>
<style scoped>
.dr-name {
  color: #3535cd;
  text-decoration-line: underline;
}
</style>