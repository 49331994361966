// views list for DynamicForms here
import ListDynamicForm from "@/views/dynamic-forms/index";
import AddDynamicForm from "@/views/dynamic-forms/add";
import EditDynamicForm from "@/views/dynamic-forms/edit";

const routes = [
  {
    path: '/dynamic-forms/',
    name: 'Dynamic Form',
    component: ListDynamicForm,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_form'
    },
    props: true
  },
  {
    path: '/dynamic-forms/add',
    name: 'Add Dynamic Form',
    component: AddDynamicForm,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_form'
    }
  },
  {
    path: '/dynamic-forms/edit/:id',
    name: 'Edit Dynamic Form',
    component: EditDynamicForm,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_form'
    },
    props: true
  },
];

export default routes;
