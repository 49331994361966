<template>
  <div>
    <vue3-star-ratings
      :starSize="starSize"
      :starColor="starColor"
      :inactiveColor="inactiveColor"
      :numberOfStars="numberOfStars"
      :disableClick="disableClick"
      :controlSize="0"
      :showControl="false"
      v-model="ratings"
    />
  </div>
</template>

<script>
export default {
  props: {
    starSize: Number,
    starColor: String,
    inactiveColor: String,
    numberOfStars: Number,
    disableClick: Boolean,
    disableControl: Boolean,
    rating: Number
  },
  data(){
    return{
        ratings:0
    }
  },
  created(){
    this.ratings = this.rating
  }
};
</script>

<style >
.vue3-star-ratings__wrapper{
    margin: 3px 0 0 0 !important;
    padding: 0 !important;
}

.stars svg{
    margin: 5px !important;
}
</style>