// views list for facility-centers here
import ListFacilityCenter from "@/views/facility-centers/index";
import AddFacilityCenter from "@/views/facility-centers/add";
import EditFacilityCenter from "@/views/facility-centers/edit";
import ShowFacilityCenter from "@/views/facility-centers/show";

const routes = [
  {
    path: '/facility-centers/',
    name: 'Facility Centers',
    component: ListFacilityCenter,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
  {
    path: '/facility-centers/add',
    name: 'Add Facility Center',
    component: AddFacilityCenter,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    }
  },
  {
    path: '/facility-centers/edit/:id',
    name: 'Edit Facility Center',
    component: EditFacilityCenter,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
  {
    path: '/facility-centers/show/:id',
    name: 'Facility Center Show',
    component: ShowFacilityCenter,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_location'
    },
    props: true
  },
];

export default routes;
