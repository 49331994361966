<template>
  <div class="col-xxl-12 col-md-12 box-col-12">
    <div class="card visitor-card">
      <div class="card-header card-no-border pb-0">
        <div class="header-top">
          <h5 class="m-0">
            Total Appointments<span class="f-14 c-text f-w-500 ms-1">
              <svg class="svg-fill me-1">
                <use
                  href="@/assets/svg/icon-sprite.svg#user-visitor"
                ></use></svg
              >({{ totalAppointments }})</span
            >
          </h5>
          <div class="card-header-right-icon">
            <div class="dropdown icon-dropdown">
              <button
                class="btn dropdown-toggle"
                id="visitorButton"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="icon-more-alt"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="visitorButton"
              >
                <a class="dropdown-item" href="#">Today</a
                ><a class="dropdown-item" href="#">Tomorrow</a
                ><a class="dropdown-item" href="#">Yesterday</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-3">
        <div class="visitors-container">
          <apexchart
            height="285"
            type="bar"
            :options="ecomDashboard.options"
            :series="ecomDashboard.series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    appointmentData: Array,
  },

  data() {
    return {
      ecomDashboard: {
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 0,
              dataLabels: {
                total: {
                  enabled: false,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          xaxis: {
            categories: this.categories,
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          colors: ["#000080"],
          fill: {
            opacity: 1,
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
          },
          responsive: [
            {
              breakpoint: 1366,
              options: {
                plotOptions: {
                  bar: {
                    columnWidth: "80%",
                  },
                },
                grid: {
                  padding: {
                    right: 0,
                  },
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                plotOptions: {
                  bar: {
                    columnWidth: "70%",
                  },
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                plotOptions: {
                  bar: {
                    columnWidth: "60%",
                  },
                },
                grid: {
                  padding: {
                    right: 5,
                  },
                },
              },
            },
          ],
        },
        series: [{name:'', data:[]}],
      },
      categories: [],
      totalAppointments: 0,
    };
  },

  mounted() {
    this.ecomDashboard.options.xaxis.categories = this.appointmentData.map(
      (item) => item.appointment_month
    );
    this.ecomDashboard.series[0].data = this.appointmentData.map((item) => {this.totalAppointments += item.total_appointments; return item.total_appointments });
  },
};
</script>

<style>
.visitors-container svg .apexcharts-series path {
  clip-path: inset(1% 0% 0% 0% round 0rem) !important;
}
</style>