// router/app-user/index.js
import { createRouter, createWebHistory } from "vue-router";

// views list for appointments here
import ListAppointment from "@/views/app-user/appointments/index";
import AddAppointment from "@/views/app-user/appointments/add";
import EditAppointment from "@/views/app-user/appointments/edit";
import AppointmentView from "@/views/app-user/appointments/show";

const routes = [
  {
    path: '/appointments/',
    name: 'Appointment',
    component: ListAppointment,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    },
    props: true
  },
  {
    path: '/appointments/add/:id',
    name: 'Add Appointment',
    component: AddAppointment,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_app_user'
    }
  },
  {
    path: '/appointments/edit/:id',
    name: 'Edit Appointment',
    component: EditAppointment,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_app_user'
    },
    props: true
  },
  {
    path: '/appointments/show/:id',
    name: 'Show Appointment',
    component: AppointmentView,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_app_user'
    },
    props: true
  },
];

export default routes;
