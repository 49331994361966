<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <bread_crumbs />
        <router-link
          to="/admins/add"
          class="c-button cbg-primary mb-2 float-end"
          >Add Admin</router-link
        >
      </div>
      <div class="col-md-12">
        <data-table
          :columns="columns"
          :module="module"
          :items="items"
          :isChangePassword="changePassword"
          :isEdit="true"
          :isDelete="true"
          :unBlock="true"
          :isBlock="true"
          @deleted="fetch"
          @nextPage="nextPage"
          @search="fetch"
          @clear="fetch"
        ></data-table>
      </div>
    </div>
  </div>
</template>
<script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
    Bread_crumbs,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Name",
          value: "f_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Role",
          value: "role",
          secondValue: "name",
        },

        {
          text: "Mobile",
          value: "phone",
        },
        {
          text: "Status",
          value: "status",
          // secondValue: "status",
        },
      ],
      items: [],
      module: "admins",
      loading: false,
      changePassword: true,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("admins/getAll", { id, search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
