<template>
  <div class="card visitor-card">
    <div class="card-header card-no-border pb-0">
      <div class="header-top">
        <h5 class="m-0">
          Task Detail<span class="f-14 c-text f-w-500 ms-1">
            <svg class="svg-fill me-1">
              <use href="@/assets/svg/icon-sprite.svg#user-visitor"></use></svg
            >({{ taskData.length }})</span
          >
        </h5>
        <div class="card-header-right-icon">
          <div class="dropdown icon-dropdown">
            <button
              class="btn dropdown-toggle"
              id="visitorButton"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="icon-more-alt"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="visitorButton"
            >
              <a class="dropdown-item" href="#">Today</a
              ><a class="dropdown-item" href="#">Tomorrow</a
              ><a class="dropdown-item" href="#">Yesterday</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-3">
      <div class="visitors-container">
        <apexchart
          height="230"
          type="pie"
          :options="ecomDashboard.options"
          :series="ecomDashboard.series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    taskData: Array,
  },

  data() {
    return {
      ecomDashboard: {
        series: [44, 55, 13, 43, 22],
        options: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      categories: null,
      transformedData: null,
    };
  },

  mounted() {
    this.ecomDashboard.options.labels = this.taskData.map(
      (item) => item.task_name
    );
    this.ecomDashboard.series = this.taskData.map(
      (item) => item.submission_count
    );
  },
};
</script>

<style>
.visitors-container svg .apexcharts-series path {
  clip-path: inset(1% 0% 0% 0% round 0rem) !important;
}
</style>