<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <router-link
          to="/health-literacy-content"
          class="c-button cbg-primary mb-3"
          >Back</router-link
        >
      </div>
      <div class="col-md-12 mt-5" v-if="item">
        <div class="card" v-if="item.type == 'image'">
          <img
            :src="item.file.path"
            class="card-img-top"
            alt="..."
            height="500"
          />
          <div class="card-body">
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text">{{ item.desc }}</p>
          </div>
        </div>
        <div class="card" v-if="item.type == 'video'">
          <video
            width="100%"
            height="500"
            controls
            :poster="item.file.thumbnail"
            :src="item.file.path"
          >
          </video>
          <div class="card-body">
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text">{{ item.desc }}</p>
          </div>
        </div>
        <div
          class="card"
          v-if="item.type == 'pdf'"
        >
          <iframe
          class="card-img-top"
            :src="loadWithHTTPS(item.file.path)"
            width="100%"
            height="500"
          ></iframe>
          <div class="card-body">
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text">{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
    Bread_crumbs,
  },
  data() {
    return {
      item: null,
      fileId:null,
      loading: false,
    };
  },
  created() {
    this.fileId = this.$route.params.id;
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("healthLiteracyContent/getSingle", { id:this.fileId, search })
        .then((response) => {
          this.item = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    loadWithHTTPS(url) {
      if (process.env.NODE_ENV && process.env.NODE_ENV == "production") {
        return url.replace("http://", "https://");
      }
      return url;
    },
  },
};
</script>

<style scoped>
.card-img-top{
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

</style>
  