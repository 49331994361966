<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <button type="button" @click="$router.go(-1)" class="c-button cbg-primary">
          Back
        </button>
        <div class="col-md-8 offset-md-2">
          <div class="card mt-4" v-if="prescription">
            <div class="card-header">
              <h4 class="card-title" v-if="prescription">Prescripiton Detail</h4>
            </div>
            <div class="card-body">
              <h5>Doctor</h5>
              <p v-if="prescription.doctor">{{ prescription.doctor.full_name }}</p>
              <hr>
              <div v-for="(item, index) of prescription.items" :key="item">
                <b>Medication: {{ index+1 }}</b>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-4">Medication Name : </div>
                  <div class="col-sm-6 col-md-8" v-if="item">{{ item.m_name }}</div>
                </div>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-4">Medication Days : </div>
                  <div class="col-sm-6 col-md-8" v-if="item">{{ item.days }}</div>
                </div>
                <div class="row my-3 ">
                  <div class="col-sm-6 col-md-4">To be Taken : </div>
                  <div class="col-sm-6 col-md-8" v-if="item">{{ item.to_be_taken }}</div>
                </div>
                <div class="row my-3">
                  <div class="col-sm-6 col-md-4">Medication Time : </div>
                  <div class="col-sm-6 col-md-8" v-if="item">{{ item.m_time }}</div>
                </div>
                <hr>
  
              </div>
              <b>Important Note:</b>
              <div class="row my-3">
                <div class="col-md-12" v-if="prescription">{{ prescription.detail }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";

export default {
  components: {
    Bread_crumbs,
  },
  data() {
    return {
      prescription: null,
      districtId: null,
      prescriptionId:null,
      loading: false,
      errors: [],
    };
  },
  mounted() {
    this.prescriptionId = this.$route.params.id;
    this.fetch(this.prescriptionId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("prescriptions/getSingle", { id })
        .then((response) => {
          this.prescription = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>
  