<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <router-link to="/dynamic-forms" class="c-button cbg-primary mb-3"
          >Back</router-link
        >
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Dynamic Form</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              id="form"
              ref="addDynamicForm"
              class="needs-validation"
              novalidate
            >
              <div class="form-group mb-3">
                <!-- <label>Form Title <span class="text-danger">*</span></label> -->
                <input
                  type="text"
                  class="form-control"
                  placeholder="Form Title *"
                  v-model="form.title"
                  required
                />
                <div class="invalid-feedback text-danger">
                  Form Title is required
                </div>
              </div>
              <template v-for="(question, index) in form.questions" :key="index">
                <div class="form-group mb-3">
                  <div class="row float-end">
                    <!-- <label class="w-auto"
                      >Question {{ index + 1 }} Title
                      <span class="text-danger">*</span></label
                    > -->
                    <button
                      type="button"
                      class="btn btn-sm btn-danger mb-3 me-3 w-auto float-end"
                      @click="removeQuestion(index)"
                    >
                      Remove Question
                    </button>
                  </div>
                  <input
                    type="text"
                    :placeholder="`Question ${index + 1}  Title *`"
                    class="form-control"
                    v-model="question.title"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Question {{ index + 1 }} Title is required
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="'required' + index"
                      true-value="1"
                      false-value="0"
                      v-model="question.is_required"
                    />
                    <label class="form-check-label" :for="'required' + index"
                      >Required</label
                    >
                  </div>
                </div>
                <div class="form-group mb-3">
                  <!-- <label>Options Type <span class="text-danger">*</span></label> -->
                  <select
                    v-model="question.type"
                    class="form-control"
                    required
                    @change="checkOptionType(index, question.type)"
                  >
                    <option value="">Select Options Type *</option>
                    <option value="text">Text</option>
                    <option value="checkbox">Checkbox</option>
                    <option value="radio">Radio</option>
                    <option value="date">Date</option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Options Type is required
                  </div>
                </div>
                <div class="form-group row">
                  <div
                    class="col-md-6 mb-3"
                    v-for="(option, index) in question.options" :key="index"
                  >
                    <!-- <label
                      >Option {{ index + 1 }} Title
                      <span class="text-danger" v-if="index < 2">*</span></label
                    > -->
                    <input
                      type="text"
                      :placeholder="`Option ${index + 1}  Title ${index < 2 ? '*' : ''}`"
                      class="form-control"
                      v-model="option.title"
                      :required="index < 2"
                    />
                    <div class="invalid-feedback text-danger">
                      Option {{ index + 1 }} Title is required
                    </div>
                  </div>
                  <span v-if="question.options.length > 0" @click="addMoreOptions(index)" class="float-end cursor text-primary">+Add more options</span>
                </div>
              </template>
              <button
              type="button"
              class="c-button cbg-primary float-end mb-3"
              @click="addQuestion"
            >
              Add Question
            </button>
              <div class="col-md-12 mt-3">
                <button type="submit" class="c-button cbg-primary">
                  Submit
                </button>
                <button type="button" @click="this.$refs.addDynamicForm.reset()" class="ms-2 c-button cbg-secondary">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
export default {
  components:{
    Bread_crumbs
  },
  data() {
    return {
      loading: false,
      form: {
        title: null,
        questions: [],
      },
      question: {
        title: null,
        type: "",
        is_required: 0,
        has_options: 0,
        options: [],
      },
      option: {
        title: null,
      },
    };
  },
  mounted() {
    this.addQuestion();
  },
  methods: {
    checkOptionType(index, type) {
      if (type == "checkbox" || type == "radio") {
        this.form.questions[index].options = [];
        this.form.questions[index].has_options = 1;
        for (let i = 0; i < 2; i++) {
          this.addOptions(index);
        }
      } else {
        this.form.questions[index].has_options = 0;
        this.form.questions[index].options = [];
      }
    },
    addQuestion() {
      this.form.questions.push(Object.assign({}, this.question));
    },
    removeQuestion(index) {
      this.form.questions.splice(index, 1);
    },
    addOptions(index) {
      this.form.questions[index].options.push(Object.assign({}, this.option));
    },
    addMoreOptions(index){
      this.addOptions(index);
      this.addOptions(index);
    },
    submit() {
      let form = document.getElementById("form");
      if (form.checkValidity() == false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = this.form;
        this.$store
          .dispatch("dynamicForms/store", { data })
          .then((response) => {
            this.loading = false;
            if (response.success == true) {
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.form.title = null;
              this.form.questions = [];
              this.addQuestion();
              if (this.$refs.addDynamicForm) {
                this.$nextTick(() => {
                  this.$refs.addDynamicForm.reset();
                  this.$refs.addDynamicForm.classList.remove("was-validated");
                });
              }
            } else {
              this.$toast.show("Error saving data.", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 5000,
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      }
    },
  },
};
</script>